import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatPseudoCheckboxModule,
} from '@angular/material/core';
import { MatDatepickerIntl, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import {
  EditorFeatures,
  RICH_TEXT_EDITOR_CONFIG,
  RichTextEditorConfig,
  RichTextEditorModule,
} from '@dataport/ngx-rich-text-editor';
import { FormlyEditorModule } from '@dworkflow-formly-editor';
import { MonacoEditorModule } from '@dworkflow-monaco-editor/monaco-editor.module';
import { CustomDatePickerAdapter } from '@dworkflow/shared/adapters/custom-date-adapter';
import { CanActivateSysadminGuard } from '@dworkflow/shared/guards/can-activate-sysadmin.guard';
import { CanDeactivateGuard } from '@dworkflow/shared/guards/can-deactivate.guard';
import { GlobalHttpInterceptor } from '@dworkflow/shared/interceptors/global-http-interceptor';
import { DatePipe } from '@dworkflow/shared/pipes/date.pipe';
import { AuthenticationService } from '@dworkflow/shared/services/authentication.service';
import { GlobalErrorHandler } from '@dworkflow/shared/services/global-error-handler.service';
import { PaginationTranslationsService } from '@dworkflow/shared/services/pagination-translations.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { ThemeService } from '@dworkflow/shared/services/theme.service';
import { CustomSerializer } from '@dworkflow/shared/utility/custom-serializer';
import { DatepickerDe } from '@dworkflow/shared/utility/datepicker-de';
import { accessibilityReducer } from '@dworkflow/state/accessibility.reducer';
import { reducer } from '@dworkflow/state/dokumente.reducer';
import { securityReducer } from '@dworkflow/state/security.reducer';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import 'moment/locale/de';
import { MomentModule } from 'ngx-moment';
import { provideToastr } from 'ngx-toastr';
import { appInitializerFactory } from './app/app-initializer.factory';
import { AppComponent } from './app/app.component';
import { ROUTES } from './app/app.routing';
import { PipesModule } from './app/shared/pipes/pipes.module';
import { InactivityService } from './app/shared/services/inactivity.service';
import { KonfigurationService } from './app/shared/services/konfiguration.service';
import { SchrittService } from './app/shared/services/schritt.service';
import { VorlagenService } from './app/shared/services/vorlagen.service';
import { WorkflowService } from './app/shared/services/workflow.service';
import { CustomMissingTranslationHandler } from './app/shared/translate/missingTranslationHandler';
import { CustomTranslateCompiler } from './app/shared/translate/translateCompiler';
import { aufgabenFuerTabelleReducer } from './app/state/aufgabe-tabelle.reducer';
import { DokumenteEffects } from './app/state/dokumente.effects';
import { EngineEffects } from './app/state/engine.effects';
import { engineReducer } from './app/state/engine.reducers';
import { GrundeinstellungenEffects } from './app/state/grundeinstellungen.effects';
import { grundeinstellungenReducer } from './app/state/grundeinstellungen.reducers';
import { RoutingEffects } from './app/state/routing.effects';
import { SchrittEffects } from './app/state/schritt.effects';
import { schrittReducer } from './app/state/schritt.reducers';
import { SecurityEffects } from './app/state/security.effects';
import { VertreterEffects } from './app/state/vertreter.effects';
import { workflowFuerTabelleReducer } from './app/state/workflow-tabelle.reducer';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const reducers: ActionReducerMap<unknown> = {
  engine: engineReducer,
  schritte: schrittReducer,
  grundeinstellungen: grundeinstellungenReducer,
  security: securityReducer,
  a11y: accessibilityReducer,
  dokumente: reducer,
  router: routerReducer,
  workflowtabelle: workflowFuerTabelleReducer,
  aufgabentabelle: aufgabenFuerTabelleReducer,
};

const effects = [
  EngineEffects,
  SchrittEffects,
  GrundeinstellungenEffects,
  SecurityEffects,
  DokumenteEffects,
  VertreterEffects,
  RoutingEffects,
];

export const BUFFER_TIME_FOR_LOADING = 200;

const CUSTOM_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

// Wenn der Browser nicht supported ist, soll Angular nicht intitialisiert werden
// Denn: in der Homepage.aspx wird das Anchor-Element von Angular entfernt => die Initialisierung würde mit einem Fehler fehlschlagen.
if (
  !(
    window.navigator.userAgent.indexOf('MSIE') > 0 ||
    window.navigator.userAgent.indexOf('Trident/') > 0
  )
) {
  bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(ROUTES, withInMemoryScrolling({ anchorScrolling: 'enabled' })),
      provideToastr({
        timeOut: 10000,
        positionClass: 'toast-top-center',
      }),
      importProvidersFrom(
        CommonModule,
        MonacoEditorModule,
        FormlyEditorModule,
        BrowserModule,
        FormsModule,
        DatePipe,
        RichTextEditorModule,
        ReactiveFormsModule,
        MomentModule,
        PipesModule,
        DragDropModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
          name: 'dWorkflow',
          maxAge: 100,
          logOnly: environment.production, // Restrict extension to log-only mode
        }),
        TranslateModule.forRoot({
          missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: CustomMissingTranslationHandler,
          },
          compiler: {
            provide: TranslateCompiler,
            useClass: CustomTranslateCompiler,
          },
        }),
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatFormFieldModule,
        MatStepperModule,
        MatMenuModule,
        MatSidenavModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        MatDividerModule,
        MatListModule,
        MatBadgeModule,
        MatPseudoCheckboxModule,
        MatProgressBarModule,
        PercentPipe,
        TenantService,
        WorkflowService,
        SchrittService,
        KonfigurationService,
        VorlagenService,
        AuthenticationService,
        CanDeactivateGuard,
        CanActivateSysadminGuard,
        ThemeService,
        CustomMissingTranslationHandler,
        InactivityService,
        StoreRouterConnectingModule.forRoot({
          serializer: CustomSerializer,
          navigationActionTiming: NavigationActionTiming.PostActivation,
        })
      ),
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: GlobalHttpInterceptor,
        multi: true,
      },
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TranslateService, Injector, ThemeService],
        multi: true,
      },
      { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
      { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_DATE_FORMATS },
      { provide: MatDatepickerIntl, useClass: DatepickerDe },
      { provide: MatPaginatorIntl, useClass: PaginationTranslationsService },
      { provide: DateAdapter, useClass: CustomDatePickerAdapter },
      { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
      {
        provide: RICH_TEXT_EDITOR_CONFIG,
        useValue: ((): RichTextEditorConfig => {
          const config = new RichTextEditorConfig();
          config.features = [
            EditorFeatures.Undo,
            EditorFeatures.Redo,
            EditorFeatures.FontStyle,
            EditorFeatures.Links,
          ];
          return config;
        })(),
      },
      { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } },
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
    ],
  });
}

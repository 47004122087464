import { Injectable } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class DatepickerDe extends MatDatepickerIntl {
  constructor(private translate: TranslateService) {
    super();
  }

  /** A label for the calendar popup (used by screen readers). */
  calendarLabel = this.translate.instant('Texte.Datepicker.AriaLabel.KalenderLabel') as string;
  /** A label for the button used to open the calendar popup (used by screen readers). */
  openCalendarLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.OpenKalenderLabel'
  ) as string;
  /** Label for the button used to close the calendar popup. */
  closeCalendarLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.CloseKalenderLabel'
  ) as string;
  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.VorherigerMonatLabel'
  ) as string;
  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.NaechsterMonatLabel'
  ) as string;
  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.VorherigesJahrLabel'
  ) as string;
  /** A label for the next year button (used by screen readers). */
  nextYearLabel = this.translate.instant('Texte.Datepicker.AriaLabel.NaechstesJahrLabel') as string;
  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.VorherigeJahresansichtLabel'
  ) as string;
  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.NaechsteJahresansichtLabel'
  ) as string;
  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.ZurMonatsansichtWechselnLabel'
  ) as string;
  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = this.translate.instant(
    'Texte.Datepicker.AriaLabel.ZurJahresansichtWechselnLabel'
  ) as string;
}

import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  // Folgende Translations werden vor dem Laden der eigentlichen Translations verwendet
  // Daher sollten die erzeugten Warnings unterdrückt werden
  private whitelist = [
    'Texte.Utilities.LoadingComponent.LadeNachricht',
    'Texte.Allgemein.WirdGeladen',
  ];
  // Bei folgenden Translations soll statt der Warnung nichts zurück gegeben werden, da die Translations
  // mglw. einfach nicht existieren
  private regexWhitelist: RegExp[] = [
    new RegExp(
      'Texte\\.Grundeinstellungen\\.Formulare\\.Editor\\.Configuration\\.Attribute\\..*\\.Beschreibung'
    ),
    new RegExp(
      'Texte\\.Grundeinstellungen\\.Formulare\\.Editor\\.Configuration\\.Attribute\\..*\\.Bezeichnung'
    ),
  ];
  handle(params: MissingTranslationHandlerParams): string {
    if (this.whitelist.some(key => params.key === key)) {
      return null;
    }
    if (this.regexWhitelist.some(regex => regex.test(params.key))) {
      return null;
    }
    const warning = this.getTranslationMissingWarning(params.key);
    console.warn(warning);
    return warning;
  }

  getTranslationMissingWarning(key: string): string {
    return 'Translation "' + key + '" fehlt';
  }
}

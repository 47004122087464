import { FormlyExtension } from '@ngx-formly/core';
import { FormulareHelper } from '../shared/utility/formulare-helper';

export const accessibilityExtension: FormlyExtension = {
  prePopulate(field): void {
    if (field.type !== FormulareHelper.formlyGroupType) {
      // floatLabel
      if (!field.props?.['floatLabel']) {
        field.props = {
          ...field.props,
          floatLabel: 'always',
        };
      }

      // aria-label
      if (field.props?.label && !field.props.attributes?.['aria-label']) {
        field.props = {
          ...field.props,
          attributes: {
            ...field.props.attributes,
            'aria-label': field.props.label,
          },
        };
      }

      // aria-description
      if (field.props?.description && !field.props.attributes?.['aria-description']) {
        field.props = {
          ...field.props,
          attributes: {
            ...field.props.attributes,
            'aria-description': field.props.description,
          },
        };
      }
    }
  },
};

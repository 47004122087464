import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BenachrichtigungenModel } from '@dworkflow/shared/model/benachrichtigungen.model';
import { DokumentUploadStatus } from '@dworkflow/shared/model/dokumente/dokument-upload-status.enum';
import { EditWorkflowverlaufModel } from '@dworkflow/shared/model/edit-workflowverlauf.model';
import { EngineEinstellungenModel } from '@dworkflow/shared/model/engine-einstellungen.model';
import { WorkflowProtokollModel } from '@dworkflow/shared/model/workflow-protokoll.model';
import { DokumenteService } from '@dworkflow/shared/services/dokumente.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, of } from 'rxjs';
import {
  bufferTime,
  catchError,
  concatMap,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import { HaftnotizModel } from '../shared/model/haftnotiz.model';
import { WorkflowInformationenFuerTabelleModel } from '../shared/model/workflow-informationen-fuer-tabelle.model';
import { httpStatusCodeToWorkflowLoadingStatus } from '../shared/model/workflow-loading-status.enum';
import { WorkflowModel } from '../shared/model/workflow.model';
import { WorkflowService } from '../shared/services/workflow.service';
import * as commonActions from './common.actions';
import * as engineActions from './engine.actions';
import * as fromStore from './engine.state';
import * as schrittActions from './schritt.actions';

@Injectable({
  providedIn: 'root',
})
export class EngineEffects {
  constructor(
    private actions: Actions,
    private workflowService: WorkflowService,
    private toastService: ToastService,
    private router: Router,
    private store: Store,
    private translate: TranslateService,
    private dokumenteService: DokumenteService,
    private tenantService: TenantService,
    private route: ActivatedRoute
  ) {}

  /**
   * Workflow-effects
   */

  createWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.createWorkflow),
      mergeMap(action =>
        this.workflowService.erstelleWorkflow(action.workflow).pipe(
          switchMap((workflow: WorkflowModel) => {
            if (
              action.workflow.starteWorkflowverlaufNachErstellung ||
              action.starteWorkflowverlaufDelayed
            ) {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Wizard.Benachrichtigungen.WorkflowErstellenUndStarten.SuccessTitel',
                  {
                    workflowTitel: action.workflow.informationen.titel,
                  }
                ) as string,
                this.translate.instant(
                  'Texte.Wizard.Benachrichtigungen.WorkflowErstellenUndStarten.SuccessMessage',
                  {
                    workflowTitel: action.workflow.informationen.titel,
                  }
                ) as string
              );
            } else {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Wizard.Benachrichtigungen.WorkflowErstellen.SuccessTitel',
                  {
                    workflowTitel: action.workflow.informationen.titel,
                  }
                ) as string,
                this.translate.instant(
                  'Texte.Wizard.Benachrichtigungen.WorkflowErstellen.SuccessMessage',
                  {
                    workflowTitel: action.workflow.informationen.titel,
                  }
                ) as string
              );
            }
            this.tenantService.redirectUrl('workflows/' + action.workflow.workflowId);
            return [
              engineActions.createWorkflowSucceeded({
                workflow,
                starteWorkflowverlaufDelayed: action.starteWorkflowverlaufDelayed,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Wizard.Benachrichtigungen.WorkflowErstellen.FailedTitel',
                {
                  workflowTitel: action.workflow.informationen.titel,
                }
              ) as string,
              this.translate.instant(
                'Texte.Wizard.Benachrichtigungen.WorkflowErstellen.FailedMessage',
                {
                  workflowTitel: action.workflow.informationen.titel,
                }
              ) as string,
              error
            );
            return of(
              engineActions.createWorkflowFailed({ workflowId: action.workflow.workflowId, error })
            );
          })
        )
      )
    );
  });

  starteWorkflowverlaufNachErstellung$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.createWorkflowSucceeded),
      filter(action => action.starteWorkflowverlaufDelayed),
      concatMap(({ workflow }) =>
        this.dokumenteService.getDokumenteByWorkflowId(workflow.workflowId).pipe(
          map(dokumente =>
            dokumente.filter(
              d =>
                d.status === DokumentUploadStatus.Pending ||
                d.status === DokumentUploadStatus.Queued ||
                d.status === DokumentUploadStatus.UploadInProgress
            )
          ),
          first(dokumente => dokumente?.length === 0),
          map(_ => workflow)
        )
      ),
      switchMap(workflow => {
        return [
          engineActions.starteWorkflowverlauf({
            workflowId: workflow.workflowId,
            workflowverlaufId: workflow.verlauf.workflowverlaufId,
          }),
        ];
      })
    );
  });

  starteWorkflowverlauf$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.starteWorkflowverlauf),
      mergeMap(action =>
        this.store.select(fromStore.selectWorkflowInformationByWorkflowId(action.workflowId)).pipe(
          first(x => !!x),
          mergeMap(informationen => {
            const waitToast = this.toastService.showAndGetWaitToast(
              this.translate.instant('Texte.Workflow.Benachrichtigungen.Starten.WaitTitel', {
                workflowTitel: informationen.titel,
              }) as string,
              this.translate.instant('Texte.Workflow.Benachrichtigungen.Starten.WaitMessage', {
                workflowTitel: informationen.titel,
              }) as string
            );

            return this.workflowService
              .starteWorkflowverlauf(action.workflowverlaufId, action.workflowId)
              .pipe(
                switchMap(() => {
                  this.toastService.showSuccess(
                    this.translate.instant(
                      'Texte.Workflow.Benachrichtigungen.Starten.SuccessTitel',
                      {
                        workflowTitel: informationen.titel,
                      }
                    ) as string,
                    this.translate.instant(
                      'Texte.Workflow.Benachrichtigungen.Starten.SuccessMessage',
                      {
                        workflowTitel: informationen.titel,
                      }
                    ) as string
                  );
                  return [
                    engineActions.starteWorkflowverlaufSucceeded({
                      workflowverlaufId: action.workflowverlaufId,
                    }),
                  ];
                }),
                catchError((error: HttpErrorResponse) => {
                  this.toastService.showHttpErrorResponse(
                    this.translate.instant(
                      'Texte.Workflow.Benachrichtigungen.Starten.FailedTitel',
                      {
                        workflowTitel: informationen.titel,
                      }
                    ) as string,
                    this.translate.instant(
                      'Texte.Workflow.Benachrichtigungen.Starten.FailedMessage',
                      {
                        workflowTitel: informationen.titel,
                      }
                    ) as string,
                    error
                  );
                  return of(
                    engineActions.starteWorkflowverlaufFailed({
                      workflowverlaufId: action.workflowverlaufId,
                      error,
                    })
                  );
                }),
                finalize(() => {
                  this.toastService.removeToast(waitToast);
                })
              );
          })
        )
      )
    );
  });

  starteWorkflowverlaufSucceeded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.starteWorkflowverlaufSucceeded),
      mergeMap(_ => [
        engineActions.loadMeineWorkflows(),
        schrittActions.loadMeineAufgaben({ anzahl: undefined }),
      ])
    );
  });

  editWorkflowverlauf$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.editWorkflowverlauf),
      mergeMap(
        ({
          navigateTo,
          workflowTitel,
          verlaufId,
          schritte: neueSchritte,
          isFaelligkeitsAnpassung,
          hauptfaelligkeit,
        }) => {
          const waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.WaitTitel',
              {
                workflowTitel: workflowTitel,
              }
            ) as string,
            this.translate.instant(
              'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.WaitMessage',
              {
                workflowTitel: workflowTitel,
              }
            ) as string
          );

          const payload: EditWorkflowverlaufModel = {
            workflowverlaufSchritte: neueSchritte,
            isFaelligkeitsAnpassung,
            hauptfaelligkeit,
          };

          return this.workflowService.editWorkflowverlauf(verlaufId, payload).pipe(
            switchMap(updatedWorkflowverlauf => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.SuccessTitel',
                  {
                    workflowTitel: workflowTitel,
                  }
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.SuccessMessage',
                  {
                    workflowTitel: workflowTitel,
                  }
                ) as string
              );
              this.router.navigateByUrl(navigateTo);
              return of(
                engineActions.editWorkflowverlaufSucceeded({
                  workflowverlauf: updatedWorkflowverlauf,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.FailedTitel',
                  {
                    workflowTitel: workflowTitel,
                  }
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Verlauf.Edit.Benachrichtigungen.EditWorkflowverlauf.FailedMessage',
                  {
                    workflowTitel: workflowTitel,
                  }
                ) as string,
                error
              );
              return of(
                engineActions.starteWorkflowverlaufFailed({
                  workflowverlaufId: verlaufId,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
        }
      )
    );
  });

  editWorkflowverlaufSucceeded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.editWorkflowverlaufSucceeded),
      mergeMap(action => {
        return of(
          schrittActions.workflowverlaufAngepasst({ workflowverlauf: action.workflowverlauf })
        );
      })
    );
  });

  loadWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loadWorkflow),
      mergeMap(action =>
        this.store.select(fromStore.selectWorkflowByWorkflowId(action.workflowId)).pipe(
          take(1),
          mergeMap(oldWorkflow => {
            let timestamp = '0';
            if (oldWorkflow && oldWorkflow.loaded && oldWorkflow.timeStampTicks) {
              timestamp = oldWorkflow.timeStampTicks;
            }
            return this.workflowService.getWorkflow(action.workflowId, timestamp);
          }),
          switchMap((workflow: WorkflowModel) => [
            engineActions.loadWorkflowSucceeded({ workflowId: action.workflowId, workflow }),
          ]),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: State muss nicht aktualisiert werden aber Dokumente und Schritte
              return [commonActions.loadWorkflow({ workflowId: action.workflowId })];
            }
            return of(
              engineActions.loadWorkflowFailed({
                workflowId: action.workflowId,
                loadingErrorDetails: error.message,
                loadingStatus: httpStatusCodeToWorkflowLoadingStatus(error.status),
              })
            );
          })
        )
      )
    );
  });

  loadWorkflowSucceeded$ = createEffect(() => {
    return this.actions.pipe(
      // Load Workflow für Dokumente und Schritte ausführen
      ofType(engineActions.loadWorkflowSucceeded),
      switchMap(action => [commonActions.loadWorkflow({ workflowId: action.workflowId })])
    );
  });

  loadWorkflowInformationenFuerTabelle$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.requestLoadWorkflowInformationenFuerTabelle),
      bufferTime(200),
      filter(actions => actions.length > 0),
      mergeMap(actions =>
        this.workflowService
          .getWorkflowInformationenFuerTabelle(actions.map(a => a.workflowId))
          .pipe(
            map((workflows: WorkflowInformationenFuerTabelleModel[]) =>
              engineActions.loadWorkflowInformationenFuerTabelleSucceeded({ workflows })
            ),
            catchError((error: HttpErrorResponse) => {
              return of(
                engineActions.loadWorkflowInformationenFuerTabelleFailed({
                  error,
                })
              );
            })
          )
      )
    );
  });

  loadMeineWorkflows$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loadMeineWorkflows),
      mergeMap(() =>
        this.store.select(fromStore.selectTimeStampForMeineWorkflows).pipe(
          take(1),
          mergeMap(timeStampTicks => this.workflowService.getWorkflowsByInitiator(timeStampTicks)),
          switchMap((workflows: WorkflowInformationenFuerTabelleModel[]) => {
            return of(engineActions.loadMeineWorkflowsSucceeded({ workflows }));
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: EngineState muss nicht aktualisiert werden, die Schritte werden geladen
              return EMPTY;
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.MeineWorkflowsLaden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.MeineWorkflowsLaden.FailedMessage'
                ) as string,
                error
              );

              return of(engineActions.loadMeineWorkflowsFailed({ error }));
            }
          })
        )
      )
    );
  });

  loadMeineWorkflowsFuerStartseite$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loadMeineWorkflowsFuerStartseite),
      mergeMap(() =>
        this.store.select(fromStore.selectTimeStampForMeineWorkflowsFuerStartseite).pipe(
          take(1),
          mergeMap(timeStampTicks =>
            this.workflowService.getWorkflowsByInitiator(timeStampTicks, 5)
          ),
          switchMap((workflows: WorkflowInformationenFuerTabelleModel[]) => {
            return of(engineActions.loadMeineWorkflowsFuerStartseiteSucceeded({ workflows }));
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: EngineState muss nicht aktualisiert werden, die Schritte werden geladen
              return EMPTY;
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.MeineWorkflowsLaden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.MeineWorkflowsLaden.FailedMessage'
                ) as string,
                error
              );

              return of(engineActions.loadMeineWorkflowsFuerStartseiteFailed({ error }));
            }
          })
        )
      )
    );
  });

  loadAlleWorkflows$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loadAlleWorkflows),
      mergeMap(_ =>
        this.store.select(fromStore.selectTimeStampForAlleWorkflows).pipe(
          take(1),
          mergeMap(timeStampTicks => this.workflowService.getAlleWorkflows(timeStampTicks)),
          switchMap((workflows: WorkflowInformationenFuerTabelleModel[]) => {
            return of(engineActions.loadAlleWorkflowsSucceeded({ workflows }));
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: EngineState muss nicht aktualisiert werden, die Schritte werden geladen
              return EMPTY;
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.AlleWorkflowsLaden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.MeineWorkflows.Benachrichtigungen.AlleWorkflowsLaden.FailedMessage'
                ) as string,
                error
              );

              return of(engineActions.loadAlleWorkflowsFailed({ error }));
            }
          })
        )
      )
    );
  });

  loadAlleWorkflowsAnzahl$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadAnzahlen, engineActions.loadAlleWorkflowsAnzahl),
      mergeMap(_ => this.workflowService.getAlleWorkflowsAnzahl()),
      switchMap((workflowAnzahl: number) => {
        return [engineActions.loadAlleWorkflowsAnzahlSucceeded({ workflowAnzahl })];
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastService.showHttpErrorResponse(
          this.translate.instant(
            'Texte.Navigation.Benachrichtigungen.AlleWorkflowsAnzahlLaden.FailedTitel'
          ) as string,
          this.translate.instant(
            'Texte.Navigation.Benachrichtigungen.AlleWorkflowsAnzahlLaden.FailedMessage'
          ) as string,
          error
        );

        return of(engineActions.loadAlleWorkflowsAnzahlFailed({ error }));
      })
    );
  });

  loadVertretungenWorkflows$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        engineActions.loadVertretungenWorkflows,
        commonActions.loadVertretungenAufgabenUndWorkflows
      ),
      // TODO: Prüfen ob bei getWorkflowsByInitiator die Id wegfallen kann. #53291
      switchMap(({ principalId }) => {
        return this.workflowService.getWorkflowsByPrincipalId('0', principalId, null).pipe(
          switchMap((workflows: WorkflowInformationenFuerTabelleModel[]) => {
            return of(engineActions.loadVertretungenWorkflowsSucceeded({ principalId, workflows }));
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: State muss nicht aktualisiert werden
              return EMPTY;
            } else if (error instanceof HttpErrorResponse && error.status === 403) {
              this.toastService.showError(
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.WorkflowsLaden.UnauthorizedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.WorkflowsLaden.UnauthorizedMessage'
                ) as string
              );
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.WorkflowsLaden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.WorkflowsLaden.FailedMessage'
                ) as string,
                error
              );
            }
            return of(engineActions.loadVertretungenWorkflowsFailed({ principalId, error }));
          })
        );
      })
    );
  });

  storniereWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.storniereWorkflow),
      mergeMap(({ workflowId, titel, kommentar }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Benachrichtigungen.Stornieren.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Workflow.Benachrichtigungen.Stornieren.WaitMessage', {
            titel: titel,
          }) as string
        );
        return this.workflowService.storniereWorkflow(workflowId, kommentar).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Stornieren.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Stornieren.SuccessMessage',
                { titel: titel }
              ) as string
            );
            return of(
              engineActions.storniereWorkflowSucceed({ workflowId }),
              schrittActions.workflowverlaufStorniert({ workflowId })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Stornieren.FailedTitel'
              ) as string,
              this.translate.instant('Texte.Workflow.Benachrichtigungen.Stornieren.FailedMessage', {
                titel: titel,
              }) as string,
              error
            );
            return of(engineActions.storniereWorkflowFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  loescheWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loescheWorkflow),
      mergeMap(({ workflowId }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant('Texte.Workflow.Benachrichtigungen.Loeschen.WaitTitel') as string,
          this.translate.instant('Texte.Workflow.Benachrichtigungen.Loeschen.WaitMessage') as string
        );
        return this.workflowService.loescheWorkflow(workflowId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Loeschen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Loeschen.SuccessMessage'
              ) as string
            );
            this.tenantService.redirectUrl('workflows/eigene');
            return of(engineActions.loescheWorkflowSucceed({ workflowId }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Loeschen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Benachrichtigungen.Loeschen.FailedMessage'
              ) as string,
              error
            );
            return of(engineActions.loescheWorkflowFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  loescheWorkflowSucceeded$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loescheWorkflowSucceed),
      mergeMap(action => {
        return of(schrittActions.workflowGeloescht({ workflowId: action.workflowId }));
      })
    );
  });

  /**
   * Haftnotiz-effects
   */
  loescheHaftnotiz$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loescheHaftnotiz),
      mergeMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.WaitMessage'
          ) as string
        );
        return this.workflowService.loescheHaftnotiz(action.workflowId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.SuccessMessage'
              ) as string
            );
            return of(engineActions.loescheHaftnotizSucceeded({ workflowId: action.workflowId }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Loeschen.FailedMessage'
              ) as string,
              error
            );
            return of(engineActions.loescheHaftnotizFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  speichereHaftnotiz$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.speichereHaftnotiz),
      mergeMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.WaitMessage'
          ) as string
        );
        return this.workflowService.speichereHaftnotiz(action.workflowId, action.haftnotiz).pipe(
          switchMap((haftnotiz: HaftnotizModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.SuccessMessage'
              ) as string
            );
            return of(
              engineActions.speichereHaftnotizSucceeded({
                workflowId: action.workflowId,
                haftnotiz,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Haftnotiz.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(
              engineActions.speichereHaftnotizFailed({ workflowId: action.workflowId, error })
            );
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  speichereWorkflowInformationen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.speicherWorkflowInformationen),
      mergeMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.WaitMessage'
          ) as string
        );
        return this.workflowService
          .speichereWorkflowInformationen(action.workflowInformationen)
          .pipe(
            switchMap((workflow: WorkflowModel) => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.SuccessMessage'
                ) as string
              );
              return of(
                engineActions.speicherWorkflowInformationenSucceeded({
                  workflow,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Informationen.Benachrichtigungen.Speichern.FailedMessage'
                ) as string,
                error
              );
              return of(
                engineActions.speicherWorkflowInformationenFailed({
                  workflowId: action.workflowInformationen.workflowId,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });
  /**
   * Einstellungen-effects
   */
  loadEinstellungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadEinstellungenUndArbeitsgruppen, engineActions.loadEinstellungen),
      mergeMap(_ =>
        this.workflowService.getEinstellungen().pipe(
          switchMap((einstellungen: EngineEinstellungenModel) =>
            of(engineActions.loadEinstellungenSucceeded({ einstellungen }))
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return of(engineActions.loadEinstellungenFailed({ error }));
          })
        )
      )
    );
  });

  updateEinstellungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.updateEinstellungen),
      mergeMap(({ einstellungen }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.WaitMessage'
          ) as string
        );
        return this.workflowService.updateEinstellungen(einstellungen).pipe(
          switchMap((newEinstellungen: EngineEinstellungenModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.SuccessMessage'
              ) as string
            );
            return of(
              engineActions.updateEinstellungenSucceeded({ einstellungen: newEinstellungen })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowEinstellungen.Benachrichtigungen.Update.FailedMessage'
              ) as string,
              error
            );
            return of(engineActions.updateEinstellungenFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  /**
   * Protokoll-effects
   */
  loadProtokoll$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.loadProtokoll),
      mergeMap(action =>
        this.workflowService.getProtokoll(action.workflowId).pipe(
          map((protokoll: WorkflowProtokollModel) =>
            engineActions.loadProtokollSucceeded({ protokoll })
          ),
          catchError((error: HttpErrorResponse) => {
            return of(
              engineActions.loadProtokollFailed({
                workflowId: action.workflowId,
                loadingErrorDetails: error.message,
                loadingStatus: httpStatusCodeToWorkflowLoadingStatus(error.status),
              })
            );
          })
        )
      )
    );
  });

  // Aufgaben-Anzahl aktualisieren bei WF Änderungen
  onAufgabeAbgeschlossen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        engineActions.createWorkflowSucceeded,
        engineActions.starteWorkflowverlaufSucceeded,
        engineActions.loescheWorkflowSucceed,
        engineActions.loescheWorkflowSucceed
      ),
      mergeMap(_ => {
        return [schrittActions.loadMeineAufgabenAnzahl()];
      })
    );
  });

  // Benachrichtigungen laden
  loadBenachrichtgungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadBenachrichtigungen),
      mergeMap(_ =>
        this.workflowService.getBenachrichtigungen().pipe(
          map((benachrichtigungen: BenachrichtigungenModel) =>
            engineActions.loadBenachrichtigungenSucceeded({ benachrichtigungen })
          ),
          catchError((error: HttpErrorResponse) => {
            return of(
              engineActions.loadBenachrichtigungenFailed({
                error,
              })
            );
          })
        )
      )
    );
  });

  // Benachrichtigung speichern
  updateBenachrichtgung$ = createEffect(() => {
    return this.actions.pipe(
      ofType(engineActions.updateBenachrichtigung),
      mergeMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.WaitMessage'
          ) as string
        );
        return this.workflowService
          .updateBenachrichtigung(
            action.typ,
            action.betreff,
            action.inhalt,
            action.versandAktiviert
          )
          .pipe(
            map(benachrichtigungsmodel => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.SucceededMessage'
                ) as string
              );
              return engineActions.updateBenachrichtigungSucceeded({
                model: benachrichtigungsmodel,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.FailedMessage'
                ) as string,
                error
              );
              return of(
                engineActions.updateBenachrichtigungFailed({
                  error,
                })
              );
            }),
            finalize(() => this.toastService.removeToast(waitToast))
          );
      })
    );
  });
}

import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'dworkflow-formly-wrapper-card',
    template: `
    <mat-card [id]="id">
      @if (props.label) {
        <mat-card-title>{{ props.label }}</mat-card-title>
      }
      @if (props.description) {
        <mat-card-subtitle>{{ props.description }}</mat-card-subtitle>
      }
      <ng-container #fieldComponent></ng-container>
    </mat-card>
    `,
    standalone: true,
    imports: [MatCardModule],
})
export class FormlyCardWrapperComponent extends FieldWrapper {}

@if (uploads$ | async; as uploads) {
  @if (uploads.length > 0) {
    <div
      class="progress-area-card"
      [ngClass]="{ collapsed: isCollapsed, finished: allFinished$ | async }"
      role="region"
      aria-labelledby="uploads-label"
      >
      <div class="titelzeile row">
        <h4 id="uploads-label" class="mat-headline-4">
          {{ 'Texte.Dokumente.NotificationArea.Uploads' | translate }}
        </h4>
        <button
          mat-button
          type="button"
          class="collapse-btn"
        title="{{
          !isCollapsed
            ? ('Texte.Dokumente.NotificationArea.Minimieren' | translate)
            : ('Texte.Dokumente.NotificationArea.Maximieren' | translate)
        }}"
        attr.aria-label="{{
          !isCollapsed
            ? ('Texte.Dokumente.NotificationArea.Minimieren' | translate)
            : ('Texte.Dokumente.NotificationArea.Maximieren' | translate)
        }}"
          data-testid="dokumenteUploadProgressAreaMinimierenButton"
          (click)="isCollapsed = !isCollapsed"
          >
          @if (!isCollapsed) {
            <mat-icon fontSet="fa" fontIcon="fa-angle-down"></mat-icon>
          }
          @if (isCollapsed) {
            <mat-icon fontSet="fa" fontIcon="fa-angle-up"></mat-icon>
          }
        </button>
      </div>
      <div class="row">
        <div class="progress-liste" aria-live="polite" aria-relevant="all">
          @for (upload of uploads; track trackWorkflowOderVorlageBy($index, upload)) {
            <div
              class="workflow"
              [ngClass]="{ 'finished-wf-upload': allUploadsFinished(upload) }"
              >
              <div class="workflow-header">
                <span class="text-overflow-elipsis wf-titel">
                  @if (upload.isWorkflow) {
                    <dworkflow-loading
                      [loaded]="(getWorkflowSub(upload.workflowOderVorlagenId) | async) !== undefined"
                      [loaderContent]="loader"
                      [showSpinner]="true"
                      class="loading-container"
                      >
                      <ng-template #loader>
                        @if (getWorkflowSub(upload.workflowOderVorlagenId) | async; as wf) {
                          <span
                      title="{{
                        'Texte.Workflow.Link.KeineTenantBerechtigungenHoverText'
                          | translate
                            : {
                                workflowTitel: wf.workflow?.titel
                              }
                      }}"
                          >
                          {{ wf.workflow?.titel | dWorkflowAbkuerzungPipe }}
                        </span>
                      }
                    </ng-template>
                  </dworkflow-loading>
                }
                @if (!upload.isWorkflow) {
                  @if (getVorlagenSub(upload.workflowOderVorlagenId) | async; as vorlage) {
                    <span
                    title="{{
                      'Texte.Vorlage.Link.HoverText' | translate : { vorlagenName: vorlage.name }
                    }}"
                      >
                      <a
                      [routerLink]="
                        '/vorlagen/' +
                        vorlage.vorlagenTyp +
                        '/' +
                        vorlage.vorlagenId +
                        '/bearbeiten'
                      "
                        class="btn-link break-line wf-titel"
                      attr.aria-label="{{
                        'Texte.Vorlage.Link.AriaLabel' | translate : { vorlagenName: vorlage.name }
                      }}"
                        >
                        <mat-icon fontSet="fa" fontIcon="fa-link"></mat-icon>
                        {{ vorlage.name | dWorkflowAbkuerzungPipe }}</a
                        >
                      </span>
                    }
                  }
                </span>
                @if (allUploadsFinished(upload)) {
                  <button
                    mat-button
                    type="button"
                    class="remove-wf-btn"
              title="{{
                upload.isWorkflow
                  ? ('Texte.Dokumente.NotificationArea.WorkflowEntfernen'
                    | translate : { workflowId: upload.workflowOderVorlagenId })
                  : ('Texte.Dokumente.NotificationArea.VorlageEntfernen'
                    | translate : { vorlagenId: upload.workflowOderVorlagenId })
              }}"
              attr.aria-label="{{
                upload.isWorkflow
                  ? ('Texte.Dokumente.NotificationArea.WorkflowEntfernen'
                    | translate : { workflowId: upload.workflowOderVorlagenId })
                  : ('Texte.Dokumente.NotificationArea.VorlageEntfernen'
                    | translate : { vorlagenId: upload.workflowOderVorlagenId })
              }}"
              [attr.data-testid]="
                'dokumenteUploadProgressAreaEntfernenButton_' + upload.workflowOderVorlagenId
              "
                    (click)="removeFertigenWorkflowOderVorlage(upload.workflowOderVorlagenId)"
                    >
                    <mat-icon fontSet="fa" fontIcon="fa-close"></mat-icon>
                  </button>
                }
              </div>
              <div class="files">
                @for (dokument of upload.dokumente; track trackDokumentBy($index, dokument)) {
                  <div>
                    <div
                      title="{{ dokument.dokument?.name }}"
                      aria-atomic="true"
                      class="dokument-wrapper"
                [ngClass]="{
                  processing:
                    dokument.dokument?.status === DokumentUploadStatus.Queued ||
                    dokument.dokument?.status === DokumentUploadStatus.Processing ||
                    dokument.dokument?.status === DokumentUploadStatus.UploadInProgress,
                  success: dokument.dokument?.status === DokumentUploadStatus.Uploaded
                }"
                      >
                      <span class="text-overflow-elipsis">
                        @if (dokument.dokument?.status === DokumentUploadStatus.Uploaded) {
                          <mat-icon
                            fontSet="fa"
                            fontIcon="fa-check"
                            class="uploaded-checkmark"
                          ></mat-icon>
                        }
                        @if (
                          dokument.dokument?.status === DokumentUploadStatus.Queued ||
                          dokument.dokument?.status === DokumentUploadStatus.Processing ||
                          dokument.dokument?.status === DokumentUploadStatus.UploadInProgress
                          ) {
                          <mat-icon
                            fontSet="fa"
                            fontIcon="fa-file-upload"
                            class="uploaded-checkmark"
                          ></mat-icon>
                        }
                        @if (dokument.dokument?.objectStorageKey) {
                          <a
                            class="dokument-titel"
                            [attr.aria-label]="dokument.dokument?.name + ' herunterladen'"
                            [title]="dokument.dokument?.name + ' herunterladen'"
                            href="{{ dokument.dokument?.objectStorageKey ?? '' }}"
                            >{{ dokument.dokument?.name }}</a
                            >
                          } @else {
                            <span class="dokument-titel">{{ dokument.dokument?.name }}</span>
                          }
                        </span>
                        @if (dokument.file.progress < 100) {
                          <div class="progress">
                            <mat-progress-bar
                              mode="determinate"
                              [value]="dokument.file.progress"
                              color="primary"
                    [attr.aria-valuetext]="
                      'Texte.Dokumente.NotificationArea.UploadProgressAbgeschlossen'
                        | translate : { fileProgress: dokument.file.progress }
                    "
                              >
                            </mat-progress-bar>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    }
  }

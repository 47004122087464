import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { VorlageModel } from '@dworkflow/shared/model/vorlagen/vorlage.model';
import { VorlagenLadeType } from '@dworkflow/shared/model/vorlagen/vorlagen-lade-type.enum';
import { VorlagenkonfigurationModel } from '@dworkflow/shared/model/vorlagenkonfiguration.model';
import { KonfigurationService } from '@dworkflow/shared/services/konfiguration.service';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import { VorlagenService } from '@dworkflow/shared/services/vorlagen.service';
import { WorkflowService } from '@dworkflow/shared/services/workflow.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  retry,
  switchMap,
  take,
} from 'rxjs/operators';
import * as commonActions from './common.actions';
import * as GrundeinstellungenActions from './grundeinstellungen.actions';
import * as fromStore from './grundeinstellungen.state';

@Injectable({
  providedIn: 'root',
})
export class GrundeinstellungenEffects {
  constructor(
    private actions$: Actions,
    private konfigurationService: KonfigurationService,
    private vorlagenService: VorlagenService,
    private router: Router,
    private translate: TranslateService,
    private toastService: ToastService,
    private store: Store,
    private workflowService: WorkflowService,
    public dialog: MatDialog
  ) {}

  /**
   * Vorlagenkonfigurationen
   */

  createKonfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.createVorlagenkonfiguration),
      mergeMap(({ value }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.WaitMessage',
            {
              titel: value.titel,
            }
          ) as string
        );
        return this.konfigurationService.createVorlagenkonfigurationen(value).pipe(
          switchMap(newValue => {
            this.toastService.removeToast(toast);
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.SuccessMessage',
                {
                  titel: value.titel,
                }
              ) as string
            );
            return [GrundeinstellungenActions.createVorlagenkonfigurationSucceeded({ newValue })];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.removeToast(toast);
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.createVorlagenkonfigurationFailed({ error }));
          })
        );
      })
    );
  });

  // Navigiere zur Vorlagenkonfigurationsübersicht
  postCreateKonfiguration$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GrundeinstellungenActions.createVorlagenkonfigurationSucceeded),
        switchMap(_action => {
          // Wenn der User bereits nicht mehr auf der Vorlagenseite ist, nicht navigieren
          if (
            this.router.url.endsWith(`vorlagenkonfigurationen/neu`) ||
            this.router.url.endsWith(`/bearbeiten`) ||
            (this.router.url.includes(`vorlagenkonfigurationen/`) &&
              this.router.url.endsWith(`/kopieren`))
          ) {
            this.router.navigateByUrl('/grundeinstellungen/vorlagenkonfiguration');
          }
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  requestDeleteKonfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.requestDeleteKonfiguration),
      mergeMap(({ id, laufendeKonfigurationsId, titel }) => {
        return this.konfigurationService.konfigurationHasVorlagen(id).pipe(
          map(hasVorlagen => {
            if (hasVorlagen) {
              import(
                '@dworkflow/shared/components/genehmigungs-modal/genehmigungs-modal.component'
              ).then(c => {
                const modal = this.dialog.open(c.GenehmigungsModalComponent, {
                  panelClass: 'modal-small-size',
                  data: {
                    titelText: this.translate.instant(
                      'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.VorlageExsistiertFrage.Titel'
                    ) as string,
                    bodyText: this.translate.instant(
                      'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.VorlageExsistiertFrage.Text'
                    ) as string,
                    bestaetigenButtonText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Loeschen'
                    ) as string,
                    abbrechenButtonText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Abbrechen'
                    ) as string,
                    schliessenText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Schliessen'
                    ) as string,
                    isLoeschenModal: true,
                  },
                  disableClose: true,
                  ariaLabel: this.translate.instant(
                    'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.VorlageExsistiertFrage.Titel'
                  ) as string,
                });
                modal.componentInstance.bestaetigungClicked.subscribe(_ => {
                  // Eigentlich hat das Anzeigen des Dialogs nichts im Store zu suchen.
                  // Refactor in 2024
                  // eslint-disable-next-line @ngrx/no-dispatch-in-effects
                  this.store.dispatch(
                    GrundeinstellungenActions.deleteVorlagenkonfiguration({
                      id,
                      laufendeKonfigurationsId,
                      titel,
                    })
                  );
                });
                return undefined;
              });
            } else {
              return GrundeinstellungenActions.deleteVorlagenkonfiguration({
                id,
                laufendeKonfigurationsId,
                titel,
              });
            }
          }),
          filter(action => !!action),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.FailedMessage',
                {
                  titel: titel,
                }
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.deleteVorlagenkonfigurationFailed({ error }));
          })
        );
      })
    );
  });

  deleteKonfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.deleteVorlagenkonfiguration),
      mergeMap(({ id, laufendeKonfigurationsId, titel }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.WaitMessage',
            {
              titel: titel,
            }
          ) as string
        );
        return this.konfigurationService
          .deleteVorlagenkonfigurationen(laufendeKonfigurationsId)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.SuccessMessage',
                  {
                    titel: titel,
                  }
                ) as string
              );
              return [
                GrundeinstellungenActions.deleteVorlagenkonfigurationSucceeded({
                  id,
                  laufendeKonfigurationsId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Loeschen.FailedMessage'
                ) as string,
                error
              );
              return of(GrundeinstellungenActions.deleteVorlagenkonfigurationFailed({ error }));
            }),
            finalize(() => this.toastService.removeToast(toast))
          );
      })
    );
  });

  loadKonfigurationen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        GrundeinstellungenActions.loadVorlagenkonfigurationen,
        commonActions.loadVorlagenkonfigurationenUndFormulare
      ),
      mergeMap(() =>
        this.konfigurationService.getVorlagenkonfigurationen().pipe(
          switchMap((konfigurationen: VorlagenkonfigurationModel[]) => [
            GrundeinstellungenActions.loadVorlagenkonfigurationenSucceeded({
              values: konfigurationen,
            }),
          ]),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.loadVorlagenkonfigurationenFailed({ error }));
          })
        )
      )
    );
  });

  loadAnzahlSichtbarerKonfigurationen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadAnzahlSichtbarerVorlagenkonfigurationen),
      mergeMap(() =>
        this.konfigurationService.getAnzahlSichtbarerKonfigurationen().pipe(
          switchMap(anzahl => [
            GrundeinstellungenActions.loadAnzahlSichtbarerVorlagenkonfigurationenSucceeded({
              anzahlSichtbarerKonfigs: anzahl,
            }),
          ]),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.AnzahlSichtbarerLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.AnzahlSichtbarerLaden.FailedMessage'
              ) as string,
              error
            );
            return of(
              GrundeinstellungenActions.loadAnzahlSichtbarerVorlagenkonfigurationenFailed({ error })
            );
          })
        )
      )
    );
  });

  loadKonfiguration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadVorlagenkonfiguration),
      mergeMap(({ laufendeKonfigurationsId }) =>
        this.store
          .select(
            fromStore.selectVorlagenkonfigurationByLaufenderKonfigurationsId(
              laufendeKonfigurationsId
            )
          )
          .pipe(
            take(1),
            switchMap(existingKonfiguration =>
              existingKonfiguration && !existingKonfiguration.schrittKonfigUnvollstaendig
                ? EMPTY
                : this.konfigurationService.getVorlagenkonfiguration(laufendeKonfigurationsId)
            ),
            switchMap(konfiguration => [
              GrundeinstellungenActions.loadVorlagenkonfigurationSucceeded({
                konfiguration: konfiguration,
              }),
            ]),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Laden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Vorlagenkonfiguration.Benachrichtigungen.Laden.FailedMessage'
                ) as string,
                error
              );
              return of(GrundeinstellungenActions.loadVorlagenkonfigurationFailed({ error }));
            })
          )
      )
    );
  });

  /**
   * Vorlagen
   */

  createVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.createVorlage),
      mergeMap(({ vorlage, vorlagenLadeType }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.WaitMessage',
            {
              titel: vorlage.name,
            }
          ) as string
        );
        return this.vorlagenService.createVorlage(vorlage, vorlagenLadeType).pipe(
          switchMap(newValue => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.SuccessMessage',
                {
                  titel: newValue.name,
                }
              ) as string
            );
            return [
              GrundeinstellungenActions.createVorlageSucceeded({ newValue, vorlagenLadeType }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.createVorlageFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  // Navigiere zur Vorlagenübersicht
  navigateAfterCreationOrUpdateVorlage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          GrundeinstellungenActions.createVorlageSucceeded,
          GrundeinstellungenActions.updateVorlageSucceeded
        ),
        switchMap(action => {
          // Wenn der User bereits nicht mehr auf der Vorlagenseite ist, nicht navigieren
          if (
            this.router.url.endsWith(`vorlagen/persoenlich/neu`) ||
            this.router.url.endsWith(`vorlagen/allgemein/neu`) ||
            this.router.url.endsWith(`${action.newValue.vorlagenId}/bearbeiten`) ||
            this.router.url.endsWith(`vorlagen/schnittstelle/neu`)
          ) {
            if (action.vorlagenLadeType === VorlagenLadeType.Persoenliche) {
              this.router.navigateByUrl(`/vorlagen/persoenlich`);
            } else {
              this.router.navigateByUrl(`/grundeinstellungen/vorlagen`);
            }
          }
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  updateVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.updateVorlage),
      mergeMap(({ vorlage, vorlagenLadeType }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.WaitMessage',
            {
              titel: vorlage.name,
            }
          ) as string
        );
        return this.vorlagenService.updateVorlage(vorlage, vorlagenLadeType).pipe(
          switchMap(newValue => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.SuccessMessage',
                {
                  titel: newValue.name,
                }
              ) as string
            );
            return [
              GrundeinstellungenActions.updateVorlageSucceeded({ newValue, vorlagenLadeType }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.updateVorlageFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  deleteVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.deleteVorlage),
      mergeMap(({ vorlage, vorlagenLadeType }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.WaitTitel') as string,
          this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.WaitMessage', {
            titel: vorlage.name,
          }) as string
        );
        return this.vorlagenService.deleteVorlage(vorlage.vorlagenId, vorlagenLadeType).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.SuccessTitel') as string,
              this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.SuccessMessage', {
                titel: vorlage.name,
              }) as string
            );
            return [
              GrundeinstellungenActions.deleteVorlageSucceeded({
                id: vorlage.vorlagenId,
                vorlagenLadeType,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.FailedTitel') as string,
              this.translate.instant('Texte.Vorlage.Aktionen.Loeschen.FailedMessage', {
                titel: vorlage.name,
              }) as string,
              error
            );
            return of(GrundeinstellungenActions.deleteVorlageFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  kopiereVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.vorlageKopieren),
      mergeMap(({ vorlagenId, vorlagenName, kopierenNachVorlagenTyp }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Vorlage.Liste.Kopieren.Benachrichtigung.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Vorlage.Liste.Kopieren.Benachrichtigung.WaitMessage', {
            titel: vorlagenName,
          }) as string
        );
        return this.vorlagenService.kopiereVorlage(vorlagenId, kopierenNachVorlagenTyp).pipe(
          switchMap((vorlage: VorlageModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Vorlage.Liste.Kopieren.Benachrichtigung.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Liste.Kopieren.Benachrichtigung.SuccessMessage',
                {
                  titel: vorlagenName,
                }
              ) as string
            );
            return [
              GrundeinstellungenActions.vorlageKopierenSucceeded({
                vorlage,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Vorlage.Liste.Kopieren.Benachrichtigung.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Liste.Kopieren.Benachrichtigung.FailedMessage',
                {
                  titel: vorlagenName,
                }
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.vorlageKopierenFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  loadVorlagen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadVorlagen),
      mergeMap(({ vorlagenLadeType }) =>
        this.vorlagenService.getVorlagen(vorlagenLadeType).pipe(
          switchMap((vorlagen: VorlageModel[]) => {
            return [
              GrundeinstellungenActions.loadVorlagenSucceeded({
                values: vorlagen,
                vorlagenLadeType,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.loadVorlagenFailed({ error }));
          })
        )
      )
    );
  });

  loadVorlagenZumWfAnlegen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadVorlagenZumWfAnlegen),
      mergeMap(({ currentUserId }) =>
        this.vorlagenService.getVorlagenZumWfAnlegen().pipe(
          switchMap((vorlagen: VorlageModel[]) => {
            return [
              GrundeinstellungenActions.loadVorlagenZumWfAnlegenSucceeded({
                values: vorlagen,
                currentUserId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Bearbeiten.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.loadVorlagenZumWfAnlegenFailed({ error }));
          })
        )
      )
    );
  });

  loadResolvedVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadResolvedVorlage),
      mergeMap(({ vorlagenId }) =>
        this.vorlagenService.getVorlage(vorlagenId, true).pipe(
          switchMap((vorlage: VorlageModel) => {
            return [
              GrundeinstellungenActions.loadResolvedVorlageSucceeded({
                value: vorlage,
              }),
              // Laden der Vorlagenkonfiguration, um im Wizard den Step zu ueberspringen
              GrundeinstellungenActions.loadVorlagenkonfiguration({
                laufendeKonfigurationsId: vorlage.laufendeKonfigurationsId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(GrundeinstellungenActions.loadResolvedVorlageFailed({ vorlagenId, error }));
          })
        )
      )
    );
  });

  vorlageFreigeben$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.vorlageFreigeben),
      mergeMap(({ vorlagenName, vorlagenId, principalIds, vorlagenLadeType }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant('Texte.Vorlage.Freigeben.WaitTitel') as string,
          this.translate.instant('Texte.Vorlage.Freigeben.WaitMessage', {
            titel: vorlagenName,
          }) as string
        );

        return this.vorlagenService.vorlageFreigeben(principalIds, vorlagenId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant('Texte.Vorlage.Freigeben.SuccessTitel') as string,
              this.translate.instant('Texte.Vorlage.Freigeben.SuccessMessage', {
                titel: vorlagenName,
              }) as string
            );
            return of(
              GrundeinstellungenActions.vorlageFreigebenSucceeded({
                vorlagenId,
                principalIds,
                vorlagenLadeType,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant('Texte.Vorlage.Freigeben.FailedTitel') as string,
              this.translate.instant('Texte.Vorlage.Freigeben.FailedMessage', {
                titel: vorlagenName,
              }) as string,
              error
            );
            return of(GrundeinstellungenActions.vorlageFreigebenFailed({ error }));
          }),
          finalize(() => this.toastService.removeToast(toast))
        );
      })
    );
  });

  loadAlleTexte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadAlleTexte),
      mergeMap(_ => [
        GrundeinstellungenActions.loadCombinedTexteWithoutCache(),
        GrundeinstellungenActions.loadDefaultTexte(),
      ])
    );
  });

  loadDefaultTexte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadDefaultTexte),
      mergeMap(_ =>
        this.workflowService.getDefaultTexte().pipe(
          switchMap((texte: string) =>
            of(GrundeinstellungenActions.loadDefaultTexteSucceeded({ texte }))
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.loadDefaultTexteFailed({ error }));
          })
        )
      )
    );
  });

  loadCombinedTexteWithoutCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.loadCombinedTexteWithoutCache),
      mergeMap(_ =>
        this.workflowService.getCombinedTexteWithoutCache().pipe(
          switchMap((texte: string) => {
            const jsonTexte = JSON.parse(texte) as Record<string, unknown>;
            this.translate.setTranslation('config', jsonTexte, true);
            return of(GrundeinstellungenActions.loadCombinedTexteWithoutCacheSucceeded({ texte }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.loadCombinedTexteWithoutCacheFailed({ error }));
          })
        )
      )
    );
  });

  putCustomTexte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.postCustomTexte),
      mergeMap(({ texte }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.WaitMessage'
          ) as string
        );
        return this.workflowService.postCustomTexte(texte).pipe(
          switchMap((texte: string) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.SuccessMessage'
              ) as string
            );
            return of(GrundeinstellungenActions.postCustomTexteSucceeded({ texte }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.KonfigurierbareTexte.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.postCustomTexteFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  putCustomTexteSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GrundeinstellungenActions.postCustomTexteSucceeded),
        switchMap(action => {
          const texte = JSON.parse(action.texte) as Record<string, unknown>;
          this.translate.setTranslation('config', texte, true);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  importVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.importVorlage),
      mergeMap(({ vorlagenFileContent, vorlagenLadeType }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.WaitMessage'
          ) as string
        );
        return this.vorlagenService.importVorlage(vorlagenFileContent).pipe(
          switchMap(({ vorlage, konfiguration }) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.SuccessMessage',
                {
                  titel: vorlage.name,
                  konfigTitel: konfiguration.titel,
                }
              ) as string
            );
            return [
              GrundeinstellungenActions.importVorlageSucceeded({
                vorlage,
                konfiguration,
                vorlagenLadeType,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 400 || error.status === 404) {
              this.toastService.showWarning(
                this.translate.instant(
                  'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.FailedTitel'
                ) as string,
                error.error as string
              );
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.FailedMessage'
                ) as string,
                error
              );
            }
            return of(GrundeinstellungenActions.importVorlageFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  importVorlageSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GrundeinstellungenActions.importVorlageSucceeded),
        mergeMap(vk => {
          return this.konfigurationService
            .getVorlagenkonfiguration(vk.konfiguration.laufendeKonfigurationsId)
            .pipe(
              map(vk => {
                if (vk.schrittKonfigUnvollstaendig) {
                  throw new Error('Vorlagenkonfig wurde nicht vollständig angelegt');
                } else {
                  return vk;
                }
              }),
              retry({ delay: 1000, count: 10 })
            );
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.showHttpErrorResponse(
            this.translate.instant(
              'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.FailedTitel'
            ) as string,
            this.translate.instant(
              'Texte.Vorlage.Aktionen.Importieren.Benachrichtigungen.FailedMessage'
            ) as string,
            error
          );
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );

  /**
   * StartseitenBeschreibung
   */
  updateStartseitenBeschreibung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GrundeinstellungenActions.updateStartseitenBeschreibung),
      mergeMap(({ startseitenBeschreibung }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.WaitMessage'
          ) as string
        );
        return this.workflowService.updateStartseitenBeschreibung(startseitenBeschreibung).pipe(
          switchMap((texte: string) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.SuccessMessage'
              ) as string
            );
            return of(GrundeinstellungenActions.updateStartseitenBeschreibungSucceeded({ texte }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Startseite.Benachrichtigungen.Update.FailedMessage'
              ) as string,
              error
            );
            return of(GrundeinstellungenActions.updateStartseitenBeschreibungFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  updateStartseitenBeschreibungSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GrundeinstellungenActions.updateStartseitenBeschreibungSucceeded),
        switchMap(action => {
          const texte = JSON.parse(action.texte) as Record<string, unknown>;
          this.translate.setTranslation('config', texte, true);
          return EMPTY;
        })
      );
    },
    { dispatch: false }
  );
}

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationTranslationsService extends MatPaginatorIntl {
  itemsPerPageLabel = 'pro Seite:';
  nextPageLabel = 'vor';
  previousPageLabel = 'zurück';
  ofLabel = 'von';
  firstPageLabel = 'erste Seite';
  lastPageLabel = 'letzte Seite';
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return '0 ' + this.ofLabel + ' ' + length.toString();
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return (
      (startIndex + 1).toString() +
      ' - ' +
      endIndex.toString() +
      ' ' +
      this.ofLabel +
      ' ' +
      length.toString()
    );
  };

  constructor(private translate: TranslateService) {
    super();
    this.translateLabels();
  }

  translateLabels(): void {
    this.itemsPerPageLabel = this.translate.instant(
      'Texte.Utilities.Tabelle.ItemsProSeite'
    ) as string;
    this.nextPageLabel = this.translate.instant('Texte.Utilities.Tabelle.SeiteVor') as string;
    this.previousPageLabel = this.translate.instant(
      'Texte.Utilities.Tabelle.SeiteZurück'
    ) as string;
    this.ofLabel = this.translate.instant('Texte.Utilities.Tabelle.Von') as string;
    this.firstPageLabel = this.translate.instant('Texte.Utilities.Tabelle.ErsteSeite') as string;
    this.lastPageLabel = this.translate.instant('Texte.Utilities.Tabelle.LetzteSeite') as string;
  }
}

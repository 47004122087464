import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@dworkflow/shared/services/local-storage.service';
import {
  BannerLevel,
  BannerMessageModel,
} from '@dworkflow/shared/model/security/bannerMessage.model';
import * as fromStore from '@dworkflow/state/security.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'dworkflow-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [NgClass, MatIconModule, MatButtonModule, AsyncPipe, TranslateModule],
})
export class BannerComponent implements OnInit {
  constructor(private store: Store, private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    this.ausgeblendeteBanner$.next(
      JSON.parse(this.localStorageService.getItem(this.bannerLocalStorageKey)) as number[]
    );
  }

  bannerLocalStorageKey = 'dworkflow-banner';
  ausgeblendeteBanner$ = new BehaviorSubject<number[]>([]);
  bannerLevel = BannerLevel;
  bannerBenachrichtigungen$ = combineLatest([
    this.store.select(fromStore.selectBannerbenachrichtigungen),
    this.ausgeblendeteBanner$,
  ]).pipe(
    map(([stateBanner, ausgeblendeteBanner]) => {
      return stateBanner?.filter(x => !ausgeblendeteBanner?.some(y => y === x.id)) ?? [];
    })
  );

  removeBanner(banner: BannerMessageModel): void {
    let items = JSON.parse(
      this.localStorageService.getItem(this.bannerLocalStorageKey)
    ) as number[];
    if (!items) {
      items = [];
    }
    items.push(banner.id);
    this.localStorageService.setItem(this.bannerLocalStorageKey, JSON.stringify(items));
    this.ausgeblendeteBanner$.next(items);
  }

  trackBenachrichtigungBy(_index: number, benachrichtigung: BannerMessageModel): string {
    return benachrichtigung.identifier;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldArrayType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
/* eslint-disable @angular-eslint/template/use-track-by-function */

@Component({
  selector: 'dworkflow-formly-repeating-section',
  template: `<div class="repeating-section" [id]="id">
    @if (props.label) {
      <legend class="mat-headline-3">{{ props.label }}</legend>
    }
    @if (props.description) {
      <p>{{ props.description }}</p>
    }

    @for (subfield of field.fieldGroup; track subfield; let i = $index) {
      <div class="flex-row repeating-section-column">
        <formly-field [field]="subfield"></formly-field>
        @if (!field.props['hideRemoveButton']) {
          <div class="repeating-section-remove-button">
            <button
              mat-button
              type="button"
              class="mat-link entfernen-button"
              [attr.aria-label]="props.removeButtonAriaLabel"
              (click)="remove(i)"
              [matTooltip]="props.removeButtonTooltip"
              [disabled]="
                field.formControl.disabled || field.props['minRows'] >= field.fieldGroup.length
              "
            >
              <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
            </button>
          </div>
        }
      </div>
    }
    @if (!field.props['hideAddButton']) {
      <div>
        <button
          mat-button
          type="button"
          class="mat-link"
          (click)="add(); onAfterAdd()"
          [attr.aria-label]="props.addButtonAriaLabel"
          [disabled]="
            field.formControl.disabled || field.props['maxRows'] <= field.fieldGroup.length
          "
        >
          <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>&nbsp;
          {{ props.addButtonText }}
        </button>
      </div>
    }
  </div>`,
  standalone: true,
  imports: [FormlyModule, MatButtonModule, MatTooltipModule, MatIconModule],
})
export class FormlyRepeatingSectionFieldTypeComponent extends FieldArrayType implements OnInit {
  ngOnInit(): void {
    if (this.field.props['minRows']) {
      while (this.field.fieldGroup.length < this.field.props['minRows']) {
        this.add(undefined, undefined, {
          markAsDirty: false,
        });
        this.onAfterAdd();
      }
    }
  }

  onAfterAdd(i?: number): void {
    // Ensure unqiue ids in repeat (workaround da formly nicht selbst darauf achtet)
    i = i == null ? this.field.fieldGroup.length : i;
    if (i > 1) {
      const idPostFix = '_' + String(i);
      const newField = this.field.fieldGroup[i - 1];
      this.setIdPostFix(newField, idPostFix);
    }
  }

  private setIdPostFix(field: FormlyFieldConfig, idPostFix: string): void {
    if (field.id) {
      field.id = field.id + idPostFix;
    }
    if (field.fieldGroup) {
      field.fieldGroup.forEach(field => this.setIdPostFix(field, idPostFix));
    }
    if (field.fieldArray) {
      this.setIdPostFix(field.fieldArray as FormlyFieldConfig, idPostFix);
    }
  }
}

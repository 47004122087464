import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// Guard fuer Routen, die nur von FachAdmins oder SysAdmins geoeffnet werden duerfen
export class CanActivateAdministratorGuard {
  constructor(private securityService: SecurityService, private tenantService: TenantService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Zugriff auf SystemAdmins einschraenken durch Setzen von 'restrictedToSystemAdminsOnly'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const restrictedToSystemAdminsOnly = route.data?.restrictedToSystemAdminsOnly;

    return this.securityService.getCurrentUser().pipe(
      map(user => {
        if (
          (restrictedToSystemAdminsOnly && user.isSystemAdmin) ||
          (!restrictedToSystemAdminsOnly && (user.isWorkflowAdministrator || user.isSystemAdmin))
        ) {
          return true;
        }

        this.tenantService.redirectForbidden();

        return false;
      })
    );
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(CanActivateAdministratorGuard).canActivate(next, state);
};

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PrincipalModel } from '../model/security/principal.model';

@Injectable({
  providedIn: 'root',
})
export class VertreterServiceInternal {
  constructor(private http: HttpClient) {}

  public getVorgesetzte(): Observable<PrincipalModel[]> {
    return this.http.get<PrincipalModel[]>(
      `${environment.security.vertretungEndpoint}/meine-vorgesetzten`
    );
  }

  public getVertretungen(): Observable<PrincipalModel[]> {
    return this.http.get<PrincipalModel[]>(
      `${environment.security.vertretungEndpoint}/vertretungen`
    );
  }

  public getMitarbeiter(): Observable<PrincipalModel[]> {
    return this.http.get<PrincipalModel[]>(
      `${environment.security.vertretungEndpoint}/mitarbeiter-vertretungen`
    );
  }

  public getVertreter(): Observable<PrincipalModel[]> {
    return this.http.get<PrincipalModel[]>(
      `${environment.security.vertretungEndpoint}/meine-vertreter`
    );
  }

  public vertreterHinzufuegen(vertreter: PrincipalModel): Observable<PrincipalModel> {
    return this.http.post<PrincipalModel>(
      `${environment.security.vertretungEndpoint}/meine-vertreter`,
      vertreter.id
    );
  }

  public vertreterEntfernen(vertreterId: number): Observable<unknown> {
    const params = new HttpParams().set('vertreterId', vertreterId.toString());
    return this.http.delete(`${environment.security.vertretungEndpoint}/meine-vertreter`, {
      params,
    });
  }
}

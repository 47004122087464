import { AufgabenErgebnis, SchrittModel, SchrittStatus } from '@dworkflow/shared/model';
import { guid } from '@dworkflow/shared/utility/guid';
import * as commonActions from '@dworkflow/state/common.actions';
import { createReducer, on } from '@ngrx/store';
import lodash from 'lodash';
import { AufgabenModel } from '../shared/model/aufgaben.model';
import { AufgabenStatus } from '../shared/model/aufgaben.status.enum';
import * as schrittActions from './schritt.actions';
import {
  SchrittState,
  aufgabenAdapter,
  initialSchrittState,
  schrittAdapter,
  schritteForWorkflowLoadingErrorsAdapter,
  schritteForWorkflowTimestampAdapter,
  selectAufgaben,
  selectAufgabenForWorkflow,
  selectSchritteForWorkflow,
} from './schritt.state';

export const schrittReducer = createReducer(
  initialSchrittState,
  on(
    schrittActions.erledigungsschrittCreate,
    schrittActions.genehmigungsschrittCreate,
    schrittActions.parallelerErledigungsschrittCreate,
    schrittActions.verfuegungsschrittCreate,
    schrittActions.formularschrittCreate,
    schrittActions.zustaendigkeitsschrittCreate,
    schrittActions.abstimmungsschrittCreate,
    schrittActions.emailSchrittCreate,
    schrittActions.verzweigungsSchrittCreate,
    (state, { schritt }) => {
      const freshCreatedWorkflowIds = lodash.uniq([
        ...state.freshCreatedWorkflowIds,
        schritt.workflowId,
      ]);
      return {
        ...state,
        freshCreatedWorkflowIds,
      };
    }
  ),
  on(
    schrittActions.erledigungsschrittCreateSucceeded,
    schrittActions.genehmigungsschrittCreateSucceeded,
    schrittActions.parallelerErledigungsschrittCreateSucceeded,
    schrittActions.verfuegungsschrittCreateSucceeded,
    schrittActions.formularschrittCreateSucceeded,
    schrittActions.zustaendigkeitsschrittCreateSucceeded,
    schrittActions.abstimmungsschrittCreateSucceeded,
    schrittActions.emailSchrittCreateSucceeded,
    schrittActions.verzweigungsSchrittCreateSucceeded,
    schrittActions.WebserviceschrittActions.createSucceeded,
    (state, { schritt }) => {
      const newAufgaben = schritt.aufgaben?.some(a => a)
        ? aufgabenAdapter.upsertMany(schritt.aufgaben ?? [], state.aufgaben)
        : state.aufgaben;
      return {
        ...state,
        schritte: schrittAdapter.upsertOne(schritt, state.schritte),
        freshCreatedWorkflowIds: state.freshCreatedWorkflowIds.filter(
          x => x !== schritt.workflowId
        ),
        aufgaben: newAufgaben,
      };
    }
  ),
  on(
    schrittActions.erledigungsschrittCreateFailed,
    schrittActions.genehmigungsschrittCreateFailed,
    schrittActions.parallelerErledigungsschrittCreateFailed,
    schrittActions.verfuegungsschrittCreateFailed,
    schrittActions.formularschrittCreateFailed,
    schrittActions.zustaendigkeitsschrittCreateFailed,
    schrittActions.abstimmungsschrittCreateFailed,
    schrittActions.emailSchrittCreateFailed,
    schrittActions.verzweigungsSchrittCreateFailed,
    schrittActions.WebserviceschrittActions.createFailed,
    (state): SchrittState => state // TODO Server Error behandeln?
  ),
  on(
    schrittActions.loadSchritteForWorkflowSucceeded,
    (state, { schritteFuerWorkflow, workflowId }) => {
      const aufgaben = schritteFuerWorkflow.schritte.flatMap(s => s.aufgaben);
      return {
        ...updateWorkflowAufgaben(aufgaben, workflowId, state),
        schritte: schrittAdapter.upsertMany(schritteFuerWorkflow.schritte, state.schritte),
        schritteForWorkflowTimestamp: schritteForWorkflowTimestampAdapter.upsertOne(
          {
            workflowId: workflowId,
            timestamp: schritteFuerWorkflow.timestamp,
          },
          state.schritteForWorkflowTimestamp
        ),
        schritteForWorkflowLoadingErrors: schritteForWorkflowLoadingErrorsAdapter.upsertOne(
          { workflowId: workflowId, error: null },
          state.schritteForWorkflowLoadingErrors
        ),
      };
    }
  ),
  on(schrittActions.loadSchritteForWorkflowFailed, (state, { workflowId, error }) => ({
    ...state,
    schritteForWorkflowLoadingErrors: schritteForWorkflowLoadingErrorsAdapter.upsertOne(
      { workflowId: workflowId, error: error },
      state.schritteForWorkflowLoadingErrors
    ),
  })),
  on(
    schrittActions.erledigungsschrittAbschliessen,
    schrittActions.parallelerErledigungsschrittAbschliessen,
    schrittActions.genehmigungsschrittAbschliessen,
    schrittActions.zustaendigkeitsschrittAbschliessen,
    (state, { kommentar, aufgabe, ergebnis }) => {
      const erledigteAufgabe: AufgabenModel = lodash.clone(aufgabe);
      erledigteAufgabe.status = AufgabenStatus.WirdAbgeschlossen;
      erledigteAufgabe.ergebnis = ergebnis;
      erledigteAufgabe.ausfuehrungskommentar = kommentar;

      const schritt: SchrittModel = lodash.cloneDeep(
        state.schritte.entities[erledigteAufgabe.schrittId]
      );
      schritt.aufgaben.filter(x => x.aufgabenId !== erledigteAufgabe.aufgabenId);
      schritt.aufgaben.push(erledigteAufgabe);
      return {
        ...state,
        schritte: schrittAdapter.upsertOne(schritt, state.schritte),
        aufgaben: aufgabenAdapter.upsertOne(erledigteAufgabe, state.aufgaben),
      };
    }
  ),
  on(
    schrittActions.abstimmungsschrittAbschliessen,
    (state, { aufgabe, ergebnis, abstimmungsOptionen }) => {
      const erledigteAufgabe: AufgabenModel = lodash.clone(aufgabe);
      erledigteAufgabe.status = AufgabenStatus.WirdAbgeschlossen;
      erledigteAufgabe.ergebnis = ergebnis;
      erledigteAufgabe.ausfuehrungskommentar = abstimmungsOptionen[0];
      return {
        ...state,
        aufgaben: aufgabenAdapter.upsertOne(erledigteAufgabe, state.aufgaben),
      };
    }
  ),
  on(schrittActions.rueckfrageStellen, (state, { kommentar, aufgabe, ergebnis }) => {
    const erledigteAufgabe: AufgabenModel = lodash.clone(aufgabe);
    erledigteAufgabe.status = AufgabenStatus.WirdUnterbrochen;
    erledigteAufgabe.ergebnis = ergebnis;
    erledigteAufgabe.ausfuehrungskommentar = kommentar;
    return {
      ...state,
      aufgaben: aufgabenAdapter.upsertOne(erledigteAufgabe, state.aufgaben),
    };
  }),
  on(
    schrittActions.verfuegungsschrittPrepareAbschliessen,
    schrittActions.verfuegungsschrittAbschliessen,
    schrittActions.formularschrittAbschliessen,
    schrittActions.formularschrittPrepareAbschliessen,
    schrittActions.verzweigungsschrittAbschliessen,
    (state, { aufgabe }) => {
      const erledigteAufgabe: AufgabenModel = lodash.clone(aufgabe);
      erledigteAufgabe.status = AufgabenStatus.WirdAbgeschlossen;
      erledigteAufgabe.zusaetzlicheEigenschaften = null;
      return {
        ...state,
        aufgaben: aufgabenAdapter.upsertOne(erledigteAufgabe, state.aufgaben),
      };
    }
  ),
  on(
    schrittActions.erledigungsschrittAbschliessenFailed,
    schrittActions.parallelerErledigungsschrittAbschliessenFailed,
    schrittActions.genehmigungsschrittAbschliessenFailed,
    schrittActions.verfuegungsschrittAbschliessenFailed,
    schrittActions.formularschrittAbschliessenFailed,
    schrittActions.zustaendigkeitsschrittAbschliessenFailed,
    schrittActions.abstimmungsschrittAbschliessenFailed,
    (state, { aufgabe }) => {
      const erledigteAufgabeFailed: AufgabenModel = lodash.clone(aufgabe);
      erledigteAufgabeFailed.status = AufgabenStatus.Aktiv;
      erledigteAufgabeFailed.ausfuehrungskommentar = '';
      return {
        ...state,
        aufgaben: aufgabenAdapter.upsertOne(erledigteAufgabeFailed, state.aufgaben),
      };
    }
  ),
  on(schrittActions.abstimmungsschrittBeenden, (state, { schrittId }) => {
    const aufgaben = lodash
      .clone(
        Object.values(state.aufgaben.entities).filter(
          (a: AufgabenModel) => a.schrittId === schrittId && a.status === AufgabenStatus.Aktiv
        )
      )
      .map(aufgabe => ({
        id: aufgabe.aufgabenId,
        changes: {
          status: AufgabenStatus.Abgeschlossen,
          ergebnis: AufgabenErgebnis.NichtAbgestimmt,
        },
      }));

    return {
      ...state,
      aufgaben: aufgabenAdapter.updateMany(aufgaben, state.aufgaben),
    };
  }),
  on(
    schrittActions.loadMeineAufgabenSucceeded,
    (state, { aufgabenListe, aufgabenFuerStartseite }) => {
      if (aufgabenListe.aufgaben === null) {
        if (aufgabenFuerStartseite) {
          return { ...state, meineAufgabenFuerStartseiteLoaded: true };
        }

        return { ...state, meineAufgabenLoaded: true };
      }

      return {
        ...state,
        aufgaben: aufgabenAdapter.upsertMany(
          aufgabenListe.aufgaben?.map(a => a.aufgabe),
          state.aufgaben
        ),
        [aufgabenFuerStartseite ? 'meineAufgabenFuerStartseite' : 'meineAufgaben']:
          aufgabenListe.aufgaben.map(a => a.aufgabe.aufgabenId),
        [aufgabenFuerStartseite ? 'meineAufgabenFuerStartseiteLoaded' : 'meineAufgabenLoaded']:
          true,
        [aufgabenFuerStartseite
          ? 'meineAufgabenFuerStartseiteTimeStamp'
          : 'meineAufgabenTimeStamp']: aufgabenListe.timestamp,
        meineAufgabenAnzahl: aufgabenFuerStartseite
          ? state.meineAufgabenAnzahl
          : aufgabenListe.aufgaben.filter(a => a.aufgabe.status === AufgabenStatus.Aktiv).length,
      };
    }
  ),
  on(schrittActions.loadMeineAufgabenAnzahlSucceeded, (state, { anzahl }): SchrittState => {
    return {
      ...state,
      meineAufgabenAnzahl: anzahl,
    };
  }),
  on(schrittActions.loadVertretungenAufgabenSucceeded, (state, { principalId, aufgabenListe }) => {
    if (aufgabenListe.aufgaben === null) {
      return {
        ...state,
        vertretungenAufgabenLoaded: {
          ...state.vertretungenAufgabenLoaded,
          [principalId]: true,
        },
      };
    }

    return {
      // Aktualisiere Aufgaben Entities und Mapping von Vetretungen-Aufgaben
      ...state,
      aufgaben: aufgabenAdapter.upsertMany(
        aufgabenListe.aufgaben.map(a => a.aufgabe),
        state.aufgaben
      ),
      vertretungenAufgabenIds: {
        ...state.vertretungenAufgabenIds,
        [principalId]: aufgabenListe.aufgaben.map(x => x.aufgabe.aufgabenId),
      },
      vertretungenAufgabenLoaded: {
        ...state.vertretungenAufgabenLoaded,
        [principalId]: true,
      },
    };
  }),
  on(
    schrittActions.loadEinstellungenSucceeded,
    (state, { einstellungen }): SchrittState => ({
      ...state,
      einstellungen,
    })
  ),
  on(
    schrittActions.speichereSchritteEinstellungenSucceeded,
    (state, { einstellungen }): SchrittState => ({
      ...state,
      einstellungen,
    })
  ),
  on(
    schrittActions.loadBenachrichtigungenSucceeded,
    (state, { benachrichtigungen }): SchrittState => ({
      ...state,
      benachrichtigungen,
    })
  ),
  on(schrittActions.updateBenachrichtigungSucceeded, (state, { model }) => {
    const clone = lodash.cloneDeep(state.benachrichtigungen);
    clone.benachrichtigungen = clone.benachrichtigungen.filter(i => i.typ !== model.typ);
    clone.benachrichtigungen.push(model);
    clone.benachrichtigungen.sort((a, b) => a.typ - b.typ);
    return {
      ...state,
      benachrichtigungen: clone,
    };
  }),
  on(schrittActions.workflowverlaufAngepasst, (state, { workflowverlauf }) => {
    const workflowId = workflowverlauf.workflowId;
    const neueSchritteIds = workflowverlauf.schritte.map(s => s.schrittId);

    // TODO: Der Filter hier erlaubt nur einen Verlauf pro Workflow. Bei Multi-Verläufen dann hier anpassen
    const entfernteSchritteIds = selectSchritteForWorkflow(workflowId)({
      schritte: state,
    })
      .filter(schritt => neueSchritteIds.every(neueId => neueId !== schritt.schrittId))
      .map(s => s.schrittId);

    const entfernteAufgabenIds = selectAufgabenForWorkflow(workflowId)({
      schritte: state,
    })
      .filter(a => entfernteSchritteIds.some(s => s === a.schrittId))
      .map(s => s.aufgabenId);

    const neueVertreteneAufgaben: { [principalId: number]: guid[] } = {};
    for (const principalId of Object.keys(state.vertretungenAufgabenIds).map(k => Number(k))) {
      neueVertreteneAufgaben[principalId] = state.vertretungenAufgabenIds[principalId].filter(
        (a: guid) => entfernteAufgabenIds.every(e => e !== a)
      );
    }

    return {
      ...state,
      schritte: schrittAdapter.removeMany(entfernteSchritteIds, state.schritte),
      aufgaben: aufgabenAdapter.removeMany(entfernteAufgabenIds, state.aufgaben),
      meineAufgaben: state.meineAufgaben.filter(a => entfernteAufgabenIds.every(e => e !== a)),
      vertretungenAufgabenIds: neueVertreteneAufgaben,
    };
  }),
  on(schrittActions.workflowGeloescht, (state, { workflowId }) => {
    const entfernteSchritteIds = selectSchritteForWorkflow(workflowId)({
      schritte: state,
    }).map(s => s.schrittId);

    const entfernteAufgabenIds = selectAufgabenForWorkflow(workflowId)({
      schritte: state,
    }).map(s => s.aufgabenId);

    const gefilterteVertreteneAufgaben: { [principalId: number]: guid[] } = {};
    for (const principalId of Object.keys(state.vertretungenAufgabenIds).map(k => Number(k))) {
      gefilterteVertreteneAufgaben[principalId] = state.vertretungenAufgabenIds[principalId].filter(
        (a: guid) => entfernteAufgabenIds.every(e => e !== a)
      );
    }

    return {
      ...state,
      schritte: schrittAdapter.removeMany(entfernteSchritteIds, state.schritte),
      aufgaben: aufgabenAdapter.removeMany(entfernteAufgabenIds, state.aufgaben),
      meineAufgaben: state.meineAufgaben.filter(a => entfernteAufgabenIds.every(e => e !== a)),
      vertretungenAufgabenIds: gefilterteVertreteneAufgaben,
    };
  }),
  on(schrittActions.workflowverlaufStorniert, (state, { workflowId }) => {
    const stornierteSchritteUpdates = selectSchritteForWorkflow(workflowId)({
      schritte: state,
    })
      .filter(s => s.status !== SchrittStatus.Abgeschlossen)
      .map(s => ({ id: s.schrittId, changes: { status: SchrittStatus.Storniert } }));
    return {
      ...state,
      schritte: schrittAdapter.updateMany(stornierteSchritteUpdates, state.schritte),
    };
  }),
  on(
    commonActions.tenantChanged,
    (_state): SchrittState => ({
      ...initialSchrittState,
    })
  )
);

// Fügt neue Aufgaben ein, updated vorhandene Aufgaben und entfernt nicht mehr vorhandene Aufgaben.
function updateWorkflowAufgaben(
  newAufgaben: AufgabenModel[],
  workflowId: guid,
  state: SchrittState
): SchrittState {
  const newAufgabenIds = newAufgaben.map(a => a.aufgabenId);

  let aufgabenEntities = state.aufgaben;
  let meineAufgabenEntities = state.meineAufgaben;
  if (newAufgabenIds.length > 0) {
    // Entfernte Aufgaben aus dem State auch sicher entfernen,
    // wichtig für den parallelen Erledigungsschritt wo erst bei Aktivierung eine Gruppe in alle User aufgelöst wird
    // darf nur durchgeführt werden, wenn auch Aufgaben übermittelt wurden, sonst werden die Aufgaben nach Workflow
    // Erstellung für den Admin nicht angezeigt
    const oldAufgabenIds = selectAufgaben(state.aufgaben)
      .filter(aufgabe => aufgabe.workflowId === workflowId)
      .map(a => a.aufgabenId);

    const zuEntfernendeAufgabenIds = oldAufgabenIds.filter(
      oldAufgabenId => !newAufgabenIds.includes(oldAufgabenId)
    );

    aufgabenEntities = aufgabenAdapter.removeMany(zuEntfernendeAufgabenIds, state.aufgaben);
    meineAufgabenEntities = state.meineAufgaben.filter(
      id => !zuEntfernendeAufgabenIds.some(rem => rem === id)
    );
  }

  aufgabenEntities = aufgabenAdapter.upsertMany(newAufgaben, aufgabenEntities);
  return <SchrittState>{
    ...state,
    aufgaben: aufgabenEntities,
    meineAufgaben: meineAufgabenEntities,
  };
}

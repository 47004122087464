<div class="container">
  @if (currentUserDisplayName$ | async; as currentUserDisplayName) {
    <h1 class="mat-headline-1">
      {{ 'Texte.Home.Title' | translate: { displayName: currentUserDisplayName } }}
    </h1>
  } @else {
    <h1 class="mat-headline-1">{{ 'Texte.Home.Title' | translate: { displayName: '' } }}</h1>
  }
  @if (tenantService.currentTenant$ | async; as currentTenant) {
    @if (currentTenant?.id !== 0) {
      <p>
        {{
          'Texte.Grundeinstellungen.Startseite.WillkommensText'
            | translate
              : {
                  TenantLangbezeichnung:
                    currentTenant.langbezeichnung ?? currentTenant.name ?? currentTenant.id,
                }
        }}
      </p>
    }
  }
  <p
    class="dworkflow-rich-text"
    [innerHTML]="'Texte.Grundeinstellungen.Startseite.BeschreibungsText' | translate"
  ></p>
  <hr />
  <h2 class="mat-headline-2">
    {{ 'Texte.Home.AktuelleAufgaben' | translate }}
  </h2>
  <p>
    {{ 'Texte.Home.AktuelleAufgabenSubtitle' | translate }}
  </p>
  @if (tenantService.currentTenant?.id > 0) {
    <button
      mat-button
      type="button"
      class="mat-link"
      routerLink="./aufgaben"
      attr.aria-label="{{ 'Texte.Home.AlleAufgabenAriaLabel' | translate }}"
      data-testid="homeRouterLinkMeineAufgaben"
    >
      <mat-icon fontSet="fa" fontIcon="fa-list"></mat-icon>&nbsp;
      {{ 'Texte.Home.AlleAufgaben' | translate }}
    </button>
  }
  <dworkflow-aufgaben-tabelle
    [aufgabenIds$]="meineAufgabenIds$"
    [sortEnabled]="false"
    [showPaginator]="false"
    [hideFilter]="true"
    [loaded]="meineAufgabenLoaded$ | async"
    data-testid="homeMeineAufgabenTabelle"
    filterAndSortPersistenceName="home-aufgaben-tabelle"
  ></dworkflow-aufgaben-tabelle>
  <h2 class="mat-headline-2">
    {{ 'Texte.Home.AktuelleWorkflows' | translate }}
  </h2>
  <p>
    {{ 'Texte.Home.AktuelleWorkflowsSubtitle' | translate }}
  </p>
  @if (tenantService.currentTenant?.id > 0) {
    <button
      mat-button
      type="button"
      class="mat-link"
      routerLink="./workflows"
      data-testid="homeRouterLinkMeineWorkflows"
      attr.aria-label="{{ 'Texte.Home.AlleWorkflowsAriaLabel' | translate }}"
    >
      <mat-icon fontSet="fa" fontIcon="fa-list"></mat-icon>&nbsp;
      {{ 'Texte.Home.AlleWorkflows' | translate }}
    </button>
  }
  <dworkflow-workflow-tabelle
    [workflowIds$]="meineWorkflowIds$"
    [hideFilter]="true"
    [sortEnabled]="false"
    [loaded]="meineWorkflowsLoaded$ | async"
    filterAndSortPersistenceName="home-workflow-tabelle"
  ></dworkflow-workflow-tabelle>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SchritteFuerWorkflowModel } from '@dworkflow/shared/model';
import { BenachrichtigungenModel } from '@dworkflow/shared/model/benachrichtigungen.model';
import { SchrittEinstellungenModel } from '@dworkflow/shared/model/schritt-einstellungen.model';
import { SchrittType } from '@dworkflow/shared/model/schritt.type.enum';
import { AktiveSchritteMitTimestampModel } from '@dworkflow/shared/model/schritte-aktiv-mit-timestamp.model';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast } from 'ngx-toastr';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SchrittReadModel } from '../shared/model/schritt-read.model';
import { SchrittService } from '../shared/services/schritt.service';
import * as commonActions from './common.actions';
import * as engineActions from './engine.actions';
import * as schrittActions from './schritt.actions';
import * as fromStore from './schritt.state';
import * as fromWorkflowTabellenStore from './workflow-tabelle.state';

@Injectable({
  providedIn: 'root',
})
export class SchrittEffects {
  constructor(
    private actions: Actions,
    private schrittService: SchrittService,
    private toastService: ToastService,
    private store: Store,
    private translate: TranslateService
  ) {}

  createSchritt$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        schrittActions.erledigungsschrittCreate,
        schrittActions.genehmigungsschrittCreate,
        schrittActions.parallelerErledigungsschrittCreate,
        schrittActions.verfuegungsschrittCreate,
        schrittActions.formularschrittCreate,
        schrittActions.zustaendigkeitsschrittCreate,
        schrittActions.abstimmungsschrittCreate,
        schrittActions.emailSchrittCreate,
        schrittActions.verzweigungsSchrittCreate,
        schrittActions.WebserviceschrittActions.create
      ),
      mergeMap(action =>
        this.schrittService.createSchritt(action.schritt).pipe(
          switchMap((schritt: SchrittReadModel) => {
            switch (schritt.schritttyp) {
              case SchrittType.Erledigungsschritt:
                return of(schrittActions.erledigungsschrittCreateSucceeded({ schritt }));
              case SchrittType.Genehmigungsschritt:
                return of(schrittActions.genehmigungsschrittCreateSucceeded({ schritt }));
              case SchrittType.ParallelerErledigungsschritt:
                return of(schrittActions.parallelerErledigungsschrittCreateSucceeded({ schritt }));
              case SchrittType.Verfuegungsschritt:
                return of(schrittActions.verfuegungsschrittCreateSucceeded({ schritt }));
              case SchrittType.Formularschritt:
                return of(schrittActions.formularschrittCreateSucceeded({ schritt }));
              case SchrittType.Zustaendigkeitsschritt:
                return of(schrittActions.zustaendigkeitsschrittCreateSucceeded({ schritt }));
              case SchrittType.Abstimmungsschritt:
                return of(schrittActions.abstimmungsschrittCreateSucceeded({ schritt }));
              case SchrittType.EMailschritt:
                return of(schrittActions.emailSchrittCreateSucceeded({ schritt }));
              case SchrittType.Verzweigungsschritt:
                return of(schrittActions.verzweigungsSchrittCreateSucceeded({ schritt }));
              case SchrittType.Webserviceschritt:
                return of(schrittActions.WebserviceschrittActions.createSucceeded({ schritt }));
            }
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Erstellen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Erstellen.FailedMessage',
                {
                  titel: action.schritt.titel,
                }
              ) as string,
              error
            );
            switch (action.schritt.schritttyp) {
              case SchrittType.Erledigungsschritt:
                return of(
                  schrittActions.erledigungsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Genehmigungsschritt:
                return of(
                  schrittActions.genehmigungsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.ParallelerErledigungsschritt:
                return of(
                  schrittActions.parallelerErledigungsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Verfuegungsschritt:
                return of(
                  schrittActions.verfuegungsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Formularschritt:
                return of(
                  schrittActions.formularschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Zustaendigkeitsschritt:
                return of(
                  schrittActions.zustaendigkeitsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Abstimmungsschritt:
                return of(
                  schrittActions.abstimmungsschrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.EMailschritt:
                return of(
                  schrittActions.emailSchrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              case SchrittType.Verzweigungsschritt:
                return of(
                  schrittActions.verzweigungsSchrittCreateFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );

              case SchrittType.Webserviceschritt:
                return of(
                  schrittActions.WebserviceschrittActions.createFailed({
                    schrittId: action.schritt.schrittId,
                    error,
                  })
                );
              default:
                throw new Error(
                  '[SchrittEffect] createSchritt$: Schrittyp Create nicht implementiert'
                );
            }
          })
        )
      )
    );
  });

  erledigungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.erledigungsschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService
          .erledigungsschrittAbschliessen(action.aufgabe.aufgabenId, action.kommentar)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
                ) as string
              );
              return [
                schrittActions.erledigungsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Erledigungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.erledigungsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  parallelerErledigungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.parallelerErledigungsschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService
          .parallelerErledigungsschrittAbschliessen(action.aufgabe.aufgabenId, action.kommentar)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
                ) as string
              );
              return [
                schrittActions.parallelerErledigungsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.ParallelerErledigungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.parallelerErledigungsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  genehmigungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.genehmigungsschrittAbschliessen),
      switchMap(action => {
        let waitToast: ActiveToast<unknown>;
        if (action.genehmigt) {
          waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.WaitGenehmigtTitel'
            ) as string,
            this.translate.instant(
              'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.WaitGenehmigtMessage'
            ) as string
          );
        } else {
          waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.WaitAbgelehntTitel'
            ) as string,
            this.translate.instant(
              'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.WaitAbgelehntMessage'
            ) as string
          );
        }
        return this.schrittService
          .genehmigungsschrittAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.genehmigt,
            action.nextSchrittverantwortlicherId
          )
          .pipe(
            switchMap(() => {
              if (action.genehmigt) {
                this.toastService.showSuccess(
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.SuccessGenehmigtTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.SuccessGenehmigtMessage'
                  ) as string
                );
              } else {
                this.toastService.showSuccess(
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.SuccessAbgelehntTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.SuccessAbgelehntMessage'
                  ) as string
                );
              }
              return [
                schrittActions.genehmigungsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Genehmigungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.genehmigungsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  verfuegungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.verfuegungsschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService.verfuegungsschrittAbschliessen(action.aufgabe.aufgabenId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
              ) as string
            );
            return [
              schrittActions.verfuegungsschrittAbschliessenSucceeded({
                aufgabenId: action.aufgabe.aufgabenId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Verfuegungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
              ) as string,
              error
            );
            return of(
              schrittActions.verfuegungsschrittAbschliessenFailed({
                aufgabe: action.aufgabe,
                error,
              })
            );
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  formularschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.formularschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService
          .formularschrittAbschliessen(action.formularSchrittAbschlussModel.aufgabenId)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
                ) as string
              );
              return [
                schrittActions.formularschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Formularschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.formularschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  zustaendigkeitsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.zustaendigkeitsschrittAbschliessen),
      switchMap(action => {
        let waitToast: ActiveToast<unknown>;
        if (action.zustaendig) {
          waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.WaitZustaendigTitel'
            ) as string,
            this.translate.instant(
              'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.WaitZustaendigMessage'
            ) as string
          );
        } else {
          waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.WaitNichtZustaendigTitel'
            ) as string,
            this.translate.instant(
              'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.WaitNichtZustaendigMessage'
            ) as string
          );
        }
        return this.schrittService
          .zustaendigkeitsschrittAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.zustaendig,
            action.zustaendigerPrincipalId
          )
          .pipe(
            switchMap(() => {
              if (action.zustaendig) {
                this.toastService.showSuccess(
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.SuccessZustaendigTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.SuccessZustaendigMessage'
                  ) as string
                );
              } else {
                this.toastService.showSuccess(
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.SuccessNichtZustaendigTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.SuccessNichtZustaendigMessage'
                  ) as string
                );
              }
              return [
                schrittActions.zustaendigkeitsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Zustaendigkeitsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.zustaendigkeitsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Abstimmungsschritt
  abstimmungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.abstimmungsschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService
          .abstimmungsschrittAbschliessen(action.aufgabe.aufgabenId, action.abstimmungsOptionen)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
                ) as string
              );
              return [
                schrittActions.abstimmungsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.abstimmungsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  abstimmungsschrittBeenden$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.abstimmungsschrittBeenden),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.WaitMessage'
          ) as string
        );
        return this.schrittService.abstimmungsschrittBeenden(action.schrittId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.SuccessMessage'
              ) as string
            );
            return [
              schrittActions.abstimmungsschrittBeendenSucceeded({
                schrittId: action.schrittId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Abstimmungsschritt.Benachrichtigungen.Beenden.FailedMessage'
              ) as string,
              error
            );
            return of(
              schrittActions.abstimmungsschrittBeendenFailed({
                schrittId: action.schrittId,
                error,
              })
            );
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  verzweigungsschrittAbschliessen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.verzweigungsschrittAbschliessen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.WaitMessage'
          ) as string
        );
        return this.schrittService
          .verzweigungsschrittAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.verzweigungsstrangId
          )
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.SuccessMessage'
                ) as string
              );
              return [
                schrittActions.verzweigungsschrittAbschliessenSucceeded({
                  aufgabenId: action.aufgabe.aufgabenId,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.Verzweigungsschritt.Benachrichtigungen.Abschliessen.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.verzweigungsschrittAbschliessenFailed({
                  aufgabe: action.aufgabe,
                  error,
                })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Rueckfrage
  rueckfrageStellen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.rueckfrageStellen),
      switchMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.WaitMessage'
          ) as string
        );

        let schritt$: Observable<unknown>;

        if (action.schritttyp === SchrittType.Erledigungsschritt) {
          schritt$ = this.schrittService.erledigungsschrittMitRueckfrageAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.rueckfrageAdressatPrincipalId
          );
        } else if (action.schritttyp === SchrittType.Genehmigungsschritt) {
          schritt$ = this.schrittService.genehmigungsschrittMitRueckfrageAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.rueckfrageAdressatPrincipalId
          );
        } else if (action.schritttyp === SchrittType.Zustaendigkeitsschritt) {
          schritt$ = this.schrittService.zustaendigkeitsschrittMitRueckfrageAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.rueckfrageAdressatPrincipalId
          );
        } else if (action.schritttyp === SchrittType.Verzweigungsschritt) {
          schritt$ = this.schrittService.verzweigungsschrittMitRueckfrageAbschliessen(
            action.aufgabe.aufgabenId,
            action.kommentar,
            action.rueckfrageAdressatPrincipalId
          );
        } else {
          // In den Zustand sollten wir nicht kommen.
          throw Error(`Es kann keine Rückfrage zum Typ ${action.schritttyp} gestellt werden.`);
        }

        return schritt$.pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.SuccessMessage'
              ) as string
            );

            return [
              schrittActions.rueckfrageStellenSucceeded({
                aufgabenId: action.aufgabe.aufgabenId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Workflow.Schritt.Rueckfrage.Benachrichtigungen.Absenden.FailedMessage'
              ) as string,
              error
            );
            return of(
              schrittActions.rueckfrageStellenFailed({
                aufgabe: action.aufgabe,
                error,
              })
            );
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  prepareloadSchritteForWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadWorkflow, schrittActions.prepareLoadSchritteForWorkflow),
      switchMap(action => {
        return combineLatest([
          this.store.select(fromStore.selectTimestampByWorkflowId(action.workflowId)),
          // eslint-disable-next-line @ngrx/avoid-combining-selectors
          this.store.select(
            fromStore.selectShouldIgnoreAuthErrorsOnGetSchritteForWorkflow(action.workflowId)
          ),
        ]).pipe(
          take(1),
          map(([timestamp, ignoreAuthErrors]) =>
            schrittActions.loadSchritteForWorkflow({
              workflowId: action.workflowId,
              timestamp,
              ignoreAuthErrors,
            })
          )
        );
      })
    );
  });

  loadSchritteForWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.loadSchritteForWorkflow),
      mergeMap(action =>
        this.schrittService.getSchritteForWorkflow(action.workflowId, action.timestamp).pipe(
          switchMap((schritteFuerWorkflow: SchritteFuerWorkflowModel) => {
            return [
              schrittActions.loadSchritteForWorkflowSucceeded({
                schritteFuerWorkflow,
                workflowId: action.workflowId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: State muss nicht aktualisiert werden
              return EMPTY;
            } else if (
              error instanceof HttpErrorResponse &&
              error.status === 403 &&
              action.ignoreAuthErrors
            ) {
              return EMPTY;
            } else {
              return of(
                schrittActions.loadSchritteForWorkflowFailed({
                  workflowId: action.workflowId,
                  error,
                })
              );
            }
          })
        )
      )
    );
  });

  loadAktiveSchritteForWorkflows$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        engineActions.loadMeineWorkflows,
        engineActions.loadMeineWorkflowsFuerStartseite,
        engineActions.loadAlleWorkflows,
        commonActions.loadVertretungenAufgabenUndWorkflows
      ),
      exhaustMap(_ =>
        this.store.select(fromWorkflowTabellenStore.selectAktiveSchritteTimestamp).pipe(
          take(1),
          switchMap((timestamp: string) =>
            this.schrittService.getAktiveSchritteForWorkflows(timestamp).pipe(
              switchMap((aktiveSchritte: AktiveSchritteMitTimestampModel) => {
                return [
                  schrittActions.loadAktiveSchritteForWorkflowsSucceeded({
                    aktiveSchritte,
                  }),
                ];
              }),
              catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse && error.status === 304) {
                  // NotModified: State muss nicht aktualisiert werden
                  return EMPTY;
                } else {
                  return of(
                    schrittActions.loadAktiveSchritteForWorkflowsFailed({
                      error,
                    })
                  );
                }
              })
            )
          )
        )
      )
    );
  });

  loadMeineAufgaben$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.loadMeineAufgaben),
      switchMap(action =>
        this.store.pipe(
          take(1),
          map(store =>
            action.anzahl && action.anzahl > 0
              ? fromStore.selectMeineAufgabenFuerStartseiteTimeStamp()(store)
              : fromStore.selectMeineAufgabenTimeStamp()(store)
          ),
          switchMap(timeStamp => {
            return this.schrittService.loadAufgaben(action.anzahl, timeStamp).pipe(
              switchMap(aufgabenListe => {
                return [
                  schrittActions.loadMeineAufgabenSucceeded({
                    aufgabenListe,
                    aufgabenFuerStartseite: action.anzahl && action.anzahl > 0,
                  }),
                ];
              }),
              catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse && error.status === 304) {
                  // NotModified: State muss nicht aktualisiert werden
                  return EMPTY;
                } else {
                  this.toastService.showHttpErrorResponse(
                    this.translate.instant(
                      'Texte.MeineAufgaben.Benachrichtigungen.MeineAufgabenLaden.FailedTitel'
                    ) as string,
                    this.translate.instant(
                      'Texte.MeineAufgaben.Benachrichtigungen.MeineAufgabenLaden.FailedMessage'
                    ) as string,
                    error
                  );

                  return of(schrittActions.loadMeineAufgabenFailed({ error }));
                }
              })
            );
          })
        )
      )
    );
  });

  loadMeineAufgabenAnzahl$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadAnzahlen, schrittActions.loadMeineAufgabenAnzahl),
      switchMap(_ =>
        this.schrittService.loadAufgabenAnzahlForPrincipal().pipe(
          switchMap(anzahl => {
            return of(schrittActions.loadMeineAufgabenAnzahlSucceeded({ anzahl }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.MeineAufgaben.Benachrichtigungen.MeineAufgabenAnzahlLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineAufgaben.Benachrichtigungen.MeineAufgabenAnzahlLaden.FailedMessage'
              ) as string,
              error
            );

            return of(schrittActions.loadMeineAufgabenAnzahlFailed({ error }));
          })
        )
      )
    );
  });

  loadVertretungenAufgaben$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        schrittActions.loadVertretungenAufgaben,
        commonActions.loadVertretungenAufgabenUndWorkflows
      ),
      switchMap(({ principalId }) => {
        return this.schrittService.loadAufgabenForPrincipal(-1, '0', principalId).pipe(
          switchMap(aufgabenListe => [
            schrittActions.loadVertretungenAufgabenSucceeded({ principalId, aufgabenListe }),
          ]),
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.status === 304) {
              // NotModified: State muss nicht aktualisiert werden
              return EMPTY;
            } else if (error instanceof HttpErrorResponse && error.status === 403) {
              this.toastService.showError(
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.AufgabenLaden.UnauthorizedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.AufgabenLaden.UnauthorizedMessage'
                ) as string
              );
              return EMPTY;
            } else {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.AufgabenLaden.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Vertretungen.Benachrichtigungen.AufgabenLaden.FailedMessage'
                ) as string,
                error
              );

              return of(schrittActions.loadVertretungenAufgabenFailed({ principalId, error }));
            }
          })
        );
      })
    );
  });

  onAufgabeAbgeschlossen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        schrittActions.erledigungsschrittAbschliessenSucceeded,
        schrittActions.verfuegungsschrittAbschliessenSucceeded,
        schrittActions.genehmigungsschrittAbschliessenSucceeded,
        schrittActions.parallelerErledigungsschrittAbschliessenSucceeded,
        schrittActions.formularschrittAbschliessenSucceeded,
        schrittActions.rueckfrageStellenSucceeded,
        schrittActions.workflowverlaufStorniert
      ),
      mergeMap(_ => {
        return [schrittActions.loadMeineAufgabenAnzahl()];
      })
    );
  });

  /**
   * Einstellungen-effects
   */
  loadEinstellungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadEinstellungenUndArbeitsgruppen, schrittActions.loadEinstellungen),
      mergeMap(_ =>
        this.schrittService.getEinstellungen().pipe(
          switchMap((einstellungen: SchrittEinstellungenModel) =>
            of(schrittActions.loadEinstellungenSucceeded({ einstellungen }))
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return of(schrittActions.loadEinstellungenFailed({ error }));
          })
        )
      )
    );
  });

  // Benachrichtigungen laden
  loadBenachrichtgungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(commonActions.loadBenachrichtigungen),
      mergeMap(_ =>
        this.schrittService.getBenachrichtigungen().pipe(
          map((benachrichtigungen: BenachrichtigungenModel) =>
            schrittActions.loadBenachrichtigungenSucceeded({ benachrichtigungen })
          ),
          catchError((error: HttpErrorResponse) => {
            return of(
              schrittActions.loadBenachrichtigungenFailed({
                error,
              })
            );
          })
        )
      )
    );
  });

  // Benachrichtigung speichern
  updateBenachrichtgung$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.updateBenachrichtigung),
      mergeMap(action => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.WaitMessage'
          ) as string
        );
        return this.schrittService
          .updateBenachrichtigung(
            action.typ,
            action.betreff,
            action.inhalt,
            action.versandAktiviert
          )
          .pipe(
            map(benachrichtigungsmodel => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.SucceededMessage'
                ) as string
              );
              return schrittActions.updateBenachrichtigungSucceeded({
                model: benachrichtigungsmodel,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Benachrichtigungen.UpdateBenachrichtigung.FailedMessage'
                ) as string,
                error
              );
              return of(
                schrittActions.updateBenachrichtigungFailed({
                  error,
                })
              );
            }),
            finalize(() => this.toastService.removeToast(waitToast))
          );
      })
    );
  });

  speichereEinstellungen$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.speichereSchritteEinstellungen),
      mergeMap(action => {
        return this.schrittService.putEinstellungen(action.einstellungen).pipe(
          map((einstellungen: SchrittEinstellungenModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Schritte.Einstellungen.SuccessMessage'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Schritte.Einstellungen.SuccessTitel'
              ) as string
            );
            return schrittActions.speichereSchritteEinstellungenSucceeded({
              einstellungen,
            });
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Grundeinstellungen.Schritte.Einstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Schritte.Einstellungen.FailedTitel'
              ) as string
            );
            return of(schrittActions.speichereSchritteEinstellungenFailed({ error }));
          })
        );
      })
    );
  });

  // FachlicheRollen
  upsertFachlicheRollenForWorkflow$ = createEffect(() => {
    return this.actions.pipe(
      ofType(schrittActions.FachlicheRollenActions.upsertFachlicheRollen),
      mergeMap(action => {
        return this.schrittService
          .upsertFachlicheRollenForWorkflow(action.fachlicheRollen, action.workflowId)
          .pipe(
            map(_ => {
              return schrittActions.FachlicheRollenActions.upsertFachlicheRollenSucceeded();
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showError(
                this.translate.instant(
                  'Texte.Workflow.Schritt.UpsertFachlicheRollen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Workflow.Schritt.UpsertFachlicheRollen.FailedMessage'
                ) as string
              );
              return of(
                schrittActions.FachlicheRollenActions.upsertFachlicheRollenFailed({ error })
              );
            })
          );
      })
    );
  });
}

import { AktiverSchrittModel } from '@dworkflow/shared/model/schritt-aktiv.model';
import { createReducer, on } from '@ngrx/store';
import lodash from 'lodash';
import { WorkflowInformationenFuerTabelleModel } from '../shared/model/workflow-informationen-fuer-tabelle.model';
import { WorkflowFuerTabelle } from '../workflow/workflow-tabelle/workflow-fuer-tabelle';
import * as commonActions from './common.actions';
import * as engineActions from './engine.actions';
import * as schrittActions from './schritt.actions';
import {
  WorkflowFuerTabelleState,
  initialWorkflowFuerTabelleState,
} from './workflow-tabelle.state';

export const workflowFuerTabelleReducer = createReducer(
  initialWorkflowFuerTabelleState,

  on(engineActions.loadWorkflowInformationenFuerTabelleSucceeded, (state, { workflows }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateWorkflowInformationenFuerTabelle(
        state.workflowsFuerTabelle,
        workflows
      ),
    };
  }),
  on(engineActions.loadMeineWorkflowsSucceeded, (state, { workflows }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateWorkflowInformationenFuerTabelle(
        state.workflowsFuerTabelle,
        workflows
      ),
    };
  }),
  on(engineActions.loadMeineWorkflowsFuerStartseiteSucceeded, (state, { workflows }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateWorkflowInformationenFuerTabelle(
        state.workflowsFuerTabelle,
        workflows
      ),
    };
  }),
  on(engineActions.loadAlleWorkflowsSucceeded, (state, { workflows }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateWorkflowInformationenFuerTabelle(
        state.workflowsFuerTabelle,
        workflows
      ),
    };
  }),
  on(engineActions.loadVertretungenWorkflowsSucceeded, (state, { workflows }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateWorkflowInformationenFuerTabelle(
        state.workflowsFuerTabelle,
        workflows
      ),
    };
  }),
  on(schrittActions.loadAktiveSchritteForWorkflowsSucceeded, (state, { aktiveSchritte }) => {
    return {
      ...state,
      workflowsFuerTabelle: updateSchritteInWorkflowFuerTabelle(
        state.workflowsFuerTabelle,
        aktiveSchritte.schritte
      ),
      aktiveSchritteTimestamp: aktiveSchritte.timestamp,
      aktiveSchritteLoaded: true,
    };
  }),
  // TenantChanged
  on(
    commonActions.tenantChanged,
    (_state): WorkflowFuerTabelleState => ({
      ...initialWorkflowFuerTabelleState,
    })
  )
);

function updateWorkflowInformationenFuerTabelle(
  state: WorkflowFuerTabelle[],
  workflowInfos: WorkflowInformationenFuerTabelleModel[]
): WorkflowFuerTabelle[] {
  let newstate = lodash.cloneDeep(state);
  for (const wf of workflowInfos) {
    const tabellenWf = newstate.find(w => w.workflowId === wf.workflowId);
    if (tabellenWf) {
      newstate = newstate.filter(w => w.workflowId != wf.workflowId);
      const newTabellenWf = lodash.cloneDeep(tabellenWf);
      newTabellenWf.workflow = wf;
      newstate.push(newTabellenWf);
    } else {
      newstate.push({ workflowId: wf.workflowId, workflow: wf } as WorkflowFuerTabelle);
    }
  }

  return newstate;
}

function updateSchritteInWorkflowFuerTabelle(
  state: WorkflowFuerTabelle[],
  schritte: AktiverSchrittModel[]
): WorkflowFuerTabelle[] {
  let newstate = lodash.cloneDeep(state);
  for (const schritt of schritte) {
    const tabellenWf = newstate.find(w => w.workflowId === schritt.workflowId);
    if (tabellenWf) {
      newstate = newstate.filter(w => w.workflowId != schritt.workflowId);
      const newTabellenWf = lodash.cloneDeep(tabellenWf);
      newTabellenWf.aktiverSchritt = schritt;
      newstate.push(newTabellenWf);
    } else {
      newstate.push({
        workflowId: schritt.workflowId,
        aktiverSchritt: {
          schrittId: schritt.schrittId,
          titel: schritt.titel,
          workflowId: schritt.workflowId,
          aufgabe: schritt.aufgabe,
        } as AktiverSchrittModel,
      } as WorkflowFuerTabelle);
    }
  }
  return newstate;
}

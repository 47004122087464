import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'dworkflow-formly-html',
  template: `<div [id]="id" [innerHTML]="props.html"></div>`,
  // Die width muss auf dem host (dworkflow-formly-html) hinzugefuegt werden, damit
  // Elemente, die von sich aus keine width haben nicht mit 0px dargestellt werden (bspw. hr)
  styles: `
    :host {
      width: 100%;
    }
  `,
  standalone: true,
})
export class HtmlComponent extends FieldType<FieldTypeConfig> {
  get type(): string {
    return this.props.type ?? 'html';
  }
}

import { HttpErrorResponse, HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DokumentBerechtigungModel, DokumentUploadStatus } from '@dworkflow/shared/model/dokumente';
import { DokumentMitTimestampModel } from '@dworkflow/shared/model/dokumente/dokument-mit-timestamp.model';
import { DokumenteEinstellungenModel } from '@dworkflow/shared/model/dokumente/dokumente-einstellungen.model';
import { DokumentenAenderungModel } from '@dworkflow/shared/model/dokumente/dokumenten-aenderung.model';
import { Formular } from '@dworkflow/shared/model/dokumente/formular.model';
import { FormulareMitFormularDatenModel } from '@dworkflow/shared/model/dokumente/formulare-mit-formulardaten.model';
import { TenantFormularBerechtigungenModel } from '@dworkflow/shared/model/dokumente/tenant-formular-berechtigungen.model';
import { VorlagenDokumentModel } from '@dworkflow/shared/model/dokumente/vorlagen-dokument.model';
import { WorkflowDokumentModel } from '@dworkflow/shared/model/dokumente/workflow-dokument.model';
import { DokumenteServiceInternal } from '@dworkflow/shared/services/dokumente.service.internal';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import * as commonActions from '@dworkflow/state/common.actions';
import * as fromSecurityStore from '@dworkflow/state/security.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatAll,
  concatMap,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import * as actions from './dokumente.actions';
import * as dokumentenActions from './dokumente.actions';
import * as fromState from './dokumente.state';
import { DokumentFile } from './dokumente.state';

@Injectable({
  providedIn: 'root',
})
export class DokumenteEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dokumenteServiceInternal: DokumenteServiceInternal,
    private toastService: ToastService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}

  // Einstellungen
  loadEinstellungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.loadEinstellungenUndArbeitsgruppen, actions.loadEinstellungen),
      mergeMap(_ =>
        this.dokumenteServiceInternal.getEinstellungen().pipe(
          switchMap((einstellungen: DokumenteEinstellungenModel) =>
            of(actions.loadEinstellungenSucceeded({ einstellungen }))
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadEinstellungenFailed({ error }));
          })
        )
      )
    );
  });

  updateEinstellungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateEinstellungen),
      mergeMap(({ einstellungen }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal.updateEinstellungen(einstellungen).pipe(
          switchMap((updatedEinstellungen: DokumenteEinstellungenModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.SuccessMessage'
              ) as string
            );
            return of(
              actions.updateEinstellungenSucceeded({ einstellungen: updatedEinstellungen })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpdateEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return of(actions.updateEinstellungenFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  // Dokumente Laden und Upload
  startUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.startUpload),
      concatMap(({ workflowId }) =>
        this.store.select(fromState.selectDokumenteWithFilesByWorkflowId(workflowId)).pipe(
          first(),
          concatAll(),
          concatMap(({ dokument, file }) => this.uploadDokument(dokument, file))
        )
      )
    );
  });

  addQueuedDokument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.addQueuedDokument),
      concatMap(action => {
        const dokumentenFile: DokumentFile = {
          dokumentenId: action.dokumentenId,
          file: action.file,
          progress: 0,
        };
        const dokument = <WorkflowDokumentModel>{
          dokumentenId: action.dokumentenId,
          workflowId: action.workflowId,
          name: action.fileNameOverride ?? action.file.name,
          aktenrelevant: false,
          status: DokumentUploadStatus.Queued,
          geaendertVonId: action.currentUserId,
          objectStorageKey: '',
          version: '',
          berechtigungen: {
            canBearbeiten: false,
            canEntfernen: false,
          } as DokumentBerechtigungModel,
          tenantId: action.tenantId,
        };
        return this.uploadDokument(dokument, dokumentenFile);
      })
    );
  });

  loadDokumenteForWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.loadWorkflow, actions.loadDokumente),
      switchMap(({ workflowId }) =>
        this.store.pipe(
          map(store => fromState.selectWorkflowTimestampById(workflowId)(store)),
          take(1),
          switchMap(timestampInTicks => {
            return this.dokumenteServiceInternal.getDokumente(workflowId, timestampInTicks).pipe(
              switchMap(dokumentenliste => {
                return [actions.loadDokumenteSucceeded({ dokumentenliste, workflowId })];
              }),
              catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse && error.status === 304) {
                  // NotModified: State muss nicht aktualisiert werden
                  return EMPTY;
                } else if (error instanceof HttpErrorResponse && error.status === 403) {
                  // Keine Fehlermeldung in der GUI anzeigen, da dies nach der Erstellung eines Workflows
                  // zeitweise auftreten kann. Da vom Dokumentenservice erst das Event "WorkflowErstellt"
                  // gehandelt werden muss. Ein Setzen der Berechtigungen wie in der SchrittApi geht nicht,
                  // da ein Workflow auch ohne Dokumente angelegt werden kann.

                  return of(actions.loadDokumenteFailed({ error }));
                } else {
                  this.toastService.showHttpErrorResponse(
                    this.translate.instant(
                      'Texte.Dokumente.Benachrichtigungen.DokumenteLaden.FailedTitel'
                    ) as string,
                    this.translate.instant(
                      'Texte.Dokumente.Benachrichtigungen.DokumenteLaden.FailedMessage'
                    ) as string,
                    error
                  );
                  return of(actions.loadDokumenteFailed({ error }));
                }
              })
            );
          })
        )
      )
    );
  });

  // Dokumente Einstellungen bearbeiten
  updateUploadedDokumentEigenschaften$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateUploadedDokumentEigenschaften),
      mergeMap(({ dokumentenId, workflowId, eigenschaften }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.WaitMessage',
            { dokumentName: eigenschaften.name }
          ) as string
        );
        return this.dokumenteServiceInternal
          .sendUpdateUploadedDokumentEigenschaftenRequest(eigenschaften, dokumentenId, workflowId)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.SuccessMessage',
                  { dokumentName: eigenschaften.name }
                ) as string
              );
              return of(
                actions.updateUploadedDokumentEigenschaftenSucceeded({
                  dokumentenId,
                  eigenschaften,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.FailedMessage',
                  { dokumentName: eigenschaften.name }
                ) as string,
                error
              );
              return of(actions.updateUploadedDokumentEigenschaftenFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Entsperren Dokument
  dokumentEntsperren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.dokumentEntsperren),
      mergeMap(({ dokumentId, workflowId, dokumentName }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.Entsperren.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Dokumente.Benachrichtigungen.Entsperren.WaitMessage', {
            dokumentName,
          }) as string
        );
        return this.dokumenteServiceInternal.dokumentEntsperren(dokumentId, workflowId).pipe(
          switchMap(dokument => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.Entsperren.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.Entsperren.SuccessMessage',
                { dokumentName }
              ) as string
            );
            return of(
              actions.dokumentEntsperrenSucceeded({
                workflowId,
                dokument,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.Entsperren.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.Entsperren.FailedMessage',
                {
                  dokumentName,
                }
              ) as string,
              error
            );
            return of(actions.dokumentEntsperrenFailed({ error, dokumentId }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  vorlagenDokumentEntsperren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.vorlagenDokumentEntsperren),
      mergeMap(({ dokumentId, vorlagenId, dokumentName }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.Entsperren.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Dokumente.Benachrichtigungen.Entsperren.WaitMessage', {
            dokumentName,
          }) as string
        );
        return this.dokumenteServiceInternal
          .vorlagenDokumentEntsperren(dokumentId, vorlagenId)
          .pipe(
            switchMap(dokument => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.Entsperren.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.Entsperren.SuccessMessage',
                  { dokumentName }
                ) as string
              );
              return of(
                actions.vorlagenDokumentEntsperrenSucceeded({
                  vorlagenId,
                  dokument,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.Entsperren.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.Entsperren.FailedMessage',
                  {
                    dokumentName,
                  }
                ) as string,
                error
              );
              return of(actions.vorlagenDokumentEntsperrenFailed({ error, dokumentId }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // DokumentenreihenfolgeAktualisieren
  aktualisiereDokumentenReihenfolge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.postDokumentReihenfolgeAktualisieren),
      mergeMap(({ workflowId, dokumenteReihenfolge }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal
          .postDokumentReihenfolgeAktualisieren(workflowId, dokumenteReihenfolge)
          .pipe(
            switchMap((dokumenteInNeuerReihenfolge: WorkflowDokumentModel[]) => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.SuccessMessage'
                ) as string
              );
              return of(
                actions.postDokumentReihenfolgeAktualisierenSucceeded({
                  workflowId,
                  dokumenteInNeuerReihenfolge,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.FailedMessage'
                ) as string,
                error
              );
              return of(actions.postDokumentReihenfolgeAktualisierenFailed({ error, workflowId }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  aktualisiereVorlagenDokumentenReihenfolge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.postVorlagenDokumentReihenfolgeAktualisieren),
      mergeMap(({ vorlagenId, dokumentenIdsToPositionMapping }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal
          .postVorlagenDokumentReihenfolgeAktualisieren(vorlagenId, dokumentenIdsToPositionMapping)
          .pipe(
            mergeMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.SuccessMessage'
                ) as string
              );
              return of(
                actions.postVorlagenDokumentReihenfolgeAktualisierenSucceeded({ vorlagenId })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentReihenfolge.FailedMessage'
                ) as string,
                error
              );
              return of(
                actions.postVorlagenDokumentReihenfolgeAktualisierenFailed({ error, vorlagenId })
              );
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Papierverfuegung
  erstellePapierverfuegung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.postPapierverfuegung),
      mergeMap(({ papierverfuegung, workflowId, aufgabenId }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal
          .postPapierverfuegung(papierverfuegung, workflowId)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.SuccessMessage'
                ) as string
              );
              return of(actions.postPapierverfuegungSucceeded({ workflowId, aufgabenId }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.PapierverfuegungErstellen.FailedMessage'
                ) as string,
                error
              );
              return of(actions.postPapierverfuegungFailed({ error, aufgabenId }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Dokument löschen
  loescheDokument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loescheDokument),
      mergeMap(({ workflowId, dokumentenId, dokumentenName, kommentar }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.WaitMessage',
            { dokumentenName: dokumentenName }
          ) as string
        );
        return this.dokumenteServiceInternal
          .loescheDokument(dokumentenId, workflowId, kommentar)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.SuccessMessage',
                  { dokumentenName: dokumentenName }
                ) as string
              );
              return of(actions.loescheDokumentSucceeded({ workflowId, dokumentenId }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.FailedMessage',
                  { dokumentenName: dokumentenName }
                ) as string,
                error
              );
              return of(actions.loescheDokumentFailed({ error, dokumentenId }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // mehrere Dokumente löschen
  loescheDokumente$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loescheDokumente),
      mergeMap(({ workflowId, dokumentenIds, kommentar, workflowTitel }) => {
        if (dokumentenIds.length > 0) {
          const waitToast = this.toastService.showAndGetWaitToast(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.WaitTitel'
            ) as string,
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.WaitMessage',
              { workflowTitel }
            ) as string
          );
          return this.dokumenteServiceInternal
            .loescheDokumente(dokumentenIds, workflowId, kommentar)
            .pipe(
              switchMap(() => {
                this.toastService.showSuccess(
                  this.translate.instant(
                    'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.SuccessTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.SuccessMessage',
                    { workflowTitel }
                  ) as string
                );
                return of(actions.loescheDokumenteSucceeded({ workflowId, dokumentenIds }));
              }),
              catchError((error: HttpErrorResponse) => {
                this.toastService.showHttpErrorResponse(
                  this.translate.instant(
                    'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.FailedTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.FailedMessage',
                    { workflowTitel }
                  ) as string,
                  error
                );
                return of(actions.loescheDokumenteFailed({ error, dokumentenIds }));
              }),
              finalize(() => {
                this.toastService.removeToast(waitToast);
              })
            );
        } else {
          this.toastService.showSuccess(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.SuccessTitel'
            ) as string,
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.DokumenteLoeschen.SuccessMessage',
              { workflowTitel }
            ) as string
          );
          return of(actions.loescheDokumenteSucceeded({ workflowId, dokumentenIds }));
        }
      })
    );
  });

  // Nicht hochgeladene Dokumente entfernen
  removeNotUploadedDokumente$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.removeNotUploadedDokument),

      // eslint-disable-next-line no-empty-pattern
      mergeMap(({ dokumentenName }) => {
        this.toastService.showSuccess(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.NichtHochgeladenesDokumentLoeschen.SuccessTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.NichtHochgeladenesDokumentLoeschen.SuccessMessage',
            {
              dokumentName: dokumentenName,
            }
          ) as string
        );

        return of(actions.removeNotUploadedDokumentSucceeded());
      })
    );
  });

  // Vorlagen Dokumente Upload
  startVorlagenDokumentUpload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.startVorlagenDokumentUpload),
      concatMap(({ vorlagenId }) =>
        this.store.select(fromState.selectVorlagenDokumenteWithFilesByVorlagenId(vorlagenId)).pipe(
          first(),
          concatAll(),
          filter(({ dokument, file }) => !!dokument && !!file),
          concatMap(({ dokument, file }) => this.uploadVorlagenDokument(dokument, file))
        )
      )
    );
  });

  // Vorlagen Dokument updaten
  updateMarkierteVorlagenDokumenteEigenschaften$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateMarkierteVorlagenDokumenteEigenschaften),
      concatMap(({ vorlagenId }) =>
        this.store.select(fromState.selectVorlagenDokumenteByVorlagenId(vorlagenId)).pipe(
          first(),
          concatAll(),
          filter(d => d.markiertFuerEigenschaftenUpdated),
          map(dokument =>
            actions.updateUploadedVorlagenDokumentEigenschaften({
              dokument: dokument,
            })
          )
        )
      )
    );
  });

  updateUploadedVorlagenDokumentEigenschaften$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.updateUploadedVorlagenDokumentEigenschaften),
      mergeMap(({ dokument }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.WaitMessage',
            { dokumentName: dokument.name }
          ) as string
        );
        return this.dokumenteServiceInternal
          .sendUpdateUploadedVorlagenDokumentEigenschaftenRequest(dokument.dokumentenId, {
            name: dokument.name,
          })
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.SuccessMessage',
                  { dokumentName: dokument.name }
                ) as string
              );
              return of(
                actions.updateUploadedVorlagenDokumentEigenschaftenSucceeded({
                  dokumentenId: dokument.dokumentenId,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Eigenschaften.Benachrichtigungen.Bearbeiten.FailedMessage',
                  { dokumentName: dokument.name }
                ) as string,
                error
              );
              return of(actions.updateUploadedVorlagenDokumentEigenschaftenFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Vorlagen Dokument löschen
  loescheMarkierteVorlagenDokumente$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loescheMarkierteVorlagenDokumente),
      concatMap(({ vorlagenId }) =>
        this.store.select(fromState.selectVorlagenDokumenteByVorlagenId(vorlagenId)).pipe(
          first(),
          concatAll(),
          filter(d => d.markiertFuerLoeschung),
          map(dokument =>
            actions.loescheVorlagenDokument({
              vorlagenId,
              dokumentenId: dokument.dokumentenId,
              dokumentenName: dokument.name,
            })
          )
        )
      )
    );
  });

  loescheVorlagenDokument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loescheVorlagenDokument),
      mergeMap(({ vorlagenId, dokumentenId, dokumentenName }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.WaitMessage',
            { dokumentenName: dokumentenName }
          ) as string
        );
        return this.dokumenteServiceInternal.loescheVorlagenDokument(dokumentenId, vorlagenId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.SuccessMessage',
                { dokumentenName: dokumentenName }
              ) as string
            );
            return of(actions.loescheVorlagenDokumentSucceeded({ vorlagenId, dokumentenId }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumentLoeschen.FailedMessage',
                { dokumentenName: dokumentenName }
              ) as string,
              error
            );
            return of(actions.loescheVorlagenDokumentFailed({ error, dokumentenId }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  loadDokumenteForVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadVorlagenDokumente),
      switchMap(({ vorlagenId }) =>
        this.dokumenteServiceInternal.getVorlagenDokumente(vorlagenId).pipe(
          switchMap(dokumentenliste => {
            return [
              actions.loadVorlagenDokumenteSucceeded({ dokumente: dokumentenliste, vorlagenId }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumenteLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Dokumente.Benachrichtigungen.DokumenteLaden.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadVorlagenDokumenteFailed({ error }));
          })
        )
      )
    );
  });

  // Utilities
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  uploadDokument(dokument: WorkflowDokumentModel, file: DokumentFile) {
    return this.dokumenteServiceInternal.uploadFile(dokument, file).pipe(
      filter(
        event =>
          event.type === HttpEventType.Sent ||
          event.type === HttpEventType.UploadProgress ||
          event.type === HttpEventType.Response
      ),
      withLatestFrom(this.store.select(fromSecurityStore.selectAccessibilityModusOn)),
      map(([event, accessibilityEnabled]) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return dokumentenActions.updateUploadProgress({
              dokumentenId: dokument.dokumentenId,
              progress: 0,
            });
          case HttpEventType.UploadProgress:
            return dokumentenActions.updateUploadProgress({
              dokumentenId: dokument.dokumentenId,
              progress: Math.round((event.loaded / event.total) * 100),
            });
          case HttpEventType.Response: {
            const dokumentModel = <DokumentMitTimestampModel>event.body;
            return dokumentenActions.uploadSuccessful({
              dokumentenId: dokument.dokumentenId,
              dokument: dokumentModel.dokument,
              timestamp: dokumentModel.workflowTimestampInTicks,
              removeFile: !accessibilityEnabled,
            });
          }
          default:
            // Sollte nicht passieren, wenn es passiert, dann ist der
            // filter() falsch und lässt andere Events durch.
            return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if (err?.status === HttpStatusCode.Conflict.valueOf()) {
          // Datei ist infiziert, breche Bearbeitung ab
          import(
            '@dworkflow/dokumente/dokumente-modal/dokumente-virus-modal/dokumente-virus-modal.component'
          ).then(c => {
            this.dialog.open(c.DokumenteVirusModalComponent, {
              ariaLabel: this.translate.instant(
                'Texte.Dokumente.DokumenteVirus.InfiziertModal.Message',
                { dokumentName: dokument.name }
              ) as string,
              data: {
                dokumentName: dokument.name,
              },
              disableClose: true,
              panelClass: 'modal-small-size',
            });
          });
        } else if (
          err?.status === HttpStatusCode.InternalServerError.valueOf() &&
          err?.statusText?.includes('Virenscan')
        ) {
          this.toastService.showError(
            this.translate.instant('Texte.Dokumente.DokumenteVirus.IcapFehler.Titel') as string,
            this.translate.instant('Texte.Dokumente.DokumenteVirus.IcapFehler.Message', {
              dokumentName: dokument.name,
            }) as string
          );
        }
        // Falls ein Fehler mit dem Dateinamen zurückgegeben wird, zeigen wir den als Warning-Toast an, statt als Error-Modal
        /* eslint-disable @typescript-eslint/no-unsafe-member-access */
        else if (
          err?.error?.errors &&
          Object.prototype.hasOwnProperty.call(err?.error?.errors, 'Name')
        ) {
          this.toastService.showWarning(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedTitel'
            ) as string,
            (this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedMessage',
              {
                dokumentName: dokument.name,
              }
            ) as string) +
              '<br />' +
              (err.error.errors['Name'][0] as string)
          );
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        } else {
          this.toastService.showHttpErrorResponse(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedTitel'
            ) as string,
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedMessage',
              {
                dokumentName: dokument.name,
              }
            ) as string,
            err
          );
        }
        return of(
          dokumentenActions.uploadFailed({
            dokument,
            file,
          })
        );
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
  uploadVorlagenDokument(dokument: VorlagenDokumentModel, file: DokumentFile) {
    return this.dokumenteServiceInternal.uploadVorlagenDokument(dokument, file).pipe(
      filter(
        event =>
          event.type === HttpEventType.Sent ||
          event.type === HttpEventType.UploadProgress ||
          event.type === HttpEventType.Response
      ),
      withLatestFrom(this.store.select(fromSecurityStore.selectAccessibilityModusOn)),
      map(([event, accessibilityEnabled]) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return dokumentenActions.updateVorlagenDokumentUploadProgress({
              dokumentenId: dokument.dokumentenId,
              progress: 0,
            });
          case HttpEventType.UploadProgress:
            return dokumentenActions.updateVorlagenDokumentUploadProgress({
              dokumentenId: dokument.dokumentenId,
              progress: Math.round((event.loaded / event.total) * 100),
            });
          case HttpEventType.Response: {
            const dokumentModel = <VorlagenDokumentModel>event.body;
            return dokumentenActions.uploadVorlagenDokumentSuccessful({
              dokument: dokumentModel,
              removeFile: !accessibilityEnabled,
            });
          }
          default:
            // Sollte nicht passieren, wenn es passiert, dann ist der
            // filter() falsch und lässt andere Events durch.
            return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        // Falls ein Fehler mit dem Dateinamen zurückgegeben wird, zeigen wir den als Warning-Toast an, statt als Error-Modal
        /* eslint-disable @typescript-eslint/no-unsafe-member-access */
        if (
          err?.error?.errors &&
          Object.prototype.hasOwnProperty.call(err?.error?.errors, 'Name')
        ) {
          this.toastService.showWarning(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedTitel'
            ) as string,
            (this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedMessage',
              {
                dokumentName: dokument.name,
              }
            ) as string) +
              '<br />' +
              (err.error.errors['Name'][0] as string)
          );
          /* eslint-enable @typescript-eslint/no-unsafe-member-access */
        } else {
          this.toastService.showHttpErrorResponse(
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedTitel'
            ) as string,
            this.translate.instant(
              'Texte.Dokumente.Benachrichtigungen.UploadFailed.FailedMessage',
              {
                dokumentName: dokument.name,
              }
            ) as string,
            err
          );
        }
        return of(
          dokumentenActions.uploadVorlagenDokumentFailed({
            dokument,
            file,
          })
        );
      })
    );
  }

  // Dokument Aenderungen
  loadDokumentAenderungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadDokumentAenderungen),
      mergeMap(({ dokumentenId, workflowId, dokumentName }) => {
        return this.dokumenteServiceInternal.getDokumentAenderungen(workflowId, dokumentenId).pipe(
          switchMap((dokumentAenderungen: DokumentenAenderungModel[]) => {
            return of(
              actions.loadDokumentAenderungenSucceeded({ dokumentenId, dokumentAenderungen })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant('Texte.Dokumente.DokumenteVersionen.FailedTitel') as string,
              this.translate.instant('Texte.Dokumente.DokumenteVersionen.FailedMessage', {
                dokumentName: dokumentName,
              }) as string,
              error
            );
            return of(actions.loadDokumentAenderungenFailed({ error }));
          })
        );
      })
    );
  });

  // DokumentenVersion Wiederherstellen
  dokumentenVersionWiederherstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.dokumentenVersionWiederherstellen),
      mergeMap(({ dokumentenId, version, workflowId, dokumentenName }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.WaitMessage',
            { dokumentenName: dokumentenName }
          ) as string
        );
        return this.dokumenteServiceInternal
          .dokumentenVersionWiederherstellen(workflowId, dokumentenId, version)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.SuccessMessage',
                  { dokumentenName: dokumentenName }
                ) as string
              );
              return of(actions.dokumentenVersionWiederherstellenSucceeded({ dokumentenId }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.FailedTitle'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Benachrichtigungen.DokumentWiederherstellen.FailedMessage'
                ) as string,
                error
              );
              return of(actions.dokumentenVersionWiederherstellenFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  // Formulare
  loadFormulare$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadFormulare, commonActions.loadVorlagenkonfigurationenUndFormulare),
      mergeMap(_ =>
        this.dokumenteServiceInternal.getFormulare().pipe(
          switchMap((formulare: Formular[]) => {
            return of(actions.loadFormulareSucceeded({ formulare }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadFormulareFailed({ error }));
          })
        )
      )
    );
  });

  loadFormular$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadFormular),
      mergeMap(({ id }) =>
        this.dokumenteServiceInternal.getFormularById(id).pipe(
          switchMap((formular: Formular) => {
            return of(actions.loadFormularSucceeded({ formular }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadFormularFailed({ error }));
          })
        )
      )
    );
  });

  upsertFormular$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.upsertFormular),
      mergeMap(({ formular }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.UpsertFormular.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.UpsertFormular.WaitMessage',
            { formularName: formular.titel }
          ) as string
        );
        return this.dokumenteServiceInternal.upsertFormular(formular).pipe(
          switchMap((formular: Formular) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.UpsertFormular.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.UpsertFormular.SuccessMessage',
                { formularName: formular.titel }
              ) as string
            );
            return of(actions.upsertFormularSucceeded({ formular }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpsertFormular.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.UpsertFormular.FailedMessage'
              ) as string,
              error
            );
            return of(actions.upsertFormularFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  requestLoescheFormular$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.requestLoescheFormular),
      mergeMap(({ formularId, formularName }) => {
        return this.dokumenteServiceInternal.istFormularInVerwendung(formularId).pipe(
          map(inVerwendung => {
            if (inVerwendung) {
              import(
                '@dworkflow/shared/components/genehmigungs-modal/genehmigungs-modal.component'
              ).then(c => {
                const modal = this.dialog.open(c.GenehmigungsModalComponent, {
                  panelClass: 'modal-small-size',
                  data: {
                    titelText: this.translate.instant(
                      'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.FormularInVerwendungFrage.Titel'
                    ) as string,
                    bodyText: this.translate.instant(
                      'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.FormularInVerwendungFrage.Text'
                    ) as string,
                    bestaetigenButtonText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Loeschen'
                    ) as string,
                    abbrechenButtonText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Abbrechen'
                    ) as string,
                    schliessenText: this.translate.instant(
                      'Texte.Allgemein.Buttons.Schliessen'
                    ) as string,
                    isLoeschenModal: true,
                  },
                  disableClose: true,
                  ariaLabel: this.translate.instant(
                    'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.FormularInVerwendungFrage.Titel'
                  ) as string,
                });
                modal.componentInstance.bestaetigungClicked.subscribe(_ => {
                  // Refactor in 2024
                  // eslint-disable-next-line @ngrx/no-dispatch-in-effects
                  this.store.dispatch(
                    actions.loescheFormular({
                      formularId,
                      formularName,
                    })
                  );
                });
                return undefined;
              });
            } else {
              return actions.loescheFormular({
                formularId,
                formularName,
              });
            }
          }),
          filter(action => !!action),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loescheFormularFailed({ error }));
          })
        );
      })
    );
  });

  loescheFormular$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loescheFormular),
      mergeMap(({ formularId, formularName }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.WaitMessage',
            { formularName }
          ) as string
        );
        return this.dokumenteServiceInternal.loescheFormular(formularId).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.SuccessTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoescheFormular.SuccessMessage',
                { formularName }
              ) as string
            );
            return of(actions.loescheFormularSucceeded({ formularId }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.LoescheFormular.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Dokumente.Benachrichtigungen.LoescheFormular.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loescheFormularFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(waitToast);
          })
        );
      })
    );
  });

  loadFormulareWithDatenForWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.loadWorkflow, actions.loadFormulareWithDatenForWorkflow),
      mergeMap(({ workflowId }) =>
        this.dokumenteServiceInternal.getFormulareWithDatenForWorkflow(workflowId).pipe(
          switchMap((formulareWithDatenForWorkflow: FormulareMitFormularDatenModel) => {
            return of(
              actions.loadFormulareWithDatenForWorkflowSucceeded({
                formulareWithDatenForWorkflow,
                workflowId,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadFormulareWithDatenForWorkflowFailed({ error }));
          })
        )
      )
    );
  });

  loadFormulareWithDatenForVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadFormulareWithDatenForVorlage),
      mergeMap(({ vorlagenId }) =>
        this.dokumenteServiceInternal.loadFormulareWithDatenForVorlage(vorlagenId).pipe(
          switchMap((formulareWithDatenForVorlage: FormulareMitFormularDatenModel) => {
            return of(
              actions.loadFormulareWithDatenForVorlageSucceeded({
                formulareWithDatenForVorlage,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadFormulareWithDatenForVorlageFailed({ error }));
          })
        )
      )
    );
  });

  loadFormulareByIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.loadFormulareByIds),
      mergeMap(({ formularIds }) =>
        this.dokumenteServiceInternal.getFormulareByIds(formularIds).pipe(
          switchMap((formulare: Formular[]) => {
            return of(actions.loadFormulareByIdsSucceeded({ formulare }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Formulare.Benachrichtigungen.LoadFormulare.FailedMessage'
              ) as string,
              error
            );
            return of(actions.loadFormulareByIdsFailed({ error }));
          })
        )
      )
    );
  });

  formularFuerSchrittErstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.formularFuerSchrittErstellen),
      mergeMap(({ workflowId, schrittAbschlussModel }) =>
        this.dokumenteServiceInternal
          .formularschrittAbschliessen(workflowId, schrittAbschlussModel)
          .pipe(
            switchMap(formularDaten => {
              return of(
                actions.formularFuerSchrittErstellenSucceeded({ workflowId, formularDaten })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularFuerSchrittErstellen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularFuerSchrittErstellen.FailedMessage'
                ) as string,
                error
              );
              return of(actions.formularFuerSchrittErstellenFailed({ error }));
            })
          )
      )
    );
  });

  formularDatenSpeichernFuerWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.formularDatenSpeichernFuerWorkflow),
      mergeMap(({ workflowId, daten }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal
          .formularDatenSpeichernFuerWorkflow(workflowId, daten)
          .pipe(
            switchMap(daten => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.SuccessMessage'
                ) as string
              );
              return of(actions.formularDatenSpeichernFuerWorkflowSucceeded({ workflowId, daten }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerWorkflow.FailedMessage'
                ) as string,
                error
              );
              return of(actions.formularDatenSpeichernFuerWorkflowFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  formularDatenSpeichernFuerVorlage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.formularDatenSpeichernFuerVorlage),
      mergeMap(({ vorlagenId, daten }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.WaitMessage'
          ) as string
        );
        return this.dokumenteServiceInternal
          .formularDatenSpeichernFuerVorlage(vorlagenId, daten)
          .pipe(
            switchMap(daten => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.SuccessMessage'
                ) as string
              );
              return of(actions.formularDatenSpeichernFuerVorlageSucceeded({ vorlagenId, daten }));
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.FailedMessage'
                ) as string,
                error
              );
              return of(actions.formularDatenSpeichernFuerVorlageFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  erstelleWorkflowFormularAlsDokument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.erstelleWorkflowFormularAlsDokument),
      mergeMap(({ workflowId, formularId, dokumentenName, fileType }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.WaitTitel',
            { fileType: fileType.toLocaleUpperCase() }
          ) as string,
          this.translate.instant(
            'Texte.Dokumente.Formulare.Benachrichtigungen.FormularDatenSpeichernFuerVorlage.WaitMessage',
            { fileType: fileType.toLocaleUpperCase() }
          ) as string
        );
        return this.dokumenteServiceInternal
          .erstelleWorkflowFormularAlsDokument(workflowId, formularId, fileType, dokumentenName)
          .pipe(
            switchMap(dokument => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Konvertierung.Benachrichtigungen.SuccessTitel',
                  { fileType: fileType.toLocaleUpperCase() }
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Konvertierung.Benachrichtigungen.SuccessMessage',
                  { fileType: fileType.toLocaleUpperCase() }
                ) as string
              );
              return of(
                actions.erstelleWorkflowFormularAlsDokumentSucceeded({
                  dokument,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Konvertierung.Benachrichtigungen.FailedTitel',
                  { fileType: fileType.toLocaleUpperCase() }
                ) as string,
                this.translate.instant(
                  'Texte.Dokumente.Formulare.Konvertierung.Benachrichtigungen.FailedMessage',
                  { fileType: fileType.toLocaleUpperCase() }
                ) as string,
                error
              );
              return of(actions.erstelleWorkflowFormularAlsDokumentFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  loadTenantFormularBerechtigungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dokumentenActions.formulareBerechtigungenActions.load),
      mergeMap(_ =>
        this.dokumenteServiceInternal.getTenantFormulareBerechtigungen().pipe(
          switchMap((berechtigungen: TenantFormularBerechtigungenModel[]) =>
            of(
              dokumentenActions.formulareBerechtigungenActions.loadSucceeded({
                tenantFormularBerechtigungen: berechtigungen,
              })
            )
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return EMPTY;
          })
        )
      )
    );
  });

  updateTenantFormularBerechtigungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dokumentenActions.formulareBerechtigungenActions.update),
      mergeMap(({ tenantFormularBerechtigung }) =>
        this.dokumenteServiceInternal
          .updateTenantFormulareBerechtigungen([tenantFormularBerechtigung])
          .pipe(
            switchMap((berechtigungen: TenantFormularBerechtigungenModel[]) =>
              of(
                dokumentenActions.formulareBerechtigungenActions.updateSucceeded({
                  tenantFormularBerechtigungen: berechtigungen,
                })
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
                ) as string,
                error
              );
              return EMPTY;
            })
          )
      )
    );
  });

  formularWiederherstellen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dokumentenActions.formularActions.wiederherstellen),
      mergeMap(({ formular, version }) => {
        const waitToast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.WaitMessage',
            { formularName: formular.titel }
          ) as string
        );
        return this.dokumenteServiceInternal
          .formularWiederherstellen(formular.formularId, version)
          .pipe(
            switchMap((formular: Formular) => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.SuccessTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.SuccessMessage',
                  { formularName: formular.titel }
                ) as string
              );

              return of(
                dokumentenActions.formularActions.wiederherstellenSucceeded({
                  formular: formular,
                })
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.Formulare.Versionen.Wiederherstellen.FailedMessage',
                  { version: version }
                ) as string,
                error
              );
              return EMPTY;
            }),
            finalize(() => {
              this.toastService.removeToast(waitToast);
            })
          );
      })
    );
  });

  clearTenantFormularBerechtigung$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.removeAdministratorAndClearFormularBerechtigungen),
      mergeMap(({ administrator }) =>
        this.dokumenteServiceInternal
          .updateTenantFormulareBerechtigungen([
            {
              isExpertenModusErlaubt: false,
              isFormularbearbeitungErlaubt: false,
              principalId: administrator.id,
            },
          ])
          .pipe(
            switchMap((berechtigungen: TenantFormularBerechtigungenModel[]) =>
              of(
                dokumentenActions.formulareBerechtigungenActions.updateSucceeded({
                  tenantFormularBerechtigungen: berechtigungen,
                })
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
                ) as string,
                error
              );
              return EMPTY;
            })
          )
      )
    );
  });
}

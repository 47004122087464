import { DokumentModel } from '@dworkflow/shared/model/dokumente';
import { DokumentBerechtigungModel } from '@dworkflow/shared/model/dokumente/dokument-berechtigung.model';
import { DokumentUploadStatus } from '@dworkflow/shared/model/dokumente/dokument-upload-status.enum';
import { DokumentenAenderungListeModel } from '@dworkflow/shared/model/dokumente/dokumenten-aenderung-liste.model';
import { PapierverfuegungAufgabe } from '@dworkflow/shared/model/dokumente/papierverfuegung-aufgabe.model';
import { PapierverfuegungStatus } from '@dworkflow/shared/model/dokumente/papierverfuegung-status.enum';
import { VorlagenDokumentModel } from '@dworkflow/shared/model/dokumente/vorlagen-dokument.model';
import { WorkflowBerechtigungenModel } from '@dworkflow/shared/model/dokumente/workflow-berechtigungen.model';
import { WorkflowDokumentModel } from '@dworkflow/shared/model/dokumente/workflow-dokument.model';
import { guid } from '@dworkflow/shared/utility/guid';
import * as commonActions from '@dworkflow/state/common.actions';
import { Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import lodash from 'lodash';
import { DokumenteHelper } from '../shared/utility/dokumente-helper';
import * as DokumentenActions from './dokumente.actions';
import {
  DokumentFile,
  DokumenteState,
  dokumentAenderungenAdapter,
  dokumentenAdapter,
  fileAdapter,
  formularAdapter,
  formularBerechtigungenAdapter,
  formularDatenAdapter,
  initialState,
  papierverfuegungAufgabeAdapter,
  tenantFormularBerechtigungenAdapter,
  vorlagenDokumentenAdapter,
  workflowBerechtigungenAdapter,
  workflowTimestampAdapter,
} from './dokumente.state';

export const reducer = createReducer(
  initialState,

  // Entferne Dokument und File vom State
  on(
    DokumentenActions.removeNotUploadedDokument,
    (state, { dokumentenId, vorlagenId, workflowId }) => {
      const dok = dokumentenAdapter
        .getSelectors()
        .selectAll(state.dokumente)
        .find(f => f.dokumentenId === dokumentenId && f.workflowId === workflowId);
      const vorlagenDok = vorlagenDokumentenAdapter
        .getSelectors()
        .selectAll(state.vorlagenDokumente)
        .find(f => f.dokumentenId === dokumentenId && f.vorlagenId === vorlagenId);
      return {
        ...state,
        dokumente:
          dok?.status === DokumentUploadStatus.Queued ||
          dok?.status === DokumentUploadStatus.Pending
            ? dokumentenAdapter.removeOne(dokumentenId, state.dokumente)
            : state.dokumente,
        vorlagenDokumente:
          vorlagenDok?.status === DokumentUploadStatus.Queued ||
          vorlagenDok?.status === DokumentUploadStatus.Pending
            ? vorlagenDokumentenAdapter.removeOne(dokumentenId, state.vorlagenDokumente)
            : state.vorlagenDokumente,
        files: fileAdapter.removeOne(dokumentenId, state.files),
      };
    }
  ),

  on(DokumentenActions.removeUploadedDokument, (state, { dokumentenId }) => {
    return {
      ...state,
      files: fileAdapter.removeOne(dokumentenId, state.files),
    };
  }),
  on(DokumentenActions.removePendingDokumente, state => {
    const ids = dokumentenAdapter
      .getSelectors()
      .selectAll(state.dokumente)
      .filter(f => f.status === DokumentUploadStatus.Pending)
      .map(d => d.dokumentenId);
    const vorlagenDokIds = vorlagenDokumentenAdapter
      .getSelectors()
      .selectAll(state.vorlagenDokumente)
      .filter(f => f.status === DokumentUploadStatus.Pending)
      .map(d => d.dokumentenId);
    return {
      ...state,
      dokumente: dokumentenAdapter.removeMany(ids, state.dokumente),
      vorlagenDokumente: vorlagenDokumentenAdapter.removeMany(
        vorlagenDokIds,
        state.vorlagenDokumente
      ),
      files: fileAdapter.removeMany(ids.concat(vorlagenDokIds), state.files),
    };
  }),

  on(
    DokumentenActions.postDokumentReihenfolgeAktualisierenSucceeded,
    (state, { dokumenteInNeuerReihenfolge }) => {
      return {
        ...state,
        dokumente: dokumentenAdapter.upsertMany(dokumenteInNeuerReihenfolge, state.dokumente),
      };
    }
  ),

  on(DokumentenActions.loadEinstellungenSucceeded, (state, { einstellungen }): DokumenteState => {
    return { ...state, einstellungen };
  }),

  on(DokumentenActions.updateEinstellungenSucceeded, (state, { einstellungen }): DokumenteState => {
    return { ...state, einstellungen };
  }),

  // Füge Dokument und File zum State als pending hinzu
  on(DokumentenActions.addPendingDokumente, (state, { vorlagenDokumente }) => {
    // TODO: file.type ist der Mime-Type. Wollen wir das evtl auch mitspeichern?

    return upsertDokumenteToState(
      state,
      vorlagenDokumente.map(x => {
        return {
          workflowId: x.workflowId,
          fileNameOverride: x.fileNameOverride,
          currentUserId: x.currentUserId,
          dokumentenId: x.dokumentenId,
          dokumentenUploadStatus: DokumentUploadStatus.Pending,
          file: x.file,
          objectStorageKey: x.dokumentenKey,
          tenantId: x.tenantId,
          vorlagenDokumentId: x.vorlagenDokumentId,
          position: x.position,
        };
      })
    );
  }),
  // Füge Dokument und File zum State als queued hinzu
  on(
    DokumentenActions.addQueuedDokument,
    (state, { workflowId, dokumentenId, file, currentUserId, fileNameOverride, tenantId }) => {
      // TODO: file.type ist der Mime-Type. Wollen wir das evtl auch mitspeichern?

      return upsertDokumentToState(
        state,
        workflowId,
        fileNameOverride,
        file,
        currentUserId,
        dokumentenId,
        DokumentUploadStatus.Queued,
        tenantId,
        null
      );
    }
  ),

  on(DokumentenActions.loadDokumenteSucceeded, (state, { dokumentenliste, workflowId }) => {
    const isDokumentUploaded = (d: WorkflowDokumentModel): boolean => {
      return (
        d.status !== DokumentUploadStatus.Pending &&
        d.status !== DokumentUploadStatus.Queued &&
        d.status !== DokumentUploadStatus.UploadInProgress
      );
    };

    const dokumenteForWorkflowToRemove = dokumentenAdapter
      .getSelectors()
      .selectAll(state.dokumente)
      .filter(d => {
        return (
          d.workflowId === workflowId &&
          // pruefen, ob sich die zu loeschenden Dokumente nicht im Status Pending, Queued oder Upload befinden
          isDokumentUploaded(d) &&
          !dokumentenliste.dokumente.some(s => d.dokumentenId === s.dokumentenId)
        );
      })
      .map(d => d.dokumentenId);

    let dokumente = dokumentenAdapter.removeMany(dokumenteForWorkflowToRemove, state.dokumente);
    dokumente = dokumentenAdapter.upsertMany(dokumentenliste.dokumente, dokumente);

    return {
      ...state,
      dokumente,
      workflowTimestamps: workflowTimestampAdapter.upsertOne(
        {
          workflowId,
          timestampInTicks: dokumentenliste.workflowTimestampInTicks,
        },
        state.workflowTimestamps
      ),
      workflowBerechtigungen: workflowBerechtigungenAdapter.upsertOne(
        { workflowId, canHinzufuegen: dokumentenliste.berechtigungen?.canHinzufuegen },
        state.workflowBerechtigungen
      ),
    };
  }),

  on(DokumentenActions.loadVorlagenDokumenteSucceeded, (state, { dokumente, vorlagenId }) => {
    const isDokumentUploaded = (d: VorlagenDokumentModel): boolean => {
      return (
        d.status !== DokumentUploadStatus.Pending &&
        d.status !== DokumentUploadStatus.Queued &&
        d.status !== DokumentUploadStatus.UploadInProgress
      );
    };

    const dokumenteForVorlageToRemove = vorlagenDokumentenAdapter
      .getSelectors()
      .selectAll(state.vorlagenDokumente)
      .filter(d => {
        return (
          d.vorlagenId === vorlagenId &&
          // pruefen, ob sich die zu loeschenden Dokumente nicht im Status Pending, Queued oder Upload befinden
          isDokumentUploaded(d) &&
          !dokumente.some(s => d.dokumentenId === s.dokumentenId)
        );
      })
      .map(d => d.dokumentenId);

    let vorlagenDokumente = vorlagenDokumentenAdapter.removeMany(
      dokumenteForVorlageToRemove,
      state.vorlagenDokumente
    );
    vorlagenDokumente = vorlagenDokumentenAdapter.upsertMany(dokumente, vorlagenDokumente);

    return {
      ...state,
      vorlagenDokumente,
    };
  }),

  on(
    DokumentenActions.updatePendingDokumentEigenschaften,
    (state, { dokumentenId, changes, workflowId, vorlagenId }) => {
      return {
        ...state,
        dokumente:
          workflowId && !vorlagenId
            ? dokumentenAdapter.updateOne(
                {
                  id: dokumentenId,
                  changes: {
                    ...changes,
                  },
                },
                state.dokumente
              )
            : state.dokumente,
        vorlagenDokumente: vorlagenId
          ? vorlagenDokumentenAdapter.updateOne(
              {
                id: dokumentenId,
                changes: {
                  ...changes,
                },
              },
              state.vorlagenDokumente
            )
          : state.vorlagenDokumente,
      };
    }
  ),

  // Update File Upload progress
  on(DokumentenActions.updateUploadProgress, (state, { dokumentenId, progress }) => {
    return {
      ...state,
      dokumente: dokumentenAdapter.updateOne(
        {
          id: dokumentenId,
          changes: {
            status: DokumentUploadStatus.UploadInProgress,
          },
        },
        state.dokumente
      ),
      files: fileAdapter.updateOne(
        {
          id: dokumentenId,
          changes: {
            progress,
          },
        },
        state.files
      ),
    };
  }),
  on(
    DokumentenActions.updateVorlagenDokumentUploadProgress,
    (state, { dokumentenId, progress }) => {
      return {
        ...state,
        vorlagenDokumente: vorlagenDokumentenAdapter.updateOne(
          {
            id: dokumentenId,
            changes: {
              status: DokumentUploadStatus.UploadInProgress,
            },
          },
          state.vorlagenDokumente
        ),
        files: fileAdapter.updateOne(
          {
            id: dokumentenId,
            changes: {
              progress,
            },
          },
          state.files
        ),
      };
    }
  ),

  // Entferne File und update Dokument bei fertigem Upload
  on(
    DokumentenActions.uploadSuccessful,
    (state, { dokument, timestamp, removeFile, dokumentenId }) => {
      return {
        ...state,
        dokumente: dokumentenAdapter.updateOne(
          {
            id: dokumentenId,
            changes: {
              ...dokument,
              vorlagenDokumentId: null,
            },
          },
          state.dokumente
        ),
        files: removeFile ? fileAdapter.removeOne(dokumentenId, state.files) : state.files,
        workflowTimestamps: workflowTimestampAdapter.upsertOne(
          { workflowId: dokument.workflowId, timestampInTicks: timestamp },
          state.workflowTimestamps
        ),
      };
    }
  ),

  on(DokumentenActions.uploadFailed, (state, { dokument, file }) => {
    return {
      ...state,
      dokumente: dokumentenAdapter.removeOne(dokument.dokumentenId, state.dokumente),
      files: fileAdapter.removeOne(file.dokumentenId, state.files),
    };
  }),

  on(DokumentenActions.startUpload, (state, { workflowId }) => {
    const updates = Object.values(state.dokumente.entities)
      .filter(x => x.workflowId === workflowId)
      .map(({ dokumentenId }) => ({
        id: dokumentenId,
        changes: {
          status: DokumentUploadStatus.Queued,
          berechtigung: {
            canEntfernen: false,
            canBearbeiten: false,
            canAktenrelevanzBearbeiten: false,
          } as DokumentBerechtigungModel,
        },
      }));

    return { ...state, dokumente: dokumentenAdapter.updateMany(updates, state.dokumente) };
  }),

  on(DokumentenActions.startVorlagenDokumentUpload, (state, { vorlagenId }) => {
    const updates = Object.values(state.vorlagenDokumente.entities)
      .filter(x => x.vorlagenId === vorlagenId && x.status === DokumentUploadStatus.Pending)
      .map(({ dokumentenId }) => ({
        id: dokumentenId,
        changes: {
          status: DokumentUploadStatus.Queued,
        },
      }));

    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.updateMany(updates, state.vorlagenDokumente),
    };
  }),
  // Entferne File und update Dokument bei fertigem Upload
  on(DokumentenActions.uploadVorlagenDokumentSuccessful, (state, { dokument, removeFile }) => {
    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.updateOne(
        {
          id: dokument.dokumentenId,
          changes: {
            ...dokument,
            markiertFuerEigenschaftenUpdated: false,
            markiertFuerLoeschung: false,
          },
        },
        state.vorlagenDokumente
      ),
      files: removeFile ? fileAdapter.removeOne(dokument.dokumentenId, state.files) : state.files,
    };
  }),

  on(DokumentenActions.uploadVorlagenDokumentFailed, (state, { dokument, file }) => {
    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.removeOne(
        dokument.dokumentenId,
        state.vorlagenDokumente
      ),
      files: fileAdapter.removeOne(file.dokumentenId, state.files),
    };
  }),

  on(DokumentenActions.initialisiereWorkflowBerechtigungen, (state, { workflowId }) => {
    const berechtigung = {
      workflowId,
      canHinzufuegen: true,
    } as WorkflowBerechtigungenModel;
    return {
      ...state,
      workflowBerechtigungen: workflowBerechtigungenAdapter.upsertOne(
        berechtigung,
        state.workflowBerechtigungen
      ),
    };
  }),

  // Dokumenten-Eigenschaften bei geuploadetem Dokumente anpassen
  on(
    DokumentenActions.updateUploadedDokumentEigenschaftenSucceeded,
    (state, { dokumentenId, eigenschaften }) => {
      const newName = state.dokumente.entities[dokumentenId].name;
      const wirdUmbenannt = newName && newName !== eigenschaften.name;
      // Der Name wird hier nicht direkt aktualisiert, weil die Umbenennung erst async im backend passieren muss
      const changes: {
        aktenrelevant: boolean;
        name?: string;
      } = {
        aktenrelevant: eigenschaften.aktenrelevant,
      };
      if (wirdUmbenannt) {
        changes.name = eigenschaften.name;
      }
      return {
        ...state,
        dokumente: dokumentenAdapter.updateOne(
          {
            id: dokumentenId,
            changes: {
              ...changes,
            },
          },
          state.dokumente
        ),
      };
    }
  ),

  // Papierverfügung
  on(DokumentenActions.postPapierverfuegung, (state, { aufgabenId }) => {
    const verfuegung: PapierverfuegungAufgabe = {
      aufgabenId,
      status: PapierverfuegungStatus.Pending,
    };
    return {
      ...state,
      verfuegungsstatus: papierverfuegungAufgabeAdapter.upsertOne(
        verfuegung,
        state.verfuegungsstatus
      ),
    };
  }),

  on(DokumentenActions.postPapierverfuegungSucceeded, (state, { aufgabenId }) => {
    const verfuegung: PapierverfuegungAufgabe = {
      aufgabenId,
      status: PapierverfuegungStatus.Succeeded,
    };
    return {
      ...state,
      verfuegungsstatus: papierverfuegungAufgabeAdapter.upsertOne(
        verfuegung,
        state.verfuegungsstatus
      ),
    };
  }),

  on(DokumentenActions.postPapierverfuegungFailed, (state, { aufgabenId }) => {
    const verfuegung: PapierverfuegungAufgabe = {
      aufgabenId,
      status: PapierverfuegungStatus.Failed,
    };
    return {
      ...state,
      verfuegungsstatus: papierverfuegungAufgabeAdapter.upsertOne(
        verfuegung,
        state.verfuegungsstatus
      ),
    };
  }),

  on(DokumentenActions.loescheDokumentSucceeded, (state, { dokumentenId }) => {
    return { ...state, dokumente: dokumentenAdapter.removeOne(dokumentenId, state.dokumente) };
  }),

  on(DokumentenActions.loescheDokumenteSucceeded, (state, { dokumentenIds }) => {
    return { ...state, dokumente: dokumentenAdapter.removeMany(dokumentenIds, state.dokumente) };
  }),

  on(
    DokumentenActions.markiereVorlagenDokumenteZurLoeschung,
    (state, { dokumentenIds, loeschen }) => {
      const changes: Update<VorlagenDokumentModel>[] = dokumentenIds.map(id =>
        Object.assign(
          {},
          {
            id,
            changes: {
              markiertFuerLoeschung: loeschen,
              markiertFuerEigenschaftenUpdated: false,
            },
          }
        )
      );

      return {
        ...state,
        vorlagenDokumente: vorlagenDokumentenAdapter.updateMany(changes, state.vorlagenDokumente),
      };
    }
  ),

  on(DokumentenActions.restoreChangesVorlagenDokumente, (state, { dokumentenIds }) => {
    const changes: Update<VorlagenDokumentModel>[] = dokumentenIds.map(id =>
      Object.assign(
        {},
        {
          id,
          changes: {
            markiertFuerLoeschung: false,
            markiertFuerEigenschaftenUpdated: false,
          },
        }
      )
    );

    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.updateMany(changes, state.vorlagenDokumente),
    };
  }),

  on(
    DokumentenActions.markiereVorlagenDokumentEigenschaftenUpdated,
    (state, { dokumentenId, updated, changes }) => {
      const oldName = state.vorlagenDokumente.entities[dokumentenId].name;
      const wirdUmbenannt = oldName && changes?.name && oldName !== changes?.name;
      // Der Name wird hier nicht direkt aktualisiert, weil die Umbenennung erst async im backend passieren muss
      const changesWithUpdate: Partial<VorlagenDokumentModel> = {
        markiertFuerEigenschaftenUpdated: updated,
      };

      if (wirdUmbenannt) {
        changesWithUpdate.name = changes?.name;
      }
      return {
        ...state,
        vorlagenDokumente: vorlagenDokumentenAdapter.updateOne(
          { id: dokumentenId, changes: changesWithUpdate },
          state.vorlagenDokumente
        ),
      };
    }
  ),

  on(
    DokumentenActions.updateUploadedVorlagenDokumentEigenschaftenSucceeded,
    (state, { dokumentenId }) => {
      const changes: Partial<VorlagenDokumentModel> = {
        markiertFuerEigenschaftenUpdated: false,
      };
      return {
        ...state,
        vorlagenDokumente: vorlagenDokumentenAdapter.updateOne(
          { id: dokumentenId, changes },
          state.vorlagenDokumente
        ),
      };
    }
  ),

  on(DokumentenActions.loescheVorlagenDokumentSucceeded, (state, { dokumentenId }) => {
    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.removeOne(dokumentenId, state.vorlagenDokumente),
    };
  }),

  // Füge Dokument und File zum State als pending hinzu
  on(
    DokumentenActions.addPendingVorlagenDokument,
    (state, { vorlagenId, dokumentenId, file, currentUserId, fileNameOverride }) => {
      return upsertVorlagenDokumentToState(
        state,
        vorlagenId,
        fileNameOverride,
        file,
        currentUserId,
        dokumentenId,
        DokumentUploadStatus.Pending,
        Object.values(state.vorlagenDokumente.entities).filter(d => d.vorlagenId == vorlagenId)
          .length + 1
      );
    }
  ),
  on(
    DokumentenActions.aktualisiereDokumenteReihenfolgeInState,
    (state, { dokumentenIdToPositionMapping }) => {
      const changedDokumente = Object.keys(dokumentenIdToPositionMapping).map(id => {
        return { id, changes: { neuePosition: dokumentenIdToPositionMapping[id as guid] } };
      });

      return {
        ...state,
        dokumente: dokumentenAdapter.updateMany(changedDokumente, state.dokumente),
      };
    }
  ),
  on(
    DokumentenActions.aktualisiereVorlagenDokumenteReihenfolgeInState,
    (state, { dokumentenIdToPositionMapping }) => {
      const changedDokumente = Object.keys(dokumentenIdToPositionMapping).map(id => {
        return { id, changes: { neuePosition: dokumentenIdToPositionMapping[id as guid] } };
      });

      return {
        ...state,
        vorlagenDokumente: vorlagenDokumentenAdapter.updateMany(
          changedDokumente,
          state.vorlagenDokumente
        ),
      };
    }
  ),

  // DokumentAenderungen
  on(
    DokumentenActions.loadDokumentAenderungenSucceeded,
    (state, { dokumentenId, dokumentAenderungen }) => {
      const dokumentAenderungenListe: DokumentenAenderungListeModel = {
        dokumentenId,
        dokumentAenderungen,
      };

      return {
        ...state,
        dokumentAenderungen: dokumentAenderungenAdapter.upsertOne(
          dokumentAenderungenListe,
          state.dokumentAenderungen
        ),
      };
    }
  ),

  // DokumentenVersionWiederherstellen
  on(
    DokumentenActions.dokumentenVersionWiederherstellenSucceeded,
    (state, { dokumentenId: dokumentenId }) => {
      const change = { status: DokumentUploadStatus.Processing };
      return {
        ...state,
        dokumente: dokumentenAdapter.updateOne(
          { id: dokumentenId, changes: change },
          state.dokumente
        ),
      };
    }
  ),

  // Formulare
  on(DokumentenActions.loadFormulareSucceeded, (state, { formulare }) => {
    return {
      ...state,
      formulare: formularAdapter.setAll(formulare, state.formulare),
      formulareLoaded: true,
    };
  }),
  on(DokumentenActions.loadFormulareByIdsSucceeded, (state, { formulare }) => {
    return {
      ...state,
      formulare: formularAdapter.upsertMany(formulare, state.formulare),
      formulareLoaded: true,
    };
  }),
  on(DokumentenActions.loadFormularSucceeded, (state, { formular }) => {
    return {
      ...state,
      formulare: formularAdapter.upsertOne(formular, state.formulare),
      formulareLoaded: true,
    };
  }),
  on(DokumentenActions.upsertFormularSucceeded, (state, { formular }) => {
    return {
      ...state,
      formulare: formularAdapter.upsertOne(formular, state.formulare),
    };
  }),
  on(DokumentenActions.loescheFormularSucceeded, (state, { formularId }) => {
    return {
      ...state,
      formulare: formularAdapter.removeMany(
        formular => formular.formularId === formularId,
        state.formulare
      ),
    };
  }),
  on(
    DokumentenActions.loadFormulareWithDatenForWorkflowSucceeded,
    (state, { formulareWithDatenForWorkflow, workflowId }) => {
      return {
        ...state,
        formulare: formularAdapter.upsertMany(
          formulareWithDatenForWorkflow.formulare,
          state.formulare
        ),
        formularDaten: formularDatenAdapter.upsertMany(
          formulareWithDatenForWorkflow.formularDaten,
          state.formularDaten
        ),
        formulareLoadedForWorkflows: lodash.uniq(
          state.formulareLoadedForWorkflows.concat([workflowId])
        ),
        formularBerechtigungen: formularBerechtigungenAdapter.upsertOne(
          {
            canWorkflowFormulareBearbeiten:
              formulareWithDatenForWorkflow.berechtigungen.canWorkflowFormulareBearbeiten,
            workflowId,
          },
          state.formularBerechtigungen
        ),
      };
    }
  ),
  on(
    DokumentenActions.loadFormulareWithDatenForVorlageSucceeded,
    (state, { formulareWithDatenForVorlage: formulareForVorlage }) => {
      return {
        ...state,
        formulare: formularAdapter.upsertMany(formulareForVorlage.formulare, state.formulare),
        formularDaten: formularDatenAdapter.upsertMany(
          formulareForVorlage.formularDaten,
          state.formularDaten
        ),
      };
    }
  ),

  on(DokumentenActions.formularDatenSpeichernFuerWorkflowSucceeded, (state, { daten }) => {
    return {
      ...state,
      formularDaten: formularDatenAdapter.upsertOne(daten, state.formularDaten),
    };
  }),
  on(DokumentenActions.formularDatenSpeichernFuerVorlageSucceeded, (state, { daten }) => {
    return {
      ...state,
      formularDaten: formularDatenAdapter.upsertOne(daten, state.formularDaten),
    };
  }),
  on(DokumentenActions.formularFuerSchrittErstellenSucceeded, (state, { formularDaten }) => {
    return {
      ...state,
      formularDaten: formularDatenAdapter.upsertOne(formularDaten, state.formularDaten),
    };
  }),
  on(DokumentenActions.erstelleWorkflowFormularAlsDokumentSucceeded, (state, { dokument }) => {
    return {
      ...state,
      dokumente: dokumentenAdapter.upsertOne(dokument, state.dokumente),
    };
  }),
  on(DokumentenActions.dokumentEntsperrenSucceeded, (state, { dokument }) => {
    return {
      ...state,
      dokumente: dokumentenAdapter.upsertOne(dokument, state.dokumente),
    };
  }),
  on(DokumentenActions.vorlagenDokumentEntsperrenSucceeded, (state, { dokument }) => {
    return {
      ...state,
      vorlagenDokumente: vorlagenDokumentenAdapter.upsertOne(dokument, state.vorlagenDokumente),
    };
  }),
  on(
    DokumentenActions.formulareBerechtigungenActions.loadSucceeded,
    DokumentenActions.formulareBerechtigungenActions.updateSucceeded,
    (state, { tenantFormularBerechtigungen }) => {
      return {
        ...state,
        tenantFormularBerechtigungen: tenantFormularBerechtigungenAdapter.setAll(
          tenantFormularBerechtigungen,
          state.tenantFormularBerechtigungen
        ),
      };
    }
  ),
  on(DokumentenActions.formularActions.wiederherstellenSucceeded, (state, { formular }) => {
    return {
      ...state,
      formulare: formularAdapter.upsertOne(formular, state.formulare),
    };
  }),

  // TenantChanged
  on(
    commonActions.tenantChanged,
    (_state): DokumenteState => ({
      ...initialState,
    })
  )
);

function upsertDokumentToState(
  state: DokumenteState,
  workflowId: guid,
  fileNameOverride: string,
  file: File,
  currentUserId: number,
  dokumentenId: guid,
  dokumentenUploadStatus: DokumentUploadStatus,
  tenantId: number,
  vorlagenDokumentId: guid,
  objectStorageKey: string = undefined
): DokumenteState {
  const dokument = getOrCreateDokumentFromState(
    state,
    workflowId,
    fileNameOverride || file.name,
    currentUserId,
    dokumentenId,
    dokumentenUploadStatus,
    vorlagenDokumentId
  );
  dokument.tenantId = tenantId;
  dokument.objectStorageKey = objectStorageKey;
  const dokumentenFile: DokumentFile = {
    dokumentenId: dokument.dokumentenId,
    file,
    progress: 0,
  };

  return {
    ...state,
    dokumente: dokumentenAdapter.upsertOne(dokument, state.dokumente),
    files: fileAdapter.upsertOne(dokumentenFile, state.files),
  };
}

function upsertDokumenteToState(
  state: DokumenteState,
  dokumente: {
    workflowId: guid;
    fileNameOverride: string;
    file: File;
    currentUserId: number;
    dokumentenId: guid;
    dokumentenUploadStatus: DokumentUploadStatus;
    tenantId: number;
    vorlagenDokumentId: guid;
    objectStorageKey: string;
  }[]
): DokumenteState {
  const stateDokumenteAndFiles: { dokument: WorkflowDokumentModel; file: DokumentFile }[] =
    dokumente.map(x => {
      const doc = getOrCreateDokumentFromState(
        state,
        x.workflowId,
        x.fileNameOverride || x.file.name,
        x.currentUserId,
        x.dokumentenId,
        x.dokumentenUploadStatus,
        x.vorlagenDokumentId
      );
      doc.tenantId = x.tenantId;
      doc.objectStorageKey = x.objectStorageKey;

      const f: DokumentFile = {
        dokumentenId: doc.dokumentenId,
        file: x.file,
        progress: 0,
      };

      return { dokument: doc, file: f };
    });

  return {
    ...state,
    dokumente: dokumentenAdapter.upsertMany(
      stateDokumenteAndFiles.map(x => x.dokument),
      state.dokumente
    ),
    files: fileAdapter.upsertMany(
      stateDokumenteAndFiles.map(x => x.file),
      state.files
    ),
  };
}

function upsertVorlagenDokumentToState(
  state: DokumenteState,
  vorlagenId: guid,
  fileNameOverride: string,
  file: File,
  currentUserId: number,
  dokumentenId: guid,
  dokumentenUploadStatus: DokumentUploadStatus,
  position: number
): DokumenteState {
  const dokument = getOrCreateVorlagenDokumentFromState(
    state,
    vorlagenId,
    fileNameOverride || file.name,
    currentUserId,
    dokumentenId,
    dokumentenUploadStatus,
    position
  );
  const dokumentenFile: DokumentFile = {
    dokumentenId: dokument.dokumentenId,
    file,
    progress: 0,
  };

  return {
    ...state,
    vorlagenDokumente: vorlagenDokumentenAdapter.upsertOne(dokument, state.vorlagenDokumente),
    files: fileAdapter.upsertOne(dokumentenFile, state.files),
  };
}

function getOrCreateDokumentFromState(
  state: DokumenteState,
  workflowId: guid,
  fileName: string,
  currentUserId: number,
  dokumentenId: guid,
  dokumentenUploadStatus: DokumentUploadStatus,
  vorlagenDokumentId: guid
): WorkflowDokumentModel {
  // Falls der selbe Name schon vergeben ist, soll das Dokument überschrieben werden
  const overwrittenDokument = dokumentenAdapter
    .getSelectors()
    .selectAll(state.dokumente)
    .find(f => f.dokumentenId === dokumentenId);

  const dokument: WorkflowDokumentModel = overwrittenDokument
    ? {
        ...overwrittenDokument,
        geaendertVonId: currentUserId,
        status: DokumentUploadStatus.Pending,
        vorlagenDokumentId: vorlagenDokumentId,
      }
    : <WorkflowDokumentModel>{
        vorlagenDokumentId: vorlagenDokumentId,
        dokumentenId: dokumentenId,
        workflowId,
        name: fileName,
        aktenrelevant: false,
        status: dokumentenUploadStatus,
        geaendertVonId: currentUserId,
        objectStorageKey: '',
        version: '',
        position: 0,
        berechtigungen: {
          canBearbeiten: dokumentenUploadStatus === DokumentUploadStatus.Pending,
          canEntfernen: !DokumenteHelper.isUploadOrRenameInProgress({
            status: dokumentenUploadStatus,
          } as DokumentModel),
        } as DokumentBerechtigungModel,
      };
  return dokument;
}

function getOrCreateVorlagenDokumentFromState(
  state: DokumenteState,
  vorlagenId: guid,
  fileName: string,
  currentUserId: number,
  dokumentenId: guid,
  dokumentenUploadStatus: DokumentUploadStatus,
  position: number
): VorlagenDokumentModel {
  // Falls der selbe Name schon vergeben ist, soll das Dokument überschrieben werden
  const overwrittenDokument = vorlagenDokumentenAdapter
    .getSelectors()
    .selectAll(state.vorlagenDokumente)
    .find(f => f.dokumentenId === dokumentenId);

  const dokument: VorlagenDokumentModel = overwrittenDokument
    ? {
        ...overwrittenDokument,
        geaendertVonId: currentUserId,
        status: dokumentenUploadStatus,
        markiertFuerLoeschung: false,
      }
    : <VorlagenDokumentModel>{
        dokumentenId,
        vorlagenId: vorlagenId,
        name: fileName,
        aktenrelevant: false,
        status: dokumentenUploadStatus,
        markiertFuerLoeschung: false,
        geaendertVonId: currentUserId,
        objectStorageKey: '',
        version: '',
        position: position,
      };
  return dokument;
}

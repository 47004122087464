<footer id="navigation_footer" class="footer container-fluid">
  <ul class="footer-items">
    <li class="footer-item">
      <button
        mat-button
        [attr.aria-label]="
          translationsLoaded
            ? ('Texte.Navigation.NavigiereZu'
              | translate : { seitentitel: 'Texte.Navigation.Hilfe' | translate })
            : 'Navigiere zur Seite \'Hilfe\''
        "
        class="mat-link"
        (click)="redirectTo('help')"
        data-testid="footer-hilfe-button"
        routerLinkActive="active-link"
        type="button"
        >
        <mat-icon fontSet="fa" fontIcon="fa-question"></mat-icon>&nbsp;
        {{ translationsLoaded ? ('Texte.Navigation.Hilfe' | translate) : 'Hilfe' }}
      </button>
    </li>
    @if (showSupportInformationenButton) {
      <li class="footer-item">
        <button
          mat-button
          aria-label="Supportinformationen anzeigen"
          (click)="openSupportInfoModal()"
          data-testid="footer-supportinformationen-button"
          type="button"
          >
          <mat-icon fontSet="fa" fontIcon="fa-headset"></mat-icon>&nbsp;
          {{
          translationsLoaded
          ? ('Texte.Navigation.Supportinformationen' | translate)
          : 'Supportinformationen'
          }}
        </button>
      </li>
    }
    <li class="footer-item">
      <button
        mat-button
        [attr.aria-label]="
          translationsLoaded
            ? ('Texte.Navigation.NavigiereZu'
              | translate : { seitentitel: 'Texte.Navigation.Datenschutz' | translate })
            : 'Navigiere zur Seite \'Datenschutz\''
        "
        class="mat-link"
        (click)="redirectTo('datenschutz')"
        data-testid="footer-datenschutz-button"
        routerLinkActive="active-link"
        type="button"
        >
        <mat-icon fontSet="fa" fontIcon="fa-scroll"></mat-icon>&nbsp;
        {{ translationsLoaded ? ('Texte.Navigation.Datenschutz' | translate) : 'Datenschutz' }}
      </button>
    </li>

    <li class="footer-item">
      <button
        mat-button
        [attr.aria-label]="
          translationsLoaded
            ? ('Texte.Navigation.NavigiereZu'
              | translate : { seitentitel: 'Texte.Navigation.Barrierefreiheit' | translate })
            : 'Navigiere zur Seite \'Barrierefreiheit\''
        "
        class="mat-link"
        (click)="redirectTo('barrierefreiheit')"
        data-testid="footer-barrierefreiheit-button"
        routerLinkActive="active-link"
        type="button"
        >
        <mat-icon
          fontSet="fa"
          fontIcon="fa-universal-access"
          class="barrierefreiheits-icon"
        ></mat-icon>
        &nbsp;
        {{
        translationsLoaded
        ? ('Texte.Navigation.Barrierefreiheit' | translate)
        : 'Barrierefreiheit'
        }}
      </button>
    </li>

    <li class="footer-item">
      <button
        mat-button
        [attr.aria-label]="
          translationsLoaded
            ? ('Texte.Navigation.NavigiereZu'
              | translate : { seitentitel: 'Texte.Navigation.Impressum' | translate })
            : 'Navigiere zur Seite \'Impressum\''
        "
        class="mat-link"
        (click)="redirectTo('impressum')"
        data-testid="footer-impressum-button"
        routerLinkActive="active-link"
        type="button"
        >
        <span class="paragraph-icon">§</span>&nbsp;
        {{ translationsLoaded ? ('Texte.Navigation.Impressum' | translate) : 'Impressum' }}
      </button>
    </li>
  </ul>
</footer>

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { DokumenteService } from '@dworkflow/shared/services/dokumente.service';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { Observable, combineLatest, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// Guard fuer Routen, die nur von FachAdmins oder SysAdmins geoeffnet werden duerfen
export class CanActivateFormularBearbeitungGuard {
  constructor(
    private securityService: SecurityService,
    private tenantService: TenantService,
    private dokumenteService: DokumenteService
  ) {}

  formularbearbeitungDurchFachadminsErlaubt$: Observable<boolean> =
    this.dokumenteService.getFormularbearbeitungDurchFachadminsErlaubt();
  currentUser$ = this.securityService.getCurrentUser();

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Zugriff auf SystemAdmins einschraenken durch Setzen von 'restrictedToSystemAdminsOnly'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    return combineLatest([this.formularbearbeitungDurchFachadminsErlaubt$, this.currentUser$]).pipe(
      filter(
        ([formularbearbeitungErlaubt, currentUser]) =>
          formularbearbeitungErlaubt != undefined && currentUser != undefined
      ),
      map(([formularbearbeitungErlaubt, currentUser]) => {
        if (
          !currentUser.isSystemAdmin &&
          (!currentUser.isWorkflowAdministrator || !formularbearbeitungErlaubt)
        ) {
          this.tenantService.redirectForbidden();
          return false;
        } else {
          return true;
        }
      })
    );
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(CanActivateFormularBearbeitungGuard).canActivate(next, state);
};

import { Injectable } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import lodash from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslateCompiler extends TranslateCompiler {
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-unused-vars
  compile(value: string, lang: string): string | Function {
    return value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  compileTranslations(translations: any, lang: string): any {
    const translationString = JSON.stringify(translations);
    lodash.templateSettings.interpolate = /##([\s\S]+?)##/g;
    const compiled = lodash.template(translationString);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const compiledTranslations = compiled(translations);
    return JSON.parse(compiledTranslations);
  }
}

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  private stackMustNotContainStrings = [
    // document-preview.component:
    // viewer wird geschlossen, während Daten nachgeladen werden.
    // vergl. https://github.com/stephanrauh/ngx-extended-pdf-viewer/issues/221
    'Worker was terminated',
    // Monaco Editor filtern
    'assets/lib/monaco-editor',
  ];

  // need Injector to prevcent circular dependency errors
  constructor(
    private injector: Injector,
    private location: LocationStrategy,
    private translate: TranslateService
  ) {}

  handleError(errorObject: Error | HttpErrorResponse): void {
    console.error(errorObject);

    let errorStr: string, url: string, stackString: string;
    // why doesnt this work?
    // if (error instanceof Response) {
    if ((<HttpErrorResponse>errorObject).url) {
      const errorResponse = errorObject as HttpErrorResponse;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      errorStr = errorResponse.error ? errorResponse.error.toString() : errorResponse.message;
      url = errorResponse.url;
    } else {
      const error = errorObject as Error;

      // --> if we choose to ignore certain keywords in our errorhandling
      if (this.containsIgnoreKeyword(error)) {
        return;
      }

      // if a await throws an exception
      /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      if ((<any>error).rejection && (<any>error).rejection.status === 400) {
        const promiseError = error as any;
        errorStr = promiseError.rejection.error;
        url = promiseError.rejection.url;
      } else {
        errorStr = error.message ? error.message : error.toString();
        url = this.location instanceof PathLocationStrategy ? this.location.path() : '';
        stackString = error.stack;
      }
      /* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    }

    const toastService = this.injector.get(ToastService);
    toastService.showError(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.translate.translations?.config?.Texte
        ? (this.translate.instant('Texte.Utilities.UnbekannterFehler.Titel') as string)
        : 'Unbekannt',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.translate.translations?.config?.Texte
        ? (this.translate.instant('Texte.Utilities.UnbekannterFehler.Nachricht') as string)
        : 'Sollte dieser Fehler öfter auftreten, versuchen Sie ein Neuladen der Seite und wenden Sie sich im Zweifelsfall mit den Details an den UHD.',
      errorStr,
      url,
      stackString
    );
  }

  private containsIgnoreKeyword(error: Error): boolean {
    if (error.stack) {
      const containsString = this.stackMustNotContainStrings.some(mustNotContain => {
        return error.stack.indexOf(mustNotContain) !== -1;
      });

      return containsString;
    }
  }
}

import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { DokumentenUploadProgressNotificationComponent } from '@dworkflow/dokumente/dokumente-upload-progress-notification-area/dokumente-upload-progress-notification-area.component';
import { HilfeComponent } from '@dworkflow/footer/hilfe/hilfe.component';
import { ImpressumComponent } from '@dworkflow/footer/impressum/impressum.component';
import { LoadingComponent } from '@dworkflow/shared/components/loading/loading.component';
import { AuthenticationService } from '@dworkflow/shared/services/authentication.service';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { TitelService } from '@dworkflow/shared/services/titel.service';
import { AriaDescribedByMutator } from '@dworkflow/shared/utility/dworkflow-aria-described-by-mutator';
import * as commonActions from '@dworkflow/state/common.actions';
import * as fromSecurityActions from '@dworkflow/state/security.actions';
import * as securityActions from '@dworkflow/state/security.actions';
import * as fromSecurityStore from '@dworkflow/state/security.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription, combineLatest } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { BannerComponent } from './banner/banner.component';
import { BarrierefreiheitserklaerungComponent } from './footer/barrierefreiheitserklaerung/barrierefreiheitserklaerung.component';
import { DatenschutzerklaerungComponent } from './footer/datenschutzerklaerung/datenschutzerklaerung.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { WorkflowService } from './shared/services/workflow.service';
import * as fromGrundeinstellungenActions from './state/grundeinstellungen.actions';

@Component({
  selector: 'dworkflow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    LoadingComponent,
    BannerComponent,
    NavigationComponent,
    RouterOutlet,
    FooterComponent,
    DokumentenUploadProgressNotificationComponent,
    NgTemplateOutlet,
    DatenschutzerklaerungComponent,
    ImpressumComponent,
    HilfeComponent,
    BarrierefreiheitserklaerungComponent,
    AsyncPipe
],
})
export class AppComponent implements OnInit, OnDestroy {
  public configurationLoaded = false;
  subscriptions: Subscription[] = [];
  skipLinkPath: string;
  loginText: string;
  loggedIn = false;
  accessibilityEnabled$: Observable<boolean> = this.store.select(
    fromSecurityStore.selectAccessibilityModusOn
  );

  texteLoaded$: Observable<boolean> = this.securityService.getTexteLoaded();
  route = 'login';
  currentRoute = '/';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private securityService: SecurityService,
    private sanitizer: DomSanitizer,
    private ariaDescribedByMutator: AriaDescribedByMutator,
    private matIconRegistry: MatIconRegistry,
    public tenantService: TenantService,
    private workflowService: WorkflowService,
    private titelService: TitelService,
    private store: Store,
    private authenticationService: AuthenticationService
  ) {
    // Weird bug: router.events wird als Observable<Event_2> erkannt und erzeugt Fehler daher hier der manuelle Cast auf das erwartete Objekt
    router.events
      .pipe(filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((event: RouterEvent) => {
        this.route =
          authenticationService.anonymousRoutes.filter(route => event.url === '/' + route)[0] ?? '';
        this.currentRoute = event.url;
      });

    if (!environment.production) {
      // do dev stuff here
    }

    // svg-Icons zu Material Icons hinzufügen
    // erst mal diekt per String Übergabe, da bei Übergabe der Url ein Cors Fehler auftritt
    this.matIconRegistry.addSvgIconLiteral(
      `dWorkflow_Sechseck`,
      // eslint-disable-next-line @microsoft/sdl/no-angular-bypass-sanitizer
      this.sanitizer.bypassSecurityTrustHtml(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.79 128.74"><defs></defs><g><path d="M129,43.67l11.94,20.65-33.73,58.52-67.55,0L5.84,64.42,39.58,5.89l67.55,0,11.94,20.65,20.83-.58Z"/></g></svg>'
      )
    );

    // "zum Hauptinhalt wechseln" auf dWorkflow Hauptinhalt um Accessibility "2.4.1 Bypass Blocks" zu erfüllen
    this.skipLinkPath = `${this.router.url}#main-content`;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (!this.router.url.endsWith('#main-content')) {
        this.skipLinkPath = `${this.router.url}#main-content`;
      }
    });
    this.securityService.checkLogin();
    // Wenn der Benutzer eingelogged ist, den TenantService initialisieren und die Texte aus der Engine laden
    this.subscriptions.push(
      this.securityService.isLoggedIn$
        .pipe(
          first(loggedIn => loggedIn),
          map(loggedIn => {
            this.loggedIn = loggedIn;
            this.subscriptions.push(this.tenantService.initialize().subscribe());
            this.subscriptions.push(
              this.workflowService
                .getCombinedTexte()
                .pipe(
                  map(t => JSON.parse(t) as Record<string, unknown>),
                  catchError(error => {
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                    const errorMsg = `Konfigurierbare Texte konnten nicht geladen werden! \n${error.message}`;
                    console.error(errorMsg);
                    console.error(error);
                    alert(errorMsg);
                    return EMPTY;
                  }),
                  map(t => {
                    this.translate.setTranslation('config', t, true);
                    this.securityService.setTexteLoaded();
                    if (isDevMode()) {
                      console.log(`Successfully initialized 'config' language.`);
                    }
                    this.store.dispatch(securityActions.loadEinstellungen());
                    this.router.events
                      .pipe(filter(event => event instanceof NavigationEnd))
                      .subscribe((_: unknown) => {
                        this.titelService.updateTitleFromRoute();
                      });
                  }),
                  catchError(error => {
                    console.error(`Problem with 'config' language initialization.`, error);
                    return EMPTY;
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      combineLatest([
        this.securityService.isLoggedIn$,
        this.tenantService.currentTenant$,
      ]).subscribe(([loggedIn, tenant]) => {
        if (tenant) {
          const favIcon = document.querySelector('#favIcon');
          const parent = favIcon.parentNode;
          const setFavicon = document.createElement('link');

          // remove old favicon
          parent.removeChild(favIcon);

          // create new favicon
          setFavicon.id = 'favIcon';
          setFavicon.setAttribute('rel', 'shortcut icon');
          setFavicon.setAttribute('href', tenant.logo ?? 'assets/images/dworkflow_sechseck.svg');
          parent.appendChild(setFavicon);

          if (loggedIn) {
            this.workflowService.getCombinedTexte().subscribe(t => {
              const parsedTexte = JSON.parse(t) as Record<string, unknown>;
              this.translate.setTranslation('config', parsedTexte, true);
              this.titelService.updateTitleFromRoute();
              this.store.dispatch(
                fromGrundeinstellungenActions.loadCombinedTexteWithoutCacheSucceeded({ texte: t })
              );
              this.securityService.setTexteLoaded();
            });
            this.securityService.loadCurrentUser();

            if (tenant.id !== 0) {
              this.store.dispatch(commonActions.loadEinstellungenUndArbeitsgruppen());
            }
          }
        }
      })
    );
  }
  ngOnInit(): void {
    this.authenticationService.storeIdentityProviderInLocalStorage();
    this.loginText = this.getLoginText();
  }

  toggleZugriffsfreundlichenModus(enabled: boolean): void {
    this.store.dispatch(
      fromSecurityActions.saveAccessibilityModusOn({
        accessibilityModusOn: enabled,
      })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }

    this.ariaDescribedByMutator.destroy();
  }

  getLoginText(): string {
    const texte = [
      'Verbindung zum Server wird aufgebaut. Bitte haben Sie einen Augenblick Geduld...',
      'Verbindung zum Server wird aufgebaut. Dies sollte nicht lange dauern...',
    ];
    return texte[Math.floor(Math.random() * texte.length)];
  }
}

import { createReducer, on } from '@ngrx/store';
import { initialAufgabenFuerTabelleState } from './aufgabe-tabelle.state';
import * as schrittActions from './schritt.actions';

export const aufgabenFuerTabelleReducer = createReducer(
  initialAufgabenFuerTabelleState,

  on(schrittActions.loadMeineAufgabenSucceeded, (state, { aufgabenListe }) => {
    return {
      ...state,
      aufgabenFuerTabelleModels: aufgabenListe.aufgaben,
    };
  }),
  on(schrittActions.loadVertretungenAufgabenSucceeded, (state, { aufgabenListe }) => {
    return {
      ...state,
      aufgabenFuerTabelleModels: aufgabenListe.aufgaben,
    };
  })
);

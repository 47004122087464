import * as commonActions from '@dworkflow/state/common.actions';
import { createReducer, on } from '@ngrx/store';
import lodash from 'lodash';
import { WorkflowInformationenFuerTabelleModel } from '../shared/model/workflow-informationen-fuer-tabelle.model';
import { LoadingStatus } from '../shared/model/workflow-loading-status.enum';
import { WorkflowModel } from '../shared/model/workflow.model';
import { WorkflowStatus } from '../shared/model/workflow.status.enum';
import { WorkflowverlaufModel } from '../shared/model/workflowverlauf.model';
import * as engineActions from './engine.actions';
import {
  EngineState,
  initialEngineState,
  workflowAdapter,
  workflowProtokollAdapter,
  workflowinformationenAdapter,
  workflowverlaeufeAdapter,
} from './engine.state';

export const engineReducer = createReducer(
  initialEngineState,
  on(engineActions.createWorkflowSucceeded, (state, { workflow }) => ({
    ...upsertWorkflowIntoState(workflow, state, LoadingStatus.Ok, null),
    meineWorkflows: [...state.meineWorkflows, workflow.workflowId],
    alleWorkflowsAnzahl: state.alleWorkflowsAnzahl + 1,
  })),
  on(engineActions.createWorkflowFailed, (state): EngineState => state), // TODO Server Error behandeln?
  on(engineActions.loadWorkflowSucceeded, (state, { workflow }) => ({
    ...upsertWorkflowIntoState(workflow, state, LoadingStatus.Ok, null),
  })),
  on(
    engineActions.loadWorkflowFailed,
    (state, { workflowId, loadingStatus, loadingErrorDetails }) => ({
      ...state,
      workflows: workflowAdapter.upsertOne(
        {
          workflowId: workflowId,
          timeStamp: null,
          timeStampTicks: null,
          loaded: false,
          loadingStatus,
          loadingErrorDetails,
          erstelltInReleaseVersion: null,
        },
        state.workflows
      ),
    })
  ),
  on(engineActions.loadMeineWorkflowsSucceeded, (state, { workflows }) => {
    return {
      ...state,
      meineWorkflows: workflows.map(m => m.workflowId),
      loadedMeineWorkflows: true,
      meineWorkflowsTimeStamp: getMaxTimeStamp(workflows),
      loadingStatus: LoadingStatus.Ok,
      loadingErrorDetails: null,
    };
  }),
  on(engineActions.loadMeineWorkflowsFuerStartseiteSucceeded, (state, { workflows }) => {
    return {
      ...state,
      meineWorkflowsFuerStartseite: workflows.map(m => m.workflowId),
      loadedMeineWorkflowsFuerStartseite: true,
      meineWorkflowsFuerStartseiteTimeStamp: getMaxTimeStamp(workflows),
      loadingStatus: LoadingStatus.Ok,
      loadingErrorDetails: null,
    };
  }),
  on(engineActions.loadAlleWorkflowsSucceeded, (state, { workflows }) => {
    const alleWorkflowsTimeStamp = getMaxTimeStamp(workflows);
    return {
      ...state,
      alleWorkflows: workflows.map(m => m.workflowId),
      loadedAlleWorkflows: true,
      alleWorkflowsTimeStamp,
    };
  }),
  on(engineActions.loadAlleWorkflowsAnzahlSucceeded, (state, { workflowAnzahl }): EngineState => {
    return {
      ...state,
      alleWorkflowsAnzahl: workflowAnzahl,
    };
  }),
  on(engineActions.loadVertretungenWorkflowsSucceeded, (state, { principalId, workflows }) => ({
    // Aktualisiere Workflow Entities und Mapping von Vertretungen-Workflows
    ...state,
    vertretungenWorkflowIds: {
      ...state.vertretungenWorkflowIds,
      [principalId]: workflows.map(x => x.workflowId),
    },
    vertretungenWorkflowsLoaded: {
      ...state.vertretungenWorkflowsLoaded,
      [principalId]: true,
    },
  })),
  on(engineActions.starteWorkflowverlauf, (state, { workflowverlaufId }) => ({
    ...state,
    workflowverlaeufe: workflowverlaeufeAdapter.updateOne(
      { id: workflowverlaufId, changes: { status: WorkflowStatus.WirdGestartet } },
      state.workflowverlaeufe
    ),
  })),
  on(engineActions.starteWorkflowverlaufFailed, (state, { workflowverlaufId }) => {
    return {
      ...state,
      workflowverlaeufe: workflowverlaeufeAdapter.updateOne(
        { id: workflowverlaufId, changes: { status: WorkflowStatus.Erstellt } },
        state.workflowverlaeufe
      ),
    };
  }),
  on(engineActions.editWorkflowverlaufSucceeded, (state, { workflowverlauf }) => {
    return upsertWorkflowverlaufIntoState(workflowverlauf, state);
  }),
  on(engineActions.storniereWorkflowSucceed, (state, { workflowId }) => {
    const verlaeufeUpdates = Object.values(state.workflowverlaeufe.entities)
      .filter(v => v.workflowId === workflowId)
      .map(v => ({
        id: v.workflowverlaufId,
        changes: { status: WorkflowStatus.Storniert },
      }));
    return {
      ...state,
      workflowverlaeufe: workflowverlaeufeAdapter.updateMany(
        verlaeufeUpdates,
        state.workflowverlaeufe
      ),
    };
  }),
  on(engineActions.loescheWorkflowSucceed, (state, { workflowId }) => {
    return {
      ...state,
      workflowverlaeufe: workflowverlaeufeAdapter.removeMany(
        verlauf => verlauf.workflowId === workflowId,
        state.workflowverlaeufe
      ),
      workflows: workflowAdapter.removeOne(workflowId, state.workflows),
      workflowinformationen: workflowinformationenAdapter.removeOne(
        workflowId,
        state.workflowinformationen
      ),
      alleWorkflows: state.alleWorkflows.filter(id => id !== workflowId),
      meineWorkflows: state.meineWorkflows.filter(id => id !== workflowId),
      alleWorkflowsAnzahl: state.alleWorkflowsAnzahl - 1,
      loadedMeineWorkflowsFuerStartseite: false,
      meineWorkflowsFuerStartseite: [],
      meineWorkflowsTimeStamp: undefined,
    };
  }),
  on(engineActions.loescheHaftnotizSucceeded, (state, { workflowId }) => {
    const infos = lodash.cloneDeep(state.workflowinformationen.entities[workflowId]);
    infos.haftnotiz = null;
    infos.haftnotizBearbeiterId = null;
    infos.haftnotizZuletztGeandert = null;
    return {
      ...state,
      workflowinformationen: workflowinformationenAdapter.upsertOne(
        infos,
        state.workflowinformationen
      ),
    };
  }),
  on(engineActions.speichereHaftnotizSucceeded, (state, { haftnotiz }) => {
    const workflowInfo = lodash.clone(state.workflowinformationen.entities[haftnotiz.workflowId]);
    workflowInfo.haftnotiz = haftnotiz.haftnotiz;
    workflowInfo.haftnotizBearbeiterId = haftnotiz.haftnotizBearbeiterId;
    workflowInfo.haftnotizZuletztGeandert = haftnotiz.haftnotizZuletztGeandert;
    return {
      ...state,
      workflowinformationen: workflowinformationenAdapter.upsertOne(
        workflowInfo,
        state.workflowinformationen
      ),
    };
  }),
  on(engineActions.speicherWorkflowInformationenSucceeded, (state, { workflow }) => ({
    ...upsertWorkflowIntoState(workflow, state, LoadingStatus.Ok, null),
  })),
  on(
    engineActions.loadEinstellungenSucceeded,
    (state, { einstellungen }): EngineState => ({
      ...state,
      einstellungen,
    })
  ),
  on(
    engineActions.updateEinstellungenSucceeded,
    (state, { einstellungen }): EngineState => ({
      ...state,
      einstellungen,
    })
  ),
  on(
    engineActions.loadBenachrichtigungenSucceeded,
    (state, { benachrichtigungen }): EngineState => ({
      ...state,
      benachrichtigungen: benachrichtigungen,
    })
  ),
  on(engineActions.updateBenachrichtigungSucceeded, (state, { model }) => {
    const clone = lodash.cloneDeep(state.benachrichtigungen);
    clone.benachrichtigungen = clone.benachrichtigungen.filter(i => i.typ !== model.typ);
    clone.benachrichtigungen.push(model);
    clone.benachrichtigungen.sort((a, b) => a.typ - b.typ);
    return {
      ...state,
      benachrichtigungen: clone,
    };
  }),
  on(engineActions.deleteProtokoll, (state, { workflowId }) => ({
    ...state,
    // Damit kein altes Protokoll auf der Protokollseite angezeigt wird, löschen wir bei neu laden den alten Wert
    workflowProtokolle: workflowProtokollAdapter.removeOne(workflowId, state.workflowProtokolle),
  })),
  on(engineActions.loadProtokollSucceeded, (state, { protokoll }) => ({
    ...state,
    workflowProtokolle: workflowProtokollAdapter.upsertOne(protokoll, state.workflowProtokolle),
  })),
  on(
    engineActions.loadProtokollFailed,
    (state, { workflowId, loadingStatus, loadingErrorDetails }) => ({
      ...state,
      workflowProtokolle: workflowProtokollAdapter.upsertOne(
        {
          workflowId: workflowId,
          loaded: false,
          loadingStatus,
          loadingErrorDetails,
          protokoll: undefined,
        },
        state.workflowProtokolle
      ),
    })
  ),

  on(
    commonActions.tenantChanged,
    (_state): EngineState => ({
      ...initialEngineState,
    })
  )
);

function upsertWorkflowIntoState(
  workflow: WorkflowModel,
  state: EngineState,
  loadingStatus: LoadingStatus,
  loadingErrorDetails: string
): EngineState {
  return {
    ...state,
    workflows: workflowAdapter.upsertOne(
      {
        workflowId: workflow.workflowId,
        timeStamp: workflow.timeStamp,
        timeStampTicks: workflow.timeStampTicks,
        loaded: true,
        loadingStatus,
        loadingErrorDetails,
        erstelltInReleaseVersion: workflow.erstelltInReleaseVersion,
      },
      state.workflows
    ),
    workflowinformationen: workflowinformationenAdapter.upsertOne(
      workflow.informationen,
      state.workflowinformationen
    ),
    workflowverlaeufe: workflowverlaeufeAdapter.upsertOne(
      workflow.verlauf,
      state.workflowverlaeufe
    ),
    alleWorkflows:
      state.alleWorkflows.includes(workflow.workflowId) === false
        ? state.alleWorkflows.concat([workflow.workflowId])
        : state.alleWorkflows,
  };
}

function upsertWorkflowverlaufIntoState(
  workflowverlauf: WorkflowverlaufModel,
  state: EngineState
): EngineState {
  return {
    ...state,
    workflowverlaeufe: workflowverlauf
      ? workflowverlaeufeAdapter.upsertOne(workflowverlauf, state.workflowverlaeufe)
      : state.workflowverlaeufe,
  };
}

function getMaxTimeStamp(workflows: WorkflowInformationenFuerTabelleModel[]): string {
  const maxWorkflow = lodash.maxBy(
    workflows,
    (w: WorkflowInformationenFuerTabelleModel) => w.timeStamp
  );
  return maxWorkflow?.timeStampTicks;
}

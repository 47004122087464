<div class="container-fluid" id="navigation_toolbar">
  <mat-toolbar class="toolbar" role="navigation">
    <mat-toolbar-row>
      <ng-container [ngTemplateOutlet]="navCompany"> </ng-container>

      <!-- header-menü, sichtbar wenn screen breit genug ist -->
      <ng-container [ngTemplateOutlet]="navTopMenu"> </ng-container>

      <div class="hide-gt-sm full-width">
        <div class="toggle-navigation">
          <button
            mat-button
            type="button"
            class="toggle-button"
            (click)="toggleNavigation()"
            data-testid="navigationToggleButton"
            id="toolbar-toggle-sidenav"
            [attr.aria-label]="
              navigationCollapsed
                ? ('Texte.Navigation.ScreenReader.NavigationOeffnen' | translate)
                : ('Texte.Navigation.ScreenReader.NavigationSchliessen' | translate)
            "
          >
            <mat-icon fontSet="fa" fontIcon="fa-bars" class="fa-2x toggle-button"></mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <!-- sidenav - wird getriggert, wenn screensize zu klein -->
  @if (!navigationCollapsed) {
    <mat-sidenav-container class="hide-gt-sm">
      <mat-sidenav
        #sidenav
        [opened]="!navigationCollapsed"
        color="primary"
        position="start"
        (closedStart)="navigationCollapsed = true"
      >
        <ng-container [ngTemplateOutlet]="sidenavMenu"> </ng-container>
      </mat-sidenav>
    </mat-sidenav-container>
  }
</div>

<!-- container mit Logo und Tenantname -->
<ng-template #navCompany>
  <span class="display-contents toolbar-brand-image-item">
    <a
      mat-button
      tabindex="0"
      data-testid="navigationRootLinkImage"
      class="toolbar-brand-image"
      title="Startseite"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLink]="getRouterLinkUrlForCurrentTenant()"
      [ngStyle]="{ backgroundImage: backgroundUrl }"
    ></a>
  </span>
  @if (erstellerTenants$ | async; as tenants) {
    <div class="tenant-select" [ngClass]="{ 'full-width-for-small-screen': tenants.length === 1 }">
      @if (tenants.length > 1) {
        <mat-select
          class="toolbar"
          #tenantSelect
          [value]="tenantService.currentTenant$ | async"
          (selectionChange)="tenantService.switchTenant($event.value)"
          placeholder="Mandanten auswählen"
          panelClass="panel-tenant-select"
        >
          @if (tenants.length > 4) {
            <div class="tenantFilterContainer">
              <mat-form-field class="full-width">
                <input
                  matInput
                  type="text"
                  (keyup)="filterTenants($event, tenants)"
                  type="text"
                  placeholder="{{ 'Texte.Allgemein.Alle' | translate }}"
                />
              </mat-form-field>
              @if ((filteredTenants$ | async)?.length === 0) {
                <div>Keine Ergebnisse gefunden</div>
              }
            </div>
          }
          @if (showCurrentTenantInOptions(tenants)) {
            <mat-option class="tenant-option" [value]="tenantService.currentTenant$ | async">
              {{ (tenantService.currentTenant$ | async)?.name }}
            </mat-option>
          }
          @for (tenant of filteredTenants$ | async; track trackTenantBy($index, tenant)) {
            <mat-option class="tenant-option" [value]="tenant">
              <div class="tenant-option-content">
                <img
                  class="tenant-logo"
                  [src]="tenant.logo ?? sechseckLogoPath"
                  alt="{{ tenant.name }}"
                />
                <span class="tenant-name allow-break-word">{{ tenant.name }}</span>
              </div>
            </mat-option>
          }
        </mat-select>
      } @else {
        <div class="single-tenant">
          <span class="tenant-name single-tenant">
            {{ (tenantService.currentTenant$ | async)?.name }}
          </span>
        </div>
      }
    </div>
  }
  <mat-divider [vertical]="true" class="hide-sm"></mat-divider>
</ng-template>

<!-- Container für Menüleiste oben -->
<ng-template #navTopMenu>
  <ul class="display-contents hide-sm">
    <li>
      <a
        mat-button
        class="tab-aufgaben"
        [routerLink]="getRouterLinkUrlForCurrentTenant('aufgaben')"
        routerLinkActive="active-link"
        (click)="closeNavigation()"
        data-testid="navigationAufgabenLink_header"
        [matBadge]="getBadgeValue(meineAufgabenCount$ | async)"
        [matBadgeHidden]="(meineAufgabenCount$ | async) === 0"
        matBadgeSize="small"
        [attr.aria-label]="
          'Texte.Navigation.ButtonMitBadge.AufgabenButtonMitAnzahl'
            | translate
              : {
                  seitentitel: 'Texte.Navigation.Aufgaben' | translate,
                  aufgabenzahl: meineAufgabenCount$ | async,
                }
        "
      >
        {{ 'Texte.Navigation.Aufgaben' | translate }}
      </a>
    </li>

    <li aria-hidden="true">
      <mat-divider [vertical]="true" aria-hidden="true"></mat-divider>
    </li>

    <li>
      <a
        mat-button
        class="tab-workflows"
        [routerLink]="getRouterLinkUrlForCurrentTenant('workflows')"
        routerLinkActive="active-link"
        (click)="closeNavigation()"
        data-testid="navigationWorkflowsLink_header"
        [matBadge]="getBadgeValue(workflowCount$ | async)"
        [matBadgeHidden]="(workflowCount$ | async) === 0"
        matBadgeSize="small"
        [attr.aria-label]="
          'Texte.Navigation.ButtonMitBadge.WorkflowButtonMitAnzahl'
            | translate
              : {
                  seitentitel: 'Texte.Navigation.Workflows' | translate,
                  workflowzahl: workflowCount$ | async,
                }
        "
      >
        {{ 'Texte.Navigation.Workflows' | translate }}
      </a>
    </li>

    @if (tenantService.currentTenant?.id !== 0) {
      <li aria-hidden="true">
        <mat-divider [vertical]="true" aria-hidden="true"></mat-divider>
      </li>
    }

    @if (tenantService.currentTenant?.id !== 0) {
      <li>
        <a
          mat-button
          class="tab-zusammenarbeit"
          [routerLink]="getRouterLinkUrlForCurrentTenant('zusammenarbeit')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationZusammenarbeitLink_header"
          matBadge="!"
          [matBadgeHidden]="
            (hasVertreter() | async) === true || (hasVertreter() | async) === undefined
          "
          matBadgeSize="small"
          [attr.aria-label]="getZusammenarbeitAriaLabel() | async"
        >
          {{ 'Texte.Navigation.Zusammenarbeit' | translate }}
        </a>
      </li>
    }

    <li aria-hidden="true">
      @if ((currentUser$ | async)?.isWorkflowErsteller && tenantService.currentTenant?.id !== 0) {
        <mat-divider [vertical]="true" aria-hidden="true"></mat-divider>
      }
    </li>

    @if ((currentUser$ | async)?.isWorkflowErsteller && tenantService.currentTenant?.id !== 0) {
      <li>
        <a
          mat-button
          class="tab-vorlagen"
          data-testid="navigationVorlagenLink_header"
          [routerLink]="getRouterLinkUrlForCurrentTenant('vorlagen/persoenlich')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu'
              | translate: { seitentitel: 'Texte.Navigation.MeineVorlagen' | translate }
          "
        >
          {{ 'Texte.Navigation.MeineVorlagen' | translate }}
        </a>
      </li>
    }

    <li aria-hidden="true">
      @if ((currentUser$ | async)?.isWorkflowErsteller && tenantService.currentTenant?.id !== 0) {
        <mat-divider [vertical]="true" aria-hidden="true"></mat-divider>
      }
    </li>

    @if ((currentUser$ | async)?.isWorkflowErsteller && tenantService.currentTenant?.id !== 0) {
      <li>
        <a
          mat-button
          class="mat-link workflow-erstellen"
          [routerLink]="getRouterLinkUrlForCurrentTenant('neu')"
          (click)="closeNavigation()"
          data-testid="navigationNeuerWorkflowButton_header"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu'
              | translate: { seitentitel: 'Texte.Navigation.NeuerWorkflow' | translate }
          "
        >
          <mat-icon class="topnav-button" fontSet="fa" fontIcon="fa-plus"></mat-icon>
          {{ 'Texte.Navigation.NeuerWorkflow' | translate }}
        </a>
      </li>
    }
  </ul>

  <span class="spacer hide-sm" aria-hidden="true"></span>

  <ul class="display-contents right hide-sm">
    @if ((currentUser$ | async)?.isWorkflowAdministrator && tenantService.currentTenant?.id > 0) {
      <li>
        <a
          mat-icon-button
          class="tab-grundeinstellung"
          [@fadeIn]
          [routerLink]="getRouterLinkUrlForCurrentTenant('grundeinstellungen')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationGrundeinstellungenLink_header"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu'
              | translate: { seitentitel: 'Texte.Navigation.Grundeinstellungen' | translate }
          "
          [title]="'Texte.Navigation.Grundeinstellungen' | translate"
        >
          <mat-icon fontSet="fa" fontIcon="fa-cogs"></mat-icon>
        </a>
      </li>
    }

    @if ((currentUser$ | async)?.isSystemAdmin && tenantService.currentTenant?.id === 0) {
      <li>
        <a
          mat-icon-button
          class="tab-grundeinstellung"
          [@fadeIn]
          [routerLink]="getRouterLinkUrlForCurrentTenant('management')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationGrundeinstellungenLink_header"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu' | translate: { seitentitel: 'Management Tool' }
          "
          title="Management Tool"
        >
          <mat-icon fontSet="fa" fontIcon="fa-cogs"></mat-icon>
        </a>
      </li>
    }
    <li>
      <button
        class="tab-benutzereinstellung"
        mat-icon-button
        [matMenuTriggerFor]="benutzerMenu"
        [title]="'Texte.Navigation.Benutzeraktionen' | translate"
        [ngClass]="router.url.split('/').includes('benutzereinstellungen') ? 'active-link' : null"
      >
        <mat-icon fontSet="fa" fontIcon="fa-user"></mat-icon>
      </button>
      <mat-menu #benutzerMenu>
        <button
          id="benutzereinstellungen-menu-item"
          mat-menu-item
          [routerLink]="getRouterLinkUrlForCurrentTenant('benutzereinstellungen')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationBenutzereinstellungenLink_header"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu'
              | translate: { seitentitel: 'Texte.Navigation.Benutzereinstellungen' | translate }
          "
        >
          <mat-icon fontSet="fa" fontIcon="fa-user-cog" class="nav-menu-item"></mat-icon>&nbsp;
          {{ 'Texte.Navigation.Benutzereinstellungen' | translate }}
        </button>
        <button
          id="abmelden-menu-item"
          data-testid="navigationAbmeldenLink_header"
          mat-menu-item
          class="mat-link"
          (click)="logout()"
        >
          <mat-icon fontSet="fas" fontIcon="fa-right-from-bracket" class="nav-menu-item"></mat-icon>
          {{ 'Texte.Navigation.Abmelden' | translate }}
        </button>
      </mat-menu>
    </li>
  </ul>
</ng-template>

<!-- Container Sidenav-Menü -->
<ng-template #sidenavMenu>
  <mat-nav-list>
    <ul>
      <li>
        <a
          mat-button
          class="tab-aufgaben"
          [routerLink]="getRouterLinkUrlForCurrentTenant('aufgaben')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationAufgabenLink_sidenav"
          [attr.aria-label]="
            'Texte.Navigation.ButtonMitBadge.AufgabenButtonMitAnzahl'
              | translate
                : {
                    seitentitel: 'Texte.Navigation.Aufgaben' | translate,
                    aufgabenzahl: meineAufgabenCount$ | async,
                  }
          "
        >
          <span
            [matBadge]="getBadgeValue(meineAufgabenCount$ | async)"
            [matBadgeHidden]="(meineAufgabenCount$ | async) === 0"
            matBadgeOverlap="false"
          >
            <span>{{ 'Texte.Navigation.Aufgaben' | translate }}</span>
          </span>
        </a>
      </li>
      <li>
        <a
          mat-button
          class="tab-workflows"
          [routerLink]="getRouterLinkUrlForCurrentTenant('workflows')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationWorkflowsLink_sidenav"
          [attr.aria-label]="
            'Texte.Navigation.ButtonMitBadge.WorkflowButtonMitAnzahl'
              | translate
                : {
                    seitentitel: 'Texte.Navigation.Workflows' | translate,
                    workflowzahl: workflowCount$ | async,
                  }
          "
        >
          <span
            [matBadge]="getBadgeValue(workflowCount$ | async)"
            [matBadgeHidden]="(workflowCount$ | async) === 0"
            matBadgeOverlap="false"
          >
            {{ 'Texte.Navigation.Workflows' | translate }}
          </span>
        </a>
      </li>
      <li>
        <a
          mat-button
          class="tab-zusammenarbeit"
          [routerLink]="getRouterLinkUrlForCurrentTenant('zusammenarbeit')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationZusammenarbeitLink_sidenav"
          [attr.aria-label]="getZusammenarbeitAriaLabel() | async"
        >
          <span matBadge="!" [matBadgeHidden]="hasVertreter() | async" matBadgeOverlap="false">
            {{ 'Texte.Navigation.Zusammenarbeit' | translate }}
          </span>
        </a>
      </li>
      @if (tenantService.currentTenant?.id !== 0) {
        <li>
          @if ((currentUser$ | async)?.isWorkflowErsteller) {
            <a
              mat-button
              class="tab-vorlagen"
              data-testid="navigationVorlagenLink_sidenav"
              [routerLink]="getRouterLinkUrlForCurrentTenant('vorlagen/persoenlich')"
              routerLinkActive="active-link"
              (click)="closeNavigation()"
              [attr.aria-label]="
                'Texte.Navigation.NavigiereZu'
                  | translate: { seitentitel: 'Texte.Navigation.MeineVorlagen' | translate }
              "
            >
              {{ 'Texte.Navigation.MeineVorlagen' | translate }}
            </a>
          }
        </li>
      }
      <li aria-hidden="true">
        @if (tenantService.currentTenant?.id !== 0) {
          <mat-divider></mat-divider>
        }
      </li>
      @if ((currentUser$ | async)?.isWorkflowErsteller && tenantService.currentTenant?.id !== 0) {
        <li>
          <a
            mat-button
            class="mat-link workflow-erstellen"
            [routerLink]="getRouterLinkUrlForCurrentTenant('new')"
            (click)="closeNavigation()"
            data-testid="navigationNeuerWorkflowButton_sidenav"
            [attr.aria-label]="
              'Texte.Navigation.NavigiereZu'
                | translate: { seitentitel: 'Texte.Navigation.NeuerWorkflow' | translate }
            "
          >
            <mat-icon class="sidenav-icon" fontSet="fa" fontIcon="fa-plus"></mat-icon>
            {{ 'Texte.Navigation.NeuerWorkflow' | translate }}
          </a>
        </li>
      }
      <li aria-hidden="true">
        @if (tenantService.currentTenant?.id !== 0) {
          <mat-divider></mat-divider>
        }
      </li>
      @if (
        (currentUser$ | async)?.isWorkflowAdministrator && tenantService.currentTenant?.id !== 0
      ) {
        <li>
          <a
            mat-button
            class="tab-grundeinstellung"
            [@fadeIn]
            [routerLink]="getRouterLinkUrlForCurrentTenant('grundeinstellungen')"
            routerLinkActive="active-link"
            (click)="closeNavigation()"
            data-testid="navigationGrundeinstellungenLink_sidenav"
            [attr.aria-label]="
              'Texte.Navigation.NavigiereZu'
                | translate: { seitentitel: 'Texte.Navigation.Grundeinstellungen' | translate }
            "
          >
            <mat-icon class="sidenav-icon" fontSet="fa" fontIcon="fa-cogs"></mat-icon>
            {{ 'Texte.Navigation.Grundeinstellungen' | translate }}
          </a>
        </li>
      }
      @if ((currentUser$ | async)?.isSystemAdmin && tenantService.currentTenant?.id === 0) {
        <li>
          <a
            mat-button
            [@fadeIn]
            [routerLink]="getRouterLinkUrlForCurrentTenant('management')"
            routerLinkActive="active-link"
            (click)="closeNavigation()"
            data-testid="navigationManagementLink_sidenav"
            [attr.aria-label]="
              'Texte.Navigation.NavigiereZu' | translate: { seitentitel: 'Management Tool' }
            "
            title="Management Tool"
          >
            <mat-icon class="sidenav-icon" fontSet="fa" fontIcon="fa-cogs"></mat-icon> Management
            Tool
          </a>
        </li>
      }
      <li>
        <a
          mat-button
          class="tab-benutzereinstellung"
          [routerLink]="getRouterLinkUrlForCurrentTenant('benutzereinstellungen')"
          routerLinkActive="active-link"
          (click)="closeNavigation()"
          data-testid="navigationBenutzereinstellungenLink_sidenav"
          [attr.aria-label]="
            'Texte.Navigation.NavigiereZu'
              | translate: { seitentitel: 'Texte.Navigation.Benutzereinstellungen' | translate }
          "
        >
          <mat-icon class="sidenav-icon" fontSet="fa" fontIcon="fa-user-cog"></mat-icon>
          {{ 'Texte.Navigation.Benutzereinstellungen' | translate }}
        </a>
      </li>
      <li>
        <a
          mat-button
          class="tab-abmelden"
          routerLink=""
          (click)="logout()"
          data-testid="navigationAbmeldenLink_sidenav"
        >
          <mat-icon class="sidenav-icon" fontSet="fas" fontIcon="fa-right-from-bracket"></mat-icon>
          {{ 'Texte.Navigation.Abmelden' | translate }}
        </a>
      </li>
    </ul>
  </mat-nav-list>
</ng-template>

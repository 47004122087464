
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLinkActive } from '@angular/router';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { guid } from '@dworkflow/shared/utility/guid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dworkflow-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLinkActive, MatIconModule, TranslateModule],
})
export class FooterComponent {
  @Input()
  useRouter = true;
  @Input()
  showSupportInformationenButton = true;
  translationsLoaded: boolean;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    translate: TranslateService,
    private tenantService: TenantService
  ) {
    // Prüfung ob Translations geladen sind, da die Komponente auch auf der Login-Seite angezeigt werden kann.
    // Dort sind die Translations aufgrund der noch nicht erfolgten Authentifizierung nicht verfügbar.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.translationsLoaded = !!translate.translations.config.Texte;
  }

  openSupportInfoModal(): void {
    import(
      '@dworkflow/footer/support-informationen-modal/support-informationen-modal.component'
    ).then(c => {
      const urlFragments = this.router.url.split('/');
      let workflowId = null;
      if (urlFragments.length > 3 && urlFragments[2] === 'workflows') {
        workflowId = urlFragments[3] as guid;
      }

      this.dialog.open(c.SupportInformationenModalComponent, {
        data: { workflowId },
        disableClose: true,
        ariaLabel: 'Supportinformationen',
      });
    });
  }

  redirectTo(url: string): void {
    this.tenantService.redirectUrl(url);
  }
}

import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { CanActivateAdministratorGuard } from '@dworkflow/shared/guards/can-activate-administrator.guard';
import { CanActivateErstellerGuard } from '@dworkflow/shared/guards/can-activate-ersteller.guard';
import { CanActivateFormularBearbeitungGuard } from '@dworkflow/shared/guards/can-activate-formular-bearbeitung-guard';
import { CanDeactivateGuard } from '@dworkflow/shared/guards/can-deactivate.guard';
import { HomeComponent } from './home/home.component';
import { CanActivateSysadminGuard } from './shared/guards/can-activate-sysadmin.guard';

export const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      titel: 'Texte.Navigation.Startseite',
    },
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'management',
    loadComponent: () => import('@dworkflow-management').then(c => c.ManagementRootComponent),
    canActivate: [CanActivateSysadminGuard],
  },
  {
    path: 'workflows',
    redirectTo: 'workflows/eigene',
    pathMatch: 'full',
    data: {
      titel: 'Texte.MeineWorkflows.MeineWorkflows',
    },
  },
  {
    path: 'workflows/eigene',
    loadComponent: () =>
      import('@dworkflow/workflow/meine-workflows/meine-workflows.component').then(
        c => c.MeineWorkflowsComponent
      ),
    data: {
      titel: 'Texte.Navigation.Workflows',
    },
  },
  {
    path: 'workflows/alle',
    loadComponent: () =>
      import('@dworkflow/workflow/meine-workflows/meine-workflows.component').then(
        c => c.MeineWorkflowsComponent
      ),
    data: {
      titel: 'Texte.Navigation.Workflows',
    },
  },
  {
    path: 'aufgaben',
    loadComponent: () =>
      import('@dworkflow/workflow/meine-aufgaben/meine-aufgaben.component').then(
        c => c.MeineAufgabenComponent
      ),
    data: {
      titel: 'Texte.Navigation.Aufgaben',
    },
  },

  /**
   * Footer
   */
  { path: 'help', redirectTo: 'hilfe' },
  {
    path: 'hilfe',
    loadComponent: () =>
      import('@dworkflow/footer/hilfe/hilfe.component').then(c => c.HilfeComponent),
    data: {
      titel: 'Texte.Navigation.Hilfe',
    },
  },
  {
    path: 'impressum',
    loadComponent: () =>
      import('@dworkflow/footer/impressum/impressum.component').then(c => c.ImpressumComponent),
    data: {
      titel: 'Texte.Navigation.Impressum',
    },
  },
  {
    path: 'barrierefreiheit',
    loadComponent: () =>
      import(
        '@dworkflow/footer/barrierefreiheitserklaerung/barrierefreiheitserklaerung.component'
      ).then(c => c.BarrierefreiheitserklaerungComponent),
    data: {
      titel: 'Texte.Navigation.Barrierefreiheit',
    },
  },
  {
    path: 'datenschutz',
    loadComponent: () =>
      import('@dworkflow/footer/datenschutzerklaerung/datenschutzerklaerung.component').then(
        c => c.DatenschutzerklaerungComponent
      ),
    data: {
      titel: 'Texte.Navigation.Datenschutz',
    },
  },
  /**
   * Benutzereinstellungen
   */
  {
    path: 'benutzereinstellungen',
    loadComponent: () =>
      import('@dworkflow/benutzereinstellungen/benutzereinstellungen.component').then(
        c => c.BenutzereinstellungenComponent
      ),
    canDeactivate: [CanDeactivateGuard],
    data: {
      titel: 'Texte.Benutzereinstellungen.Benutzereinstellungen.Titel',
    },
  },
  {
    matcher: (url: UrlSegment[]): UrlMatchResult => {
      if (url.length > 0) {
        return {
          consumed: [url[0]],
          posParams: {
            tenant: url[0],
          },
        };
      }

      return null;
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          titel: 'Texte.Navigation.Startseite',
        },
      },
      {
        path: 'home',
        redirectTo: '',
        pathMatch: 'full',
      },
      { path: 'workflows', redirectTo: 'workflows/eigene' },
      {
        path: 'workflows',
        data: {
          titel: 'Texte.MeineWorkflows.MeineWorkflows',
        },
        children: [
          {
            path: 'eigene',
            pathMatch: 'full',
            loadComponent: () =>
              import('@dworkflow/workflow/meine-workflows/meine-workflows.component').then(
                c => c.MeineWorkflowsComponent
              ),
            data: {
              titel: 'Texte.Navigation.Workflows',
            },
          },
          {
            path: 'alle',
            pathMatch: 'full',
            loadComponent: () =>
              import('@dworkflow/workflow/meine-workflows/meine-workflows.component').then(
                c => c.MeineWorkflowsComponent
              ),
            data: {
              titel: 'Texte.Navigation.Workflows',
            },
          },
          {
            path: ':workflowId',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('@dworkflow/workflow/workflow-detail/workflow-detail.component').then(
                    c => c.DetailComponent
                  ),
                data: {
                  titel: undefined,
                },
              },
              {
                path: 'verlauf',
                pathMatch: 'full',
                loadComponent: () =>
                  import(
                    '@dworkflow/workflow/workflowverlauf/workflowverlauf-edit-view/workflowverlauf-edit-view.component'
                  ).then(c => c.WorkflowverlaufEditViewComponent),
                data: {
                  titel: undefined,
                },
              },
              {
                path: 'faelligkeiten',
                pathMatch: 'full',
                loadComponent: () =>
                  import(
                    '@dworkflow/workflow/workflowverlauf/workflowverlauf-faelligkeiten-edit-view/workflowverlauf-faelligkeiten-edit-view.component'
                  ).then(c => c.WorkflowverlaufFaelligkeitenEditViewComponent),
                data: {
                  titel: undefined,
                },
              },
              {
                path: 'protokoll',
                pathMatch: 'full',
                loadComponent: () =>
                  import(
                    '@dworkflow/workflow/workflow-protokoll/workflow-protokoll.component'
                  ).then(c => c.ProtokollComponent),
                data: {
                  titel: 'Texte.Protokoll.Protokoll',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'aufgaben',
        loadComponent: () =>
          import('@dworkflow/workflow/meine-aufgaben/meine-aufgaben.component').then(
            c => c.MeineAufgabenComponent
          ),
        data: {
          titel: 'Texte.Navigation.Aufgaben',
        },
      },

      /**
       * Vertretung
       */
      {
        path: 'zusammenarbeit',
        loadComponent: () =>
          import('@dworkflow/zusammenarbeit/zusammenarbeit.component').then(
            c => c.ZusammenarbeitComponent
          ),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: 'Texte.Zusammenarbeit.Titel',
        },
      },
      {
        path: 'zusammenarbeit/vertretung-wahrnehmen',
        loadComponent: () =>
          import(
            '@dworkflow/zusammenarbeit/vertretungen/vertretung-wahrnehmen/vertretung-wahrnehmen.component'
          ).then(c => c.VertretungWahrnehmenComponent),
        data: {
          titel: undefined,
        },
      },
      {
        path: 'vertretung-wahrnehmen',
        loadComponent: () =>
          import(
            '@dworkflow/zusammenarbeit/vertretungen/vertretung-wahrnehmen/vertretung-wahrnehmen.component'
          ).then(c => c.VertretungWahrnehmenComponent),
        data: {
          titel: undefined,
        },
      },
      {
        path: 'zusammenarbeit/arbeitsgruppe/new',
        redirectTo: 'zusammenarbeit/arbeitsgruppe/neu',
      },
      {
        path: 'zusammenarbeit/arbeitsgruppe/neu',
        loadComponent: () =>
          import(
            '@dworkflow/zusammenarbeit/arbeitsgruppen/arbeitsgruppe-edit/arbeitsgruppe-edit.component'
          ).then(c => c.ArbeitsgruppeEditComponent),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: undefined,
        },
      },
      {
        path: 'zusammenarbeit/arbeitsgruppe/:arbeitsgruppenId',
        loadComponent: () =>
          import(
            '@dworkflow/zusammenarbeit/arbeitsgruppen/arbeitsgruppe-edit/arbeitsgruppe-edit.component'
          ).then(c => c.ArbeitsgruppeEditComponent),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: undefined,
        },
      },

      /**
       * Neuer Workflow
       */
      {
        path: 'neu',
        loadComponent: () =>
          import('@dworkflow/workflow/neuer-workflow/neuer-workflow.component').then(
            c => c.NeuerWorkflowComponent
          ),
        canActivate: [CanActivateErstellerGuard],
        data: {
          titel: 'Texte.Navigation.NeuerWorkflow',
        },
      },
      {
        path: 'new',
        redirectTo: 'neu',
      },
      {
        path: 'neu/wizard/:vorlagenId',
        loadComponent: () =>
          import(
            '@dworkflow/workflow/neuer-workflow/neuer-workflow-wizard/neuer-workflow-wizard.component'
          ).then(c => c.NeuerWorkflowWizardComponent),
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateErstellerGuard],
        data: {
          titel: 'Texte.Navigation.NeuerWorkflow',
        },
      },

      /**
       * Vorlagen
       */
      {
        path: 'vorlagen/persoenlich',
        loadComponent: () =>
          import('@dworkflow/vorlagen/vorlagen-list-view/vorlagen-list-view.component').then(
            c => c.VorlagenListViewComponent
          ),
        data: {
          titel: 'Texte.Navigation.MeineVorlagen',
        },
      },
      {
        path: 'vorlagen/:vorlagenLadeType/new',
        redirectTo: 'vorlagen/:vorlagenLadeType/neu',
      },
      {
        path: 'vorlagen/:vorlagenLadeType/neu',
        loadComponent: () =>
          import('@dworkflow/vorlagen/vorlage-edit-view/vorlage-edit-view.component').then(
            c => c.VorlageEditViewComponent
          ),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: undefined,
        },
      },
      {
        path: 'vorlagen/:vorlagenLadeType/:vorlagenId/edit',
        redirectTo: 'vorlagen/:vorlagenLadeType/:vorlagenId/bearbeiten',
      },
      {
        path: 'vorlagen/:vorlagenLadeType/:vorlagenId/bearbeiten',
        loadComponent: () =>
          import('@dworkflow/vorlagen/vorlage-edit-view/vorlage-edit-view.component').then(
            c => c.VorlageEditViewComponent
          ),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: undefined,
        },
      },

      /**
       * Workflows
       */
      /**
       * Formulare
       */
      { path: 'formular/new', redirectTo: 'formular/neu' },
      {
        path: 'formular/neu',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/formulare/formular-edit/formular-edit.component'
          ).then(c => c.FormularEditComponent),
        canActivate: [CanActivateFormularBearbeitungGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: 'Texte.Navigation.FormularErstellen',
          restrictedToSystemAdminsOnly: true,
        },
      },
      {
        path: 'formular/:formularId',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/formulare/formular-edit/formular-edit.component'
          ).then(c => c.FormularEditComponent),
        canActivate: [CanActivateFormularBearbeitungGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: 'Texte.Navigation.FormularBearbeiten',
          restrictedToSystemAdminsOnly: true,
        },
      },
      /**
       * Vorlagenkonfigurationen
       */
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/create',
        redirectTo: 'grundeinstellungen/vorlagenkonfigurationen/neu',
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/neu',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/vorlagenkonfiguration-edit-view/vorlagenkonfiguration-edit-view.component'
          ).then(c => c.VorlagenkonfigurationEditViewComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          isNew: true,
          isCopy: false,
          isReadOnly: false,
          titel: 'Texte.Grundeinstellungen.Vorlagenkonfiguration.Erstellen',
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/edit',
        redirectTo: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/bearbeiten',
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/bearbeiten',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/vorlagenkonfiguration-edit-view/vorlagenkonfiguration-edit-view.component'
          ).then(c => c.VorlagenkonfigurationEditViewComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          isNew: false,
          isCopy: false,
          isReadOnly: false,
          titel: 'Texte.Grundeinstellungen.Vorlagenkonfiguration.Bearbeiten',
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/copy',
        redirectTo: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/kopieren',
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/kopieren',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/vorlagenkonfiguration-edit-view/vorlagenkonfiguration-edit-view.component'
          ).then(c => c.VorlagenkonfigurationEditViewComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          isNew: true,
          isCopy: true,
          isReadOnly: false,
          titel: 'Texte.Grundeinstellungen.Vorlagenkonfiguration.Kopieren',
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId/view',
        redirectTo: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId',
      },
      {
        path: 'grundeinstellungen/vorlagenkonfigurationen/:konfigurationsId',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/vorlagenkonfiguration-edit-view/vorlagenkonfiguration-edit-view.component'
          ).then(c => c.VorlagenkonfigurationEditViewComponent),
        canActivate: [CanActivateAdministratorGuard],
        data: {
          isNew: false,
          isCopy: false,
          isReadOnly: true,
          titel: 'Texte.Grundeinstellungen.Vorlagenkonfiguration.Anzeigen',
          restrictedToSystemAdminsOnly: false,
        },
      },

      /**
       * Grundeinstellungen
       */
      {
        path: 'grundeinstellungen',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/grundeinstellungen-view/grundeinstellungen-view.component'
          ).then(c => c.GrundeinstellungenViewComponent),
        canActivate: [CanActivateAdministratorGuard],
        data: {
          titel: 'Texte.Grundeinstellungen.Grundeinstellungen',
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/formulare',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/formulare/formular-tabelle/formular-tabelle.component'
          ).then(c => c.FormularTabelleComponent),
        canActivate: [CanActivateFormularBearbeitungGuard],
        data: {
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/mandant',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/mandanten-einstellungen/mandanten-einstellungen.component'
          ).then(c => c.MandantenEinstellungenComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          restrictedToSystemAdminsOnly: true,
        },
      },
      {
        path: 'grundeinstellungen/vorlagenkonfiguration',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/vorlagenkonfigurationen-list/vorlagenkonfigurationen-list.component'
          ).then(c => c.VorlagenkonfigurationenListComponent),
        canActivate: [CanActivateAdministratorGuard],
        data: {
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/vorlagen',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/grundeinstellungen-vorlagen/grundeinstellungen-vorlagen.component'
          ).then(c => c.GrundeinstellungenVorlagenComponent),
        canActivate: [CanActivateAdministratorGuard],
        data: {
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/berechtigungen',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/grundeinstellungen-berechtigungen/grundeinstellungen-berechtigungen.component'
          ).then(c => c.GrundeinstellungenBerechtigungenComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/allgemein',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/allgemeine-einstellungen/allgemeine-einstellungen.component'
          ).then(c => c.AllgemeineEinstellungenComponent),
        canActivate: [CanActivateAdministratorGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          restrictedToSystemAdminsOnly: false,
        },
      },
      {
        path: 'grundeinstellungen/services',
        loadComponent: () =>
          import(
            '@dworkflow/grundeinstellungen/service-connections/service-connections.component'
          ).then(c => c.ServiceConnectionComponent),
        canActivate: [CanActivateAdministratorGuard],
        data: {
          restrictedToSystemAdminsOnly: true,
        },
      },

      /**
       * Benutzereinstellungen
       */
      {
        path: 'benutzereinstellungen',
        loadComponent: () =>
          import('@dworkflow/benutzereinstellungen/benutzereinstellungen.component').then(
            c => c.BenutzereinstellungenComponent
          ),
        canDeactivate: [CanDeactivateGuard],
        data: {
          titel: 'Texte.Benutzereinstellungen.Benutzereinstellungen.Titel',
        },
      },
      /**
       * Footer
       */
      { path: 'help', redirectTo: 'hilfe' },
      {
        path: 'hilfe',
        loadComponent: () =>
          import('@dworkflow/footer/hilfe/hilfe.component').then(c => c.HilfeComponent),
        data: {
          titel: 'Texte.Navigation.Hilfe',
        },
      },
      {
        path: 'impressum',
        loadComponent: () =>
          import('@dworkflow/footer/impressum/impressum.component').then(c => c.ImpressumComponent),
        data: {
          titel: 'Texte.Navigation.Impressum',
        },
      },
      {
        path: 'barrierefreiheit',
        loadComponent: () =>
          import(
            '@dworkflow/footer/barrierefreiheitserklaerung/barrierefreiheitserklaerung.component'
          ).then(c => c.BarrierefreiheitserklaerungComponent),
        data: {
          titel: 'Texte.Navigation.Barrierefreiheit',
        },
      },
      {
        path: 'datenschutz',
        loadComponent: () =>
          import('@dworkflow/footer/datenschutzerklaerung/datenschutzerklaerung.component').then(
            c => c.DatenschutzerklaerungComponent
          ),
        data: {
          titel: 'Texte.Navigation.Datenschutz',
        },
      },

      /**
       * Forbidden
       */
      {
        path: 'forbidden',
        loadComponent: () =>
          import('@dworkflow/shared/components/forbidden/forbidden.component').then(
            c => c.ForbiddenComponent
          ),
        data: {
          titel: 'Texte.Allgemein.Forbidden.TabTitel',
        },
      },

      /**
       * 404 für Urls mit Tenant
       */
      {
        path: '**',
        redirectTo: '404',
        data: {
          titel: undefined,
        },
      },
      {
        path: '404',
        loadComponent: () =>
          import('@dworkflow/shared/components/nicht-gefunden/nicht-gefunden.component').then(
            c => c.NichtGefundenComponent
          ),
        data: {
          titel: 'Texte.Allgemein.NichtGefunden.TabTitel',
        },
      },
    ],
  },

  /**
   * Footer
   */
  {
    path: '**',
    redirectTo: '404',
    data: {
      titel: undefined,
    },
  },
  {
    path: '404',
    loadComponent: () =>
      import('@dworkflow/shared/components/nicht-gefunden/nicht-gefunden.component').then(
        c => c.NichtGefundenComponent
      ),
    data: {
      titel: 'Texte.Allgemein.NichtGefunden.TabTitel',
    },
  },
];

import * as commonActions from '@dworkflow/state/common.actions';
import { createReducer, on } from '@ngrx/store';
import lodash from 'lodash';
import * as securityActions from './security.actions';
import {
  SecurityState,
  arbeitsgruppenAdapter,
  getPickerQueryKey,
  initialSecurityState,
  principalAdapter,
  serviceConnectionAdapter,
  tenantAdapter,
} from './security.state';
import * as vertreterActions from './vertreter.actions';

export const securityReducer = createReducer(
  initialSecurityState,

  on(securityActions.texteLoaded, (state): SecurityState => {
    return { ...state, texteLoaded: true };
  }),

  on(securityActions.requestLoadPrincipals, (state, { principalIds }) => {
    const principalsToLoad = lodash.uniq(state.principalsToLoad);
    if (!!principalIds && principalIds.length > 0) {
      for (const principalId of principalIds) {
        if (
          principalId !== 0 &&
          !state.principals.ids
            .map((id: number | string) => id as number)
            .some((i: number) => i === principalId) &&
          !principalsToLoad.some(id => principalId === id)
        ) {
          principalsToLoad.push(principalId);
        }
      }
      return { ...state, principalsToLoad };
    }
    return { ...state };
  }),

  on(securityActions.requestResolvePrincipals, (state, { principalSids }) => {
    const principalsToResolve = lodash.uniq(state.principalsToResolve);
    const resolvedSids = Object.values(state.principals.entities).map(p => p.sid);
    if (!!principalSids && principalSids.length > 0) {
      for (const principalSid of principalSids) {
        if (
          !!principalSid &&
          !resolvedSids.some(sid => sid.toLowerCase() === principalSid.toLowerCase()) &&
          !principalsToResolve.some(sid => sid.toLowerCase() === principalSid.toLowerCase())
        ) {
          principalsToResolve.push(principalSid.toLowerCase());
        }
      }
      return { ...state, principalsToResolve };
    }
    return { ...state };
  }),
  on(securityActions.loadPrincipalsSucceeded, (state, { principals }) => {
    const loadedPrincipals = new Set(principals.map(p => p.id));
    const principalsNotLoaded = lodash
      .clone(state.principalsToLoad)
      .filter(id => !loadedPrincipals.has(id));

    return {
      ...state,
      principals: principalAdapter.upsertMany(principals, state.principals),
      principalsToLoad: principalsNotLoaded,
    };
  }),
  on(securityActions.resolvePrincipalsSucceeded, (state, { resolvedPrincipals }) => {
    const loadedPrincipals = new Set(resolvedPrincipals.map(p => p.sid));
    const principalsNotResolved = lodash
      .clone(state.principalsToResolve)
      .filter(sid => !loadedPrincipals.has(sid));

    return {
      ...state,
      principals: principalAdapter.upsertMany(resolvedPrincipals, state.principals),
      principalsToResolve: principalsNotResolved,
    };
  }),
  on(securityActions.requestLoadTenant, (state, { tenantId }) => {
    // Tenant nur Laden, wenn seine Id ungleich 0 ist und er noch nicht im State vorhanden ist
    if (
      !!tenantId &&
      tenantId !== 0 &&
      !state.tenants.ids.some((i: number | string) => i === tenantId)
    ) {
      const tenantsToLoad = lodash.uniq([...state.tenantsToLoad, tenantId]);
      return { ...state, tenantsToLoad };
    }
    return { ...state };
  }),
  on(securityActions.loadTenantsSucceeded, (state, { tenants }) => {
    const loadedTenants = new Set(tenants.map(p => p.id));
    const tenantsToLoad = lodash.clone(state.tenantsToLoad).filter(id => !loadedTenants.has(id));
    return {
      ...state,
      tenants: tenantAdapter.upsertMany(tenants, state.tenants),
      tenantsToLoad,
    };
  }),
  on(
    securityActions.loadGroupMembersSucceeded,
    (state, { groupMembers, groupId }): SecurityState => {
      if (!state.groupMembers[groupId]) {
        return {
          ...state,
          groupMembers: {
            ...state.groupMembers,
            [groupId]: groupMembers,
          } as { [id: number]: number[] },
        };
      }
      return state;
    }
  ),

  // Vertreter reducer
  on(vertreterActions.loadVertreterSucceeded, (state, { vertreter }) => {
    return {
      ...state,
      principals: principalAdapter.upsertMany(vertreter, state.principals),
      vertreterIds: vertreter.map(v => v.id),
      vertreterLoaded: true,
    };
  }),
  on(vertreterActions.addVertreterSucceeded, (state, { vertreter }) => {
    return {
      ...state,
      principals: principalAdapter.upsertOne(vertreter, state.principals),
      vertreterIds: lodash.uniq([...state.vertreterIds, vertreter.id]),
    };
  }),
  on(vertreterActions.removeVertreterSucceeded, (state, { vertreterId }) => {
    return {
      ...state,
      vertreterIds: state.vertreterIds.filter(v => v !== vertreterId),
    };
  }),
  on(vertreterActions.loadVorgesetzteSucceeded, (state, { vorgesetzte }) => {
    return {
      ...state,
      principals: principalAdapter.upsertMany(vorgesetzte, state.principals),
      vorgesetzteIds: vorgesetzte.map(v => v.id),
      vorgesetzteLoaded: true,
    };
  }),
  on(vertreterActions.loadVertretungenSucceeded, (state, { vertretungen }) => {
    return {
      ...state,
      principals: principalAdapter.upsertMany(vertretungen, state.principals),
      vertretungenIds: vertretungen.map(v => v.id),
    };
  }),
  on(vertreterActions.loadMitarbeiterSucceeded, (state, { mitarbeiter }) => {
    return {
      ...state,
      principals: principalAdapter.upsertMany(mitarbeiter, state.principals),
      mitarbeiterIds: mitarbeiter.map(v => v.id),
    };
  }),

  on(securityActions.loadCurrentUserSucceeded, (state, { currentUser }) => {
    const clonedUser = lodash.cloneDeep(currentUser);
    if (clonedUser.benutzereinstellungen.defaultTheme === null) {
      clonedUser.benutzereinstellungen.defaultTheme = 'default-theme';
    }

    return {
      ...state,
      principals: principalAdapter.upsertOne(clonedUser, state.principals),
      currentUserId: currentUser.id,
      isSystemAdmin: currentUser.isSystemAdmin,
      benutzereinstellungen: clonedUser.benutzereinstellungen,
      benutzereinstellungenLoaded: true,
    };
  }),

  on(securityActions.loadAdministratorenSucceeded, (state, { administratoren }) => ({
    ...state,
    administratorenIds: administratoren.map(a => a.id),
    administratorenLoaded: true,
    principals: principalAdapter.upsertMany(administratoren, state.principals),
  })),
  on(securityActions.loadWorkflowErstellerSucceeded, (state, { workflowErsteller }) => ({
    ...state,
    workflowErstellerIds: workflowErsteller.map(w => w.id),
    workflowErstellerLoaded: true,
    principals: principalAdapter.upsertMany(workflowErsteller, state.principals),
  })),
  on(securityActions.loadWorkflowTeilnehmerSucceeded, (state, { workflowTeilnehmer }) => ({
    ...state,
    principals: principalAdapter.upsertMany(workflowTeilnehmer, state.principals),
    workflowTeilnehmerIds: workflowTeilnehmer.map(w => w.id),
    workflowTeilnehmerLoaded: true,
  })),
  on(
    securityActions.loadGenerellLeseberechtigtePrincipalsSucceeded,
    (state, { generellLeseberechtigtePrincipals }) => ({
      ...state,
      principals: principalAdapter.upsertMany(generellLeseberechtigtePrincipals, state.principals),
      generellLeseberechtigtePrincipalsIds: generellLeseberechtigtePrincipals.map(w => w.id),
      generellLeseberechtigtePrincipalsLoaded: true,
    })
  ),
  on(securityActions.addAdministratorSucceeded, (state, { administratoren }) => ({
    ...state,
    administratorenIds: administratoren.map(a => a.id),
  })),
  on(securityActions.removeAdministratorSucceeded, (state, { administratoren }) => ({
    ...state,
    administratorenIds: administratoren.map(a => a.id),
  })),
  on(securityActions.addWorkflowErstellerSucceeded, (state, { workflowErsteller }) => ({
    ...state,
    workflowErstellerIds: workflowErsteller.map(e => e.id),
  })),
  on(securityActions.removeWorkflowErstellerSucceeded, (state, { workflowErsteller }) => ({
    ...state,
    workflowErstellerIds: workflowErsteller.map(e => e.id),
  })),
  on(securityActions.addWorkflowTeilnehmerSucceeded, (state, { workflowTeilnehmer }) => ({
    ...state,
    workflowTeilnehmerIds: workflowTeilnehmer.map(t => t.id),
  })),
  on(securityActions.removeWorkflowTeilnehmerSucceeded, (state, { workflowTeilnehmer }) => ({
    ...state,
    workflowTeilnehmerIds: workflowTeilnehmer.map(t => t.id),
  })),
  on(
    securityActions.addGenerellLeseberechtigtenPrincipalSucceeded,
    (state, { generellLeseberechtigtePrincipals }) => ({
      ...state,
      generellLeseberechtigtePrincipalsIds: generellLeseberechtigtePrincipals.map(g => g.id),
    })
  ),
  on(
    securityActions.removeGenerellLeseberechtigtenPrincipalSucceeded,
    (state, { generellLeseberechtigtePrincipals }) => ({
      ...state,
      generellLeseberechtigtePrincipalsIds: generellLeseberechtigtePrincipals.map(g => g.id),
    })
  ),
  on(
    securityActions.checkLoginSucceeded,
    (state, { isLoggedIn }): SecurityState => ({
      ...state,
      isLoggedIn: isLoggedIn,
    })
  ),
  on(
    securityActions.checkLoginFailed,
    (state): SecurityState => ({
      ...state,
      isLoggedIn: false,
    })
  ),
  on(
    securityActions.searchForPrincipalsSucceeded,
    (state, { searchString, searchType, results, tenantId }) => ({
      ...state,
      pickerSearches: {
        ...state.pickerSearches,
        [getPickerQueryKey(searchString, searchType, tenantId)]: results,
      },
    })
  ),
  on(
    securityActions.loadEinstellungenSucceeded,
    (state, { einstellungen }): SecurityState => ({
      ...state,
      einstellungen,
    })
  ),
  // eslint-disable-next-line @ngrx/on-function-explicit-return-type
  on(securityActions.speichereMaxWorkflowAdministratorenSucceeded, (state, { maxAdminCount }) => ({
    ...state,
    einstellungen: { ...state.einstellungen, maxWorkflowAdministratoren: maxAdminCount },
  })),
  // Benutzereinstellungen
  on(
    securityActions.saveAccessibilityModusOn,
    (state, { accessibilityModusOn }): SecurityState => ({
      ...state,
      benutzereinstellungen: {
        ...state.benutzereinstellungen,
        accessibilityModusOn,
      },
    })
  ),
  on(
    securityActions.speichereDesignSucceeded,
    (state, { theme, darkmodeOn }): SecurityState => ({
      ...state,
      benutzereinstellungen: {
        ...state.benutzereinstellungen,
        theme: theme ?? state.benutzereinstellungen.defaultTheme,
        darkmodeOn,
      },
    })
  ),

  on(
    securityActions.saveAnzuzeigendeAufgabentabellenSpalten,
    (state, { anzuzeigendeAufgabentabellenSpalten }): SecurityState => ({
      ...state,
      benutzereinstellungen: {
        ...state.benutzereinstellungen,
        anzuzeigendeAufgabentabellenSpalten,
      },
    })
  ),
  on(
    securityActions.saveAnzuzeigendeWorkflowtabellenSpalten,
    (state, { anzuzeigendeWorkflowtabellenSpalten }): SecurityState => ({
      ...state,
      benutzereinstellungen: {
        ...state.benutzereinstellungen,
        anzuzeigendeWorkflowtabellenSpalten,
      },
    })
  ),
  // GetMaxWorkflowAdministratoren
  on(
    securityActions.getMaxWorkflowAdministratorenSucceeded,
    (state, { maxWorkflowAdministratoren }): SecurityState => ({
      ...state,
      maxWorkflowAdministratoren: maxWorkflowAdministratoren,
      maxWorkflowAdministratorenLoaded: true,
    })
  ),
  // Reset bei Tenant Wechsel
  on(
    commonActions.tenantChanged,
    (state): SecurityState => ({
      ...initialSecurityState,
      isSystemAdmin: state.isSystemAdmin,
      currentUserId: state.currentUserId,
      groupMembers: state.groupMembers,
      isLoggedIn: state.isLoggedIn,
      tenants: state.tenants,
      tenantsToLoad: state.tenantsToLoad,
    })
  ),

  on(securityActions.loadArbeitsgruppenSucceeded, (state, { arbeitsgruppen }) => ({
    ...state,
    arbeitsgruppenLoaded: true,
    arbeitsgruppen: arbeitsgruppenAdapter.upsertMany(arbeitsgruppen, state.arbeitsgruppen),
  })),

  on(securityActions.loescheArbeitsgruppeSucceeded, (state, { id }) => ({
    ...state,
    arbeitsgruppen: arbeitsgruppenAdapter.removeOne(id, state.arbeitsgruppen),
    pickerSearches: Object.entries(state.pickerSearches).reduce(
      (prev, curr) => ({
        ...prev,
        [curr[0]]: curr[1].filter(p => p.sid !== `Arbeitsgruppe_${id}`),
      }),
      {}
    ),
  })),
  on(securityActions.speichereArbeitsgruppeSucceeded, (state, { arbeitsgruppe }) => ({
    ...state,
    arbeitsgruppen: arbeitsgruppenAdapter.upsertOne(arbeitsgruppe, state.arbeitsgruppen),
  })),
  on(securityActions.ServiceConnectionActions.loadSucceeded, (state, { serviceConnections }) => ({
    ...state,
    serviceConnections: serviceConnectionAdapter.upsertMany(
      serviceConnections,
      state.serviceConnections
    ),
    serviceConnectionsLoaded: true,
  })),
  on(
    securityActions.ServiceConnectionActions.deleteSucceeded,
    (state, { serviceConnectionId }) => ({
      ...state,
      serviceConnections: serviceConnectionAdapter.removeOne(
        serviceConnectionId,
        state.serviceConnections
      ),
    })
  )
);

import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceConnection } from '@dworkflow/shared/model';
import { PrincipalModel, UserModel } from '@dworkflow/shared/model/security';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import * as commonActions from '@dworkflow/state/common.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import lodash from 'lodash';
import { EMPTY, of } from 'rxjs';
import {
  bufferTime,
  catchError,
  filter,
  finalize,
  first,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import { SecurityEinstellungenModel } from '../shared/model/security/security-einstellungen.model';
import { TenantModel } from '../shared/model/security/tenant.model';
import { AuthenticationService } from '../shared/services/authentication.service';
import { SecurityServiceInternal } from '../shared/services/security.service.internal';
import { TenantService } from '../shared/services/tenant.service';
import * as securityActions from './security.actions';
import * as fromStore from './security.state';

@Injectable({
  providedIn: 'root',
})
export class SecurityEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private securityService: SecurityServiceInternal,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private toastService: ToastService,
    private router: Router,
    private tenantService: TenantService
  ) {}

  loadPrincipals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadPrincipals),
      switchMap(_ =>
        this.store.select(fromStore.selectPrincipalsToLoad).pipe(
          take(1),
          switchMap(principalIds => {
            if (!principalIds || principalIds.length === 0) {
              return EMPTY;
            }
            return lodash
              .chunk(principalIds, 100)
              .map(ids => securityActions.loadPrincipalsByIds({ principalIds: ids }));
          })
        )
      )
    );
  });

  loadPrincipalsByIds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadPrincipalsByIds),
      mergeMap(action => {
        if (!action.principalIds || action.principalIds.length === 0) {
          return EMPTY;
        }
        return this.securityService.loadPrincipals(action.principalIds).pipe(
          switchMap((principals: PrincipalModel[]) => {
            return of(securityActions.loadPrincipalsSucceeded({ principals }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.PrincipalsLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.PrincipalsLaden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(securityActions.loadPrincipalsFailed({ error }));
          })
        );
      })
    );
  });

  resolvePrincipals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.resolvePrincipals),
      switchMap(_ =>
        this.store.select(fromStore.selectPrincipalsToResolve).pipe(
          take(1),
          switchMap(principalSids => {
            if (!principalSids || principalSids.length === 0) {
              return EMPTY;
            }
            return this.securityService.resolveBySids(principalSids).pipe(
              switchMap((principals: PrincipalModel[]) => {
                return of(
                  securityActions.resolvePrincipalsSucceeded({ resolvedPrincipals: principals })
                );
              }),
              catchError((error: HttpErrorResponse) => {
                this.toastService.showError(
                  this.translate.instant(
                    'Texte.Allgemein.Benachrichtigungen.PrincipalsLaden.FailedTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Allgemein.Benachrichtigungen.PrincipalsLaden.FailedMessage'
                  ) as string,
                  error.error as string,
                  error.url
                );
                return of(securityActions.resolvePrincipalsFailed({ error }));
              })
            );
          })
        )
      )
    );
  });

  loadTenants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadTenants),
      switchMap(_ =>
        this.store.select(fromStore.selectTenantsToLoad).pipe(
          take(1),
          switchMap(tenantIds => {
            if (!tenantIds || tenantIds.length === 0) {
              return EMPTY;
            }
            return this.securityService.loadTenants(tenantIds).pipe(
              switchMap((tenants: TenantModel[]) => {
                const notLoadedTenantIds = tenantIds.filter(i => !tenants.some(t => t.id === i));
                tenants = tenants.concat(
                  notLoadedTenantIds.map(id => ({ id, keineBerechtigung: true }) as TenantModel)
                );
                return [securityActions.loadTenantsSucceeded({ tenants })];
              }),
              catchError((error: HttpErrorResponse) => {
                this.toastService.showError(
                  this.translate.instant(
                    'Texte.Allgemein.Benachrichtigungen.TenantsLaden.FailedTitel'
                  ) as string,
                  this.translate.instant(
                    'Texte.Allgemein.Benachrichtigungen.TenantsLaden.FailedMessage'
                  ) as string,
                  error.error as string,
                  error.url
                );
                return of(securityActions.loadTenantsFailed({ error }));
              })
            );
          })
        )
      )
    );
  });

  requestLoadPrincipals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.requestLoadPrincipals),
      bufferTime(200),
      filter(actions => actions.length > 0),
      mergeMap(() => {
        return of(securityActions.loadPrincipals());
      })
    );
  });

  requestResolvePrincipals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.requestResolvePrincipals),
      bufferTime(200),
      filter(actions => actions.length > 0),
      mergeMap(() => {
        return of(securityActions.resolvePrincipals());
      })
    );
  });

  requestLoadTenant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.requestLoadTenant),
      bufferTime(200),
      filter(actions => actions.length > 0),
      mergeMap(() => {
        return of(securityActions.loadTenants());
      })
    );
  });

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadCurrentUser),
      switchMap(() => {
        return this.securityService.loadCurrentUser().pipe(
          switchMap((currentUser: UserModel) => {
            return of(securityActions.loadCurrentUserSucceeded({ currentUser }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.AngemeldetenNutzerLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.AngemeldetenNutzerLaden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(securityActions.loadCurrentUserFailed({ error }));
          })
        );
      })
    );
  });

  loadAdministratoren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadAdministratoren),
      mergeMap(() =>
        this.securityService.getAdministratoren().pipe(
          switchMap((administratoren: PrincipalModel[]) => {
            return of(
              securityActions.loadAdministratorenSucceeded({
                administratoren,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.loadAdministratorenFailed({ error }));
          })
        )
      )
    );
  });

  requestGroupMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.requestGroupMembers),
      mergeMap(({ groupId }) =>
        this.store.select(fromStore.selectGroupMembersById(groupId)).pipe(
          take(1),
          switchMap((groupMembers: number[]) => {
            if (groupMembers === undefined) {
              return of(securityActions.loadGroupMembers({ groupId }));
            }
            return [];
          })
        )
      )
    );
  });

  loadGroupMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadGroupMembers),
      mergeMap(({ groupId }) =>
        this.securityService.getGroupMembers(groupId).pipe(
          switchMap((groupMembers: number[]) => {
            return of(securityActions.loadGroupMembersSucceeded({ groupMembers, groupId }));
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Utilities.PeoplePicker.Benachrichtigungen.FailedLoad.Title'
              ) as string,
              this.translate.instant(
                'Texte.Utilities.PeoplePicker.Benachrichtigungen.FailedLoad.Message'
              ) as string,
              error
            );
            return of(securityActions.loadGroupMembersFailed({ error }));
          })
        )
      )
    );
  });

  loadWorkflowErsteller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadWorkflowErsteller),
      mergeMap(() =>
        this.securityService.getWorkflowErsteller().pipe(
          switchMap((workflowErsteller: PrincipalModel[]) => {
            return of(
              securityActions.loadWorkflowErstellerSucceeded({
                workflowErsteller,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.loadWorkflowErstellerFailed({ error }));
          })
        )
      )
    );
  });

  loadWorkflowTeilnehmer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadWorkflowTeilnehmer),
      mergeMap(() =>
        this.securityService.getWorkflowTeilnehmer().pipe(
          switchMap((workflowTeilnehmer: PrincipalModel[]) => {
            return of(
              securityActions.loadWorkflowTeilnehmerSucceeded({
                workflowTeilnehmer,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.loadWorkflowTeilnehmerFailed({ error }));
          })
        )
      )
    );
  });

  loadGenerellLeseberechtigte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loadGenerellLeseberechtigtePrincipals),
      mergeMap(() =>
        this.securityService.getGenerellLeseberechtigtePrincipals().pipe(
          switchMap((generellLeseberechtigtePrincipals: PrincipalModel[]) => {
            return of(
              securityActions.loadGenerellLeseberechtigtePrincipalsSucceeded({
                generellLeseberechtigtePrincipals,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.loadGenerellLeseberechtigtePrincipalsFailed({ error }));
          })
        )
      )
    );
  });

  addAdministrator$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.addAdministrator),
      mergeMap(({ administrator }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.WaitMessage',
            {
              displayName: administrator.displayName,
            }
          ) as string
        );
        return this.securityService.addAdministrator(administrator).pipe(
          switchMap(administratoren => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.SucceededMessage',
                {
                  displayName: administrator.displayName,
                }
              ) as string
            );
            // Refactor 2024
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(
              securityActions.requestLoadPrincipals({ principalIds: [administrator.id] })
            );
            return [
              securityActions.addAdministratorSucceeded({
                administratoren: administratoren,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Hinzufuegen.FailedMessage',
                {
                  displayName: administrator.displayName,
                }
              ) as string,
              error
            );
            return of(securityActions.addAdministratorFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  removeAdministrator$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.removeAdministratorAndClearFormularBerechtigungen),
      mergeMap(({ administrator }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.WaitMessage',
            {
              displayName: administrator.displayName,
            }
          ) as string
        );
        return this.securityService.removeAdministrator(administrator).pipe(
          switchMap(administratoren => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.SucceededMessage',
                {
                  displayName: administrator.displayName,
                }
              ) as string
            );
            return of(
              securityActions.removeAdministratorSucceeded({
                administratoren: administratoren,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.Administratoren.Benachrichtigungen.Entfernen.FailedMessage',
                {
                  displayName: administrator.displayName,
                }
              ) as string,
              error
            );
            return [
              securityActions.removeAdministratorFailed({ error }),
              securityActions.loadAdministratoren(),
            ];
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  addWorkflowErsteller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.addWorkflowErsteller),
      mergeMap(({ workflowErsteller }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.WaitMessage',
            {
              displayName: workflowErsteller.displayName,
            }
          ) as string
        );
        return this.securityService.addWorkflowErsteller(workflowErsteller).pipe(
          switchMap(alleWorkflowErsteller => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.SucceededMessage',
                {
                  displayName: workflowErsteller.displayName,
                }
              ) as string
            );
            // Refactor 2024
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(
              securityActions.requestLoadPrincipals({ principalIds: [workflowErsteller.id] })
            );
            return [
              securityActions.addWorkflowErstellerSucceeded({
                workflowErsteller: alleWorkflowErsteller,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Hinzufuegen.FailedMessage',
                {
                  displayName: workflowErsteller.displayName,
                }
              ) as string,
              error
            );
            return of(securityActions.addWorkflowErstellerFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  removeWorkflowErsteller$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.removeWorkflowErsteller),
      mergeMap(({ workflowErsteller }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.WaitMessage',
            {
              displayName: workflowErsteller.displayName,
            }
          ) as string
        );
        return this.securityService.removeWorkflowErsteller(workflowErsteller).pipe(
          switchMap(alleWorkflowErsteller => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.SucceededMessage',
                {
                  displayName: workflowErsteller.displayName,
                }
              ) as string
            );
            return [
              securityActions.removeWorkflowErstellerSucceeded({
                workflowErsteller: alleWorkflowErsteller,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowErsteller.Benachrichtigungen.Entfernen.FailedMessage',
                {
                  displayName: workflowErsteller.displayName,
                }
              ) as string,
              error
            );
            return of(securityActions.removeWorkflowErstellerFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });
  addWorkflowTeilnehmer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.addWorkflowTeilnehmer),
      mergeMap(({ workflowTeilnehmer }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.WaitMessage',
            {
              displayName: workflowTeilnehmer.displayName,
            }
          ) as string
        );
        return this.securityService.addWorkflowTeilnehmer(workflowTeilnehmer).pipe(
          switchMap(alleWorkflowTeilnehmer => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.SucceededMessage',
                {
                  displayName: workflowTeilnehmer.displayName,
                }
              ) as string
            );
            // Refactor 2024
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(
              securityActions.requestLoadPrincipals({ principalIds: [workflowTeilnehmer.id] })
            );
            return [
              securityActions.addWorkflowTeilnehmerSucceeded({
                workflowTeilnehmer: alleWorkflowTeilnehmer,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Hinzufuegen.FailedMessage',
                {
                  displayName: workflowTeilnehmer.displayName,
                }
              ) as string,
              error
            );
            return of(securityActions.addWorkflowTeilnehmerFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  removeWorkflowTeilnehmer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.removeWorkflowTeilnehmer),
      mergeMap(({ workflowTeilnehmer }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.WaitMessage',
            {
              displayName: workflowTeilnehmer.displayName,
            }
          ) as string
        );
        return this.securityService.removeWorkflowTeilnehmer(workflowTeilnehmer).pipe(
          switchMap(alleWorkflowTeilnehmer => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.SucceededMessage',
                {
                  displayName: workflowTeilnehmer.displayName,
                }
              ) as string
            );
            return [
              securityActions.removeWorkflowTeilnehmerSucceeded({
                workflowTeilnehmer: alleWorkflowTeilnehmer,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.WorkflowTeilnehmer.Benachrichtigungen.Entfernen.FailedMessage',
                {
                  displayName: workflowTeilnehmer.displayName,
                }
              ) as string,
              error
            );
            return of(securityActions.removeWorkflowTeilnehmerFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  addGenerellLeseberechtigtenPrincipal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.addGenerellLeseberechtigtenPrincipal),
      mergeMap(({ generellLeseberechtigtenPrincipal }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.WaitMessage',
            {
              displayName: generellLeseberechtigtenPrincipal.displayName,
            }
          ) as string
        );
        return this.securityService
          .addGenerellLeseberechtigtenPrincipal(generellLeseberechtigtenPrincipal)
          .pipe(
            switchMap(generellLeseberechtigte => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.SucceededMessage',
                  {
                    displayName: generellLeseberechtigtenPrincipal.displayName,
                  }
                ) as string
              );
              // Refactor 2024
              // eslint-disable-next-line @ngrx/no-dispatch-in-effects
              this.store.dispatch(
                securityActions.requestLoadPrincipals({
                  principalIds: [generellLeseberechtigtenPrincipal.id],
                })
              );
              return [
                securityActions.addGenerellLeseberechtigtenPrincipalSucceeded({
                  generellLeseberechtigtePrincipals: generellLeseberechtigte,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Hinzufuegen.FailedMessage',
                  {
                    displayName: generellLeseberechtigtenPrincipal.displayName,
                  }
                ) as string,
                error
              );
              return of(securityActions.addGenerellLeseberechtigtenPrincipalFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  removeGenerellLeseberechtigtenPrincipal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.removeGenerellLeseberechtigtenPrincipal),
      mergeMap(({ generellLeseberechtigtenPrincipal }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.WaitMessage',
            {
              displayName: generellLeseberechtigtenPrincipal.displayName,
            }
          ) as string
        );
        return this.securityService
          .removeGenerellLeseberechtigtenPrincipal(generellLeseberechtigtenPrincipal)
          .pipe(
            switchMap(generellLeseberechtigte => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.SucceededMessage',
                  {
                    displayName: generellLeseberechtigtenPrincipal.displayName,
                  }
                ) as string
              );
              return [
                securityActions.removeGenerellLeseberechtigtenPrincipalSucceeded({
                  generellLeseberechtigtePrincipals: generellLeseberechtigte,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.GenerellLeseberechtigte.Benachrichtigungen.Entfernen.FailedMessage',
                  {
                    displayName: generellLeseberechtigtenPrincipal.displayName,
                  }
                ) as string,
                error
              );
              return of(securityActions.removeGenerellLeseberechtigtenPrincipalFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  checklogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.checkLogin),
      mergeMap(() =>
        this.authService.checkLogin().pipe(
          switchMap((isLoggedIn: boolean) => {
            // Falls checkLogin false war, leitet der global-http-interceptor auf die login route weiter

            return of(
              securityActions.checkLoginSucceeded({
                isLoggedIn,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            const titel = this.translate.instant(
              'Texte.Allgemein.Benachrichtigungen.CheckLogin.FailedTitel'
            ) as string;
            const message = this.translate.instant(
              'Texte.Allgemein.Benachrichtigungen.CheckLogin.FailedMessage'
            ) as string;
            this.toastService.showHttpErrorResponse(
              titel.startsWith('Translation') && titel.endsWith('fehlt')
                ? titel
                : 'Die Anmeldung ist abgelaufen',
              message.startsWith('Translation') && message.endsWith('fehlt')
                ? message
                : 'Die Anmeldung ist abgelaufen, bitte laden Sie die Seite erneut oder wenden Sie sich bei wiederkehrenden Problemen an einen Administrator',
              error
            );
            return of(securityActions.checkLoginFailed({ error }));
          })
        )
      )
    );
  });

  searchForPrincipals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.requestSearchForPrincipals),
      mergeMap(({ searchString, searchType, tenantId }) => {
        return this.securityService.search(searchString, searchType).pipe(
          switchMap(principals => {
            return [
              securityActions.searchForPrincipalsSucceeded({
                results: principals,
                searchString,
                searchType,
                tenantId,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Utilities.PeoplePicker.Benachrichtigungen.FailedSearch.Title'
              ) as string,
              this.translate.instant(
                'Texte.Utilities.PeoplePicker.Benachrichtigungen.FailedSearch.Message',
                {
                  searchString: searchString,
                }
              ) as string,
              error
            );
            return of(securityActions.searchForPrincipalsFailed({ error }));
          })
        );
      })
    );
  });

  /**
   * Einstellungen-effects
   */
  loadEinstellungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.loadEinstellungenUndArbeitsgruppen, securityActions.loadEinstellungen),
      mergeMap(_ =>
        this.securityService.getEinstellungen().pipe(
          switchMap((einstellungen: SecurityEinstellungenModel) =>
            of(securityActions.loadEinstellungenSucceeded({ einstellungen }))
          ),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.LoadEinstellungen.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.loadEinstellungenFailed({ error }));
          })
        )
      )
    );
  });

  speichereMaxWorkflowAdministratoren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.speichereMaxWorkflowAdministratoren),
      mergeMap(({ maxAdminCount }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.WaitMessage'
          ) as string
        );
        return this.securityService.speichereMaxAdminCount(maxAdminCount).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.SucceededMessage'
              ) as string
            );
            return [
              securityActions.speichereMaxWorkflowAdministratorenSucceeded({
                maxAdminCount,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.MaxAdministratoren.Benachrichtigungen.Speichern.FailedMessage'
              ) as string,
              error
            );
            return of(securityActions.speichereMaxWorkflowAdministratorenFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  // Benutzereinstellungen
  speichereTheme$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.speichereTheme),
      switchMap(({ theme }) => {
        return this.store.select(fromStore.selectBenutzereinstellungen).pipe(
          first(),
          switchMap(einstellungen => {
            if (einstellungen.theme === theme) {
              return EMPTY;
            }
            return this.securityService.speichereDesign(theme, einstellungen.darkmodeOn).pipe(
              switchMap(() => {
                return [
                  securityActions.speichereDesignSucceeded({
                    theme,
                    darkmodeOn: einstellungen.darkmodeOn,
                  }),
                ];
              }),
              catchError((error: HttpErrorResponse) => {
                return of(securityActions.speichereDesignFailed({ error }));
              })
            );
          })
        );
      })
    );
  });

  speichereDarkModeOn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.speichereDarkModeOn),
      switchMap(({ darkModeOn }) => {
        return this.store.select(fromStore.selectBenutzereinstellungen).pipe(
          first(),
          switchMap(einstellungen => {
            return this.securityService.speichereDesign(einstellungen.theme, darkModeOn).pipe(
              switchMap(() => {
                return [
                  securityActions.speichereDesignSucceeded({
                    theme: einstellungen.theme,
                    darkmodeOn: darkModeOn,
                  }),
                ];
              }),
              catchError((error: HttpErrorResponse) => {
                return of(securityActions.speichereDesignFailed({ error }));
              })
            );
          })
        );
      })
    );
  });

  speichereThemeUndDarkModeOn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.speichereThemeUndDarkModeOn),
      switchMap(({ theme, darkModeOn }) => {
        return this.securityService.speichereDesign(theme, darkModeOn).pipe(
          switchMap(() => {
            return [
              securityActions.speichereDesignSucceeded({
                theme: theme,
                darkmodeOn: darkModeOn,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(securityActions.speichereDesignFailed({ error }));
          })
        );
      })
    );
  });

  saveAccessibilityModusOn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.saveAccessibilityModusOn),
      mergeMap(({ accessibilityModusOn }) => {
        return this.securityService.saveAccessibilityModusOn(accessibilityModusOn).pipe(
          switchMap(() => [
            securityActions.saveAccessibilityModusOnSucceeded({
              accessibilityModusOn,
            }),
          ])
        );
      })
    );
  });

  saveAnzuzeigendeAufgabentabellenSpalten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.saveAnzuzeigendeAufgabentabellenSpalten),
      mergeMap(({ anzuzeigendeAufgabentabellenSpalten, wirdWiederhergestellt }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            wirdWiederhergestellt
              ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.WaitTitel'
              : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            wirdWiederhergestellt
              ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.WaitMessage'
              : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.WaitMessage'
          ) as string
        );
        return this.securityService
          .saveAnzuzeigendeAufgabentabellenSpalten(anzuzeigendeAufgabentabellenSpalten)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.SucceededTitel'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.SucceededTitel'
                ) as string,
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.SucceededMessage'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.SucceededMessage'
                ) as string
              );
              return [
                securityActions.saveAnzuzeigendeAufgabentabellenSpaltenSucceeded({
                  anzuzeigendeAufgabentabellenSpalten,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.FailedTitel'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.FailedTitel'
                ) as string,
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Wiederherstellen.FailedMessage'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.AufgabenTabelle.Speichern.FailedMessage'
                ) as string,
                error
              );
              return of(securityActions.saveAnzuzeigendeAufgabentabellenSpaltenFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  saveAnzuzeigendeWorkflowtabellenSpalten$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.saveAnzuzeigendeWorkflowtabellenSpalten),
      mergeMap(({ anzuzeigendeWorkflowtabellenSpalten, wirdWiederhergestellt }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            wirdWiederhergestellt
              ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.WaitTitel'
              : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.WaitTitel'
          ) as string,
          this.translate.instant(
            wirdWiederhergestellt
              ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.WaitMessage'
              : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.WaitMessage'
          ) as string
        );
        return this.securityService
          .saveAnzuzeigendeWorkflowtabellenSpalten(anzuzeigendeWorkflowtabellenSpalten)
          .pipe(
            switchMap(() => {
              this.toastService.showSuccess(
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.SucceededTitel'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.SucceededTitel'
                ) as string,
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.SucceededMessage'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.SucceededMessage'
                ) as string
              );
              return [
                securityActions.saveAnzuzeigendeWorkflowtabellenSpaltenSucceeded({
                  anzuzeigendeWorkflowtabellenSpalten,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.FailedTitel'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.FailedTitel'
                ) as string,
                this.translate.instant(
                  wirdWiederhergestellt
                    ? 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Wiederherstellen.FailedMessage'
                    : 'Texte.Benutzereinstellungen.Tabellenanordnung.Benachrichtigungen.WorkflowTabelle.Speichern.FailedMessage'
                ) as string,
                error
              );
              return of(securityActions.saveAnzuzeigendeAufgabentabellenSpaltenFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  getMaxWorkflowAdministratoren$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.getMaxWorkflowAdministratoren),
      mergeMap(_ => {
        return this.securityService.getMaxWorkflowAdministratoren().pipe(
          switchMap(maxWorkflowAdministratoren => {
            return [
              securityActions.getMaxWorkflowAdministratorenSucceeded({
                maxWorkflowAdministratoren,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(securityActions.getMaxWorkflowAdministratorenFailed({ error }));
          })
        );
      })
    );
  });

  loadArbeitsgruppen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commonActions.loadEinstellungenUndArbeitsgruppen, securityActions.loadArbeitsgruppen),
      mergeMap(_ => {
        return this.securityService.loadArbeitsgruppen().pipe(
          switchMap(arbeitsgruppen => {
            return [
              securityActions.loadArbeitsgruppenSucceeded({
                arbeitsgruppen,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(securityActions.loadArbeitsgruppenFailed({ error }));
          })
        );
      })
    );
  });

  loescheArbeitsgruppe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.loescheArbeitsgruppe),
      mergeMap(({ id, name }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.WaitMessage', {
            name,
          }) as string
        );
        return this.securityService.loescheArbeitsgruppe(id).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.SucceededMessage',
                {
                  name,
                }
              ) as string
            );
            return [
              securityActions.loescheArbeitsgruppeSucceeded({
                id: id,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Loeschen.FailedMessage',
                {
                  name,
                }
              ) as string,
              error
            );
            return of(securityActions.loescheArbeitsgruppeFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  speichereArbeitsgruppe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.speichereArbeitsgruppe),
      mergeMap(({ arbeitsgruppe }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.WaitTitel'
          ) as string,
          this.translate.instant('Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.WaitMessage', {
            name: arbeitsgruppe.name,
          }) as string
        );
        return this.securityService.speichereArbeitsgruppe(arbeitsgruppe).pipe(
          switchMap(arbeitsGruppeReturnValue => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.SucceededMessage',
                {
                  name: arbeitsgruppe.name,
                }
              ) as string
            );
            this.router.navigateByUrl(
              `${this.tenantService.currentTenant.urlParameter}/zusammenarbeit`
            );
            return [
              securityActions.speichereArbeitsgruppeSucceeded({
                arbeitsgruppe: arbeitsGruppeReturnValue,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Arbeitsgruppen.Benachrichtigungen.Speichern.FailedMessage',
                {
                  name: arbeitsgruppe.name,
                }
              ) as string,
              error
            );
            return of(securityActions.speichereArbeitsgruppeFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  loadServiceConnections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.ServiceConnectionActions.load),
      mergeMap(_ =>
        this.securityService.loadServiceConnections().pipe(
          switchMap(serviceConnections => {
            return [
              securityActions.ServiceConnectionActions.loadSucceeded({
                serviceConnections: serviceConnections,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            return of(securityActions.ServiceConnectionActions.loadFailed({ error }));
          })
        )
      )
    );
  });

  erstelleServiceConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.ServiceConnectionActions.erstelle),
      mergeMap(action => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Erstellen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Erstellen.WaitMessage'
          ) as string
        );
        return this.securityService
          .upsertServiceConnection(action.serviceConnection, action.tenantId)
          .pipe(
            filter(event => event.type === HttpEventType.Response),
            switchMap(event => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Erstellen.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Erstellen.SucceededMessage',
                  { name: (event as HttpResponse<ServiceConnection>).body.name }
                ) as string
              );
              return [
                securityActions.ServiceConnectionActions.erstelleSucceeded({
                  serviceConnection: (event as HttpResponse<ServiceConnection>).body,
                }),
                securityActions.ServiceConnectionActions.load(),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Erstellen.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Erstellen.FailedMessage'
                ) as string,
                error
              );
              return of(securityActions.ServiceConnectionActions.erstelleFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  updateServiceConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.ServiceConnectionActions.update),
      mergeMap(action => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Update.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Update.WaitMessage'
          ) as string
        );
        return this.securityService
          .upsertServiceConnection(action.serviceConnection, action.tenantId)
          .pipe(
            filter(event => event.type === HttpEventType.Response),
            switchMap(event => {
              this.toastService.showSuccess(
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Update.SucceededTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Update.SucceededMessage',
                  { name: (event as HttpResponse<ServiceConnection>).body.name }
                ) as string
              );
              return [
                securityActions.ServiceConnectionActions.updateSucceeded({
                  serviceConnection: (event as HttpResponse<ServiceConnection>).body,
                }),
                securityActions.ServiceConnectionActions.load(),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.toastService.showHttpErrorResponse(
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Update.FailedTitel'
                ) as string,
                this.translate.instant(
                  'Texte.Grundeinstellungen.ServiceConnections.Update.FailedMessage'
                ) as string,
                error
              );

              return of(securityActions.ServiceConnectionActions.updateFailed({ error }));
            }),
            finalize(() => {
              this.toastService.removeToast(toast);
            })
          );
      })
    );
  });

  deleteServiceConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(securityActions.ServiceConnectionActions.delete),
      mergeMap(action => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Delete.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.Grundeinstellungen.ServiceConnections.Delete.WaitMessage'
          ) as string
        );
        return this.securityService.deleteServiceConnection(action.serviceConnection).pipe(
          switchMap(id => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.Grundeinstellungen.ServiceConnections.Delete.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.ServiceConnections.Delete.SucceededMessage',
                { name: action.serviceConnection.name }
              ) as string
            );
            return [
              securityActions.ServiceConnectionActions.deleteSucceeded({
                serviceConnectionId: id,
              }),
              securityActions.ServiceConnectionActions.load(),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showHttpErrorResponse(
              this.translate.instant(
                'Texte.Grundeinstellungen.ServiceConnections.Delete.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Grundeinstellungen.ServiceConnections.Delete.FailedMessage'
              ) as string,
              error
            );

            return of(securityActions.ServiceConnectionActions.deleteFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });
}

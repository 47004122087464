import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TenantModel } from '@dworkflow/shared/model/security';
import { AuthenticationService } from '@dworkflow/shared/services/authentication.service';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import * as fromEngineState from '@dworkflow/state/engine.state';
import * as fromSchrittState from '@dworkflow/state/schritt.state';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const MaxBadgeAnzahl = 99;

@Component({
  selector: 'dworkflow-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
  standalone: true,
  imports: [
    MatToolbarModule,
    NgTemplateOutlet,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    RouterLinkActive,
    RouterLink,
    NgStyle,
    NgClass,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatDividerModule,
    MatBadgeModule,
    MatMenuModule,
    MatListModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  navigationCollapsed = true;
  currentUser$ = this.securityService.getCurrentUser();
  sechseckLogoPath = './assets/images/dworkflow_sechseck.svg';
  workflowCount$ = this.store.select(fromEngineState.selectAlleWorkflowsCount);
  meineAufgabenCount$ = this.store.select(fromSchrittState.selectMeineAufgabenAnzahl());
  backgroundUrl: string;
  subscriptions: Subscription[] = [];
  erstellerTenants$: Observable<TenantModel[]>;
  filteredTenants$ = new BehaviorSubject<TenantModel[]>([]);
  erstellerTenants: TenantModel[];

  @ViewChild('tenantSelect') tenantSelect: MatSelect;
  router = inject(Router);

  constructor(
    private translate: TranslateService,
    public store: Store,
    private securityService: SecurityService,
    private element: ElementRef,
    public tenantService: TenantService,
    private authenticationService: AuthenticationService
  ) {
    this.erstellerTenants$ = tenantService.erstellerTenants$.pipe(
      tap(e => {
        this.erstellerTenants = e;
        if (
          tenantService.currentTenant?.id > 0 &&
          !e.some(t => t.id === tenantService.currentTenant?.id)
        ) {
          e.splice(0, 0, tenantService.currentTenant);
        }
        this.sortErstellertenants();
        this.filteredTenants$.next(this.erstellerTenants);
      })
    );
  }

  sortErstellertenants(): void {
    this.erstellerTenants = this.erstellerTenants.filter(
      t => t.id !== this.tenantService.currentTenant.id
    );

    if (this.tenantService.currentTenant?.id > 0) {
      this.erstellerTenants.splice(0, 0, this.tenantService.currentTenant);
    }
  }

  ngAfterViewInit(): void {
    this.setAriaHiddenForBadgeContent();
    const sub = this.tenantSelect?.openedChange?.subscribe((isOpen: boolean) => {
      if (isOpen) {
        const panel = this.tenantSelect.panel.nativeElement as HTMLDivElement;
        panel.scrollTop = 0;
      }
    });
    if (sub) {
      this.subscriptions.push(sub);
    }
    this.subscriptions.push(
      this.tenantService.currentTenant$.subscribe(tenant => {
        this.sortErstellertenants();
        this.filteredTenants$.next(this.erstellerTenants);
        this.securityService.requestLoadVertreterUndVorgesetzte();
        setTimeout(() => {
          if (tenant?.logo) {
            this.backgroundUrl = 'url(' + tenant.logo + ')';
          } else {
            this.backgroundUrl = "url('" + this.sechseckLogoPath + "')";
          }
        }, 100);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  showCurrentTenantInOptions(erstellerTenants: TenantModel[]): boolean {
    if (!this.tenantService.currentTenant?.id || this.tenantService.currentTenant?.id === 0) {
      return false;
    }

    return !erstellerTenants.some(t => t.id === this.tenantService.currentTenant?.id);
  }

  toggleNavigation(): void {
    this.navigationCollapsed = !this.navigationCollapsed;
  }

  closeNavigation(): void {
    this.navigationCollapsed = true;
  }

  hasVertreter(): Observable<boolean> {
    return this.securityService.anyVertreterOrVorgesetzter();
  }

  getGrundeinstellungenLink(): string {
    if (this.tenantService.currentTenant.id === 0) {
      return this.getRouterLinkUrlForCurrentTenant('management');
    }
    return this.getRouterLinkUrlForCurrentTenant('grundeinstellungen');
  }

  getZusammenarbeitAriaLabel(): Observable<string> {
    return this.hasVertreter().pipe(
      map(hasVertreter =>
        hasVertreter
          ? (this.translate.instant('Texte.Navigation.NavigiereZu', {
              seitentitel: this.translate.instant('Texte.Navigation.Zusammenarbeit') as string,
            }) as string)
          : (this.translate.instant('Texte.Navigation.NavigiereZu', {
              seitentitel: this.translate.instant(
                'Texte.Navigation.ZusammenarbeitBeiKeineVertretungEingetragen'
              ) as string,
            }) as string)
      )
    );
  }

  filterTenants(event: KeyboardEvent, erstellerTenants: TenantModel[]): void {
    this.filteredTenants$.next(
      erstellerTenants.filter(
        tenant =>
          tenant.name
            .toLocaleLowerCase()
            .indexOf((event.target as HTMLInputElement).value.toLocaleLowerCase()) > -1
      )
    );
  }

  getRouterLinkUrlForCurrentTenant(routerLink: string = ''): string {
    if (!this.tenantService.currentTenant || (this.tenantService.currentTenant?.id ?? 0) === 0) {
      return routerLink;
    }

    return routerLink === ''
      ? this.tenantService.currentTenant.urlParameter
      : this.tenantService.currentTenant.urlParameter + '/' + routerLink;
  }

  private setAriaHiddenForBadgeContent(): void {
    // Damit nicht permanent die Zahl aus dem Badge vorgelesen wird, wenn sich die Zahl der
    // Workflows oder Aufgaben ändert. Die Information ist schon in dem Aria-Label für
    // die Buttons enthalten

    const badgeElements = (this.element.nativeElement as HTMLElement).getElementsByClassName(
      'mat-badge-content'
    );

    for (let i = 0; i < badgeElements.length; i++) {
      badgeElements[i].setAttribute('aria-hidden', 'true');
    }
  }

  trackTenantBy(_index: number, tenant: TenantModel): number {
    return tenant.id;
  }

  getBadgeValue(anzahl: number): string {
    if (anzahl > MaxBadgeAnzahl) {
      return `${MaxBadgeAnzahl}+`;
    }

    return anzahl.toString();
  }

  logout(): void {
    this.authenticationService.logout();
  }
}

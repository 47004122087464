import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// Guard fuer Routen, die nur von FachAdmins oder SysAdmins geoeffnet werden duerfen
export class CanActivateSysadminGuard {
  constructor(private securityService: SecurityService, private tenantService: TenantService) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.securityService.getCurrentUser().pipe(
      map(user => {
        if (user.isSystemAdmin) {
          return true;
        }

        this.tenantService.redirectForbidden();

        return false;
      })
    );
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(CanActivateSysadminGuard).canActivate(next, state);
};

import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostListener, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { DokumentUploadStatus } from '@dworkflow/shared//model/dokumente/dokument-upload-status.enum';
import { LoadingComponent } from '@dworkflow/shared/components/loading/loading.component';
import { VorlageModel } from '@dworkflow/shared/model';
import { DokumentModel } from '@dworkflow/shared/model/dokumente/dokument.model';
import { AbkuerzungPipe } from '@dworkflow/shared/pipes';
import { DokumenteService } from '@dworkflow/shared/services/dokumente.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { guid } from '@dworkflow/shared/utility/guid';
import * as fromDokumentState from '@dworkflow/state/dokumente.state';
import { DokumentFile } from '@dworkflow/state/dokumente.state';
import * as grundeinstellungenState from '@dworkflow/state/grundeinstellungen.state';
import * as wfTabelleState from '@dworkflow/state/workflow-tabelle.state';
import { WorkflowFuerTabelle } from '@dworkflow/workflow/workflow-tabelle/workflow-fuer-tabelle';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, firstValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'dworkflow-dokumente-upload-progress-notification-area',
  templateUrl: './dokumente-upload-progress-notification-area.component.html',
  styleUrls: ['./dokumente-upload-progress-notification-area.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    AsyncPipe,
    TranslateModule,
    RouterModule,
    AbkuerzungPipe,
    LoadingComponent
],
})
export class DokumentenUploadProgressNotificationComponent {
  isCollapsed = false;
  store = inject(Store);
  DokumentUploadStatus = DokumentUploadStatus;
  tenantService = inject(TenantService);

  workflowsMitUploads$ = this.dokumenteService.getUploadingDokumenteWithFiles().pipe(
    // Dokumente auf Dictionary mit WorkflowId als Key und Dokumente als Value mappen
    map(dokumente => {
      dokumente = dokumente.filter(d => d !== undefined);
      return dokumente.reduce(
        (workflowDokumentLookup, dokumentWithFile) => ({
          ...workflowDokumentLookup,
          [dokumentWithFile.dokument?.workflowId]: workflowDokumentLookup[
            dokumentWithFile.dokument?.workflowId
          ]
            ? [...workflowDokumentLookup[dokumentWithFile.dokument?.workflowId], dokumentWithFile]
            : [dokumentWithFile],
        }),
        {} as {
          [workflowOderVorlagenId: string]: {
            file: fromDokumentState.DokumentFile;
            dokument: DokumentModel;
          }[];
        }
      );
    }),
    // Workflows nach status und Dokumente nach Progress sortieren und zu einer Liste konvertieren
    map(workflowLookup =>
      Object.keys(workflowLookup)
        .map(
          key =>
            new WorkflowOderVorlage(
              key as guid,
              workflowLookup[key].sort((d, d2) => d2.file.progress - d.file.progress)
            )
        )
        .sort(
          (wf, wf2) =>
            (wf2.dokumente.every(
              d => d.dokument.status === DokumentUploadStatus.Uploaded
            ) as unknown as number) -
            (wf.dokumente.every(
              d => d.dokument.status === DokumentUploadStatus.Uploaded
            ) as unknown as number)
        )
    )
  );

  vorlagenMitUploads$ = this.dokumenteService.getUploadingVorlagenDokumenteWithFiles().pipe(
    // Dokumente auf Dictionary mit VorlagenId als Key und Dokumente als Value mappen
    map(dokumente => {
      dokumente = dokumente.filter(d => d !== undefined);
      return dokumente.reduce(
        (vorlagenDokumentLookup, dokumentWithFile) => ({
          ...vorlagenDokumentLookup,
          [dokumentWithFile.dokument?.vorlagenId]: vorlagenDokumentLookup[
            dokumentWithFile.dokument?.vorlagenId
          ]
            ? [...vorlagenDokumentLookup[dokumentWithFile.dokument?.vorlagenId], dokumentWithFile]
            : [dokumentWithFile],
        }),
        {} as {
          [workflowOderVorlagenId: string]: {
            file: fromDokumentState.DokumentFile;
            dokument: DokumentModel;
          }[];
        }
      );
    }),
    // Vorlagen nach status und Dokumente nach Progress sortieren und zu einer Liste konvertieren
    map(vorlagenLookup =>
      Object.keys(vorlagenLookup)
        .map(
          key =>
            new WorkflowOderVorlage(
              key as guid,
              vorlagenLookup[key].sort((d, d2) => d2.file.progress - d.file.progress)
            )
        )
        .sort(
          (wf, wf2) =>
            (wf2.dokumente.every(
              d => d.dokument.status === DokumentUploadStatus.Uploaded
            ) as unknown as number) -
            (wf.dokumente.every(
              d => d.dokument.status === DokumentUploadStatus.Uploaded
            ) as unknown as number)
        )
    )
  );

  uploads$ = combineLatest([this.vorlagenMitUploads$, this.workflowsMitUploads$]).pipe(
    map(([vorlagenMitUploads, workflowsMitUploads]) => {
      vorlagenMitUploads = vorlagenMitUploads.map(v => {
        v.isWorkflow = false;
        return v;
      });
      workflowsMitUploads = workflowsMitUploads.map(v => {
        v.isWorkflow = true;
        return v;
      });
      return vorlagenMitUploads.concat(workflowsMitUploads);
    })
  );

  allFinished$ = this.uploads$.pipe(
    map(uploads =>
      uploads.every(upload =>
        upload.dokumente.every(
          d => !!d.dokument && d.dokument.status === DokumentUploadStatus.Uploaded
        )
      )
    )
  );

  constructor(private dokumenteService: DokumenteService, private translate: TranslateService) {}

  removeFertigenWorkflowOderVorlage(id: string): void {
    this.uploads$
      .pipe(
        first(),
        map(wfs =>
          wfs
            .find(wf => wf.workflowOderVorlagenId === id)
            .dokumente.map(dokument => {
              return {
                dokumentenId: dokument.dokument.dokumentenId,
                dokumentenName: dokument.dokument.name,
              } as { dokumentenId: guid; dokumentenName: string };
            })
        )
      )
      .subscribe((dokumente: { dokumentenId: guid; dokumentenName: string }[]) => {
        for (const dok of dokumente) {
          this.dokumenteService.removeUploadedDokument(dok.dokumentenId);
        }
      });
  }

  allUploadsFinished(workflowOderVorlage: WorkflowOderVorlage): boolean {
    return workflowOderVorlage.dokumente.every(
      d => d.dokument?.status === DokumentUploadStatus.Uploaded
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  async canDeactivate($event: BeforeUnloadEvent): Promise<boolean> {
    const allUploaded = await firstValueFrom(
      this.dokumenteService
        .getUploadingDokumenteWithFiles()
        .pipe(
          map(
            dokumente =>
              !dokumente
                .filter(d => !!d)
                .some(
                  d =>
                    d.dokument.status === DokumentUploadStatus.UploadInProgress ||
                    d.dokument.status === DokumentUploadStatus.Queued
                )
          )
        )
    );

    if (!allUploaded) {
      if ($event) {
        $event.returnValue = this.translate.instant(
          'Texte.Dokumente.NotificationArea.ExitWarning'
        ) as string;
      } else {
        return confirm(
          this.translate.instant('Texte.Dokumente.NotificationArea.ExitWarning') as string
        );
      }
    }
  }

  trackWorkflowOderVorlageBy(_index: number, workflowOderVorlage: WorkflowOderVorlage): guid {
    return workflowOderVorlage.workflowOderVorlagenId;
  }

  trackDokumentBy(_index: number, dokument: { file: DokumentFile; dokument: DokumentModel }): guid {
    return dokument.dokument.dokumentenId;
  }

  getVorlagenSub(vorlagenId: guid): Observable<VorlageModel> {
    return this.store.select(grundeinstellungenState.selectVorlageById(vorlagenId));
  }

  getWorkflowSub(workflowId: guid): Observable<WorkflowFuerTabelle> {
    return this.store.select(wfTabelleState.selectWorkflowsFuerTabelleByWorkflowId(workflowId));
  }
}

export class WorkflowOderVorlage {
  public workflowOderVorlagenId: guid;
  public isWorkflow: boolean;
  public dokumente: { file: DokumentFile; dokument: DokumentModel }[];
  public constructor(
    workflowOderVorlagenId: guid,
    dokumente: { file: DokumentFile; dokument: DokumentModel }[]
  ) {
    this.dokumente = dokumente;
    this.workflowOderVorlagenId = workflowOderVorlagenId;
  }
}

import { Injectable } from '@angular/core';
import * as fromStore from '@dworkflow/state/accessibility.state';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
// Der Mutator stellt sicher, dass aria-describedby-ids für ausgeblendete Fehler eine Referenz haben.
// Da die Manipulation des aria-describedby-Attributs nicht klappt, weil irgendwas (vermutlich bei der Validierung der Form)
// es immer wieder überschreibt, ist hier unsere Lösung, einfach ein leeres span ans Ende des Bodys zu hängen,
// solange kein anderes Element mit der Id existiert, und wenn es hinzukommt, das leere span dann wieder zu entfernen.
// Der Mutator wird in der app.component.ts eingebunden, damit nur ein Mutator in der Anwendung den document.Body manipuliert.
// Die Manipulation direkt in der EnsureDescribedbyReferencesDirective hat große Performanceeinbußen
// (insbesondere in der Vorlagenkonfiguration-edit-Component) erzeugt.
@Injectable({
  providedIn: 'root',
})
export class AriaDescribedByMutator {
  ariaDescribedByIdsSelector$: Observable<string[]> = this.store.select(
    fromStore.selectAriaDescribedByIds
  );
  ariaDescribedByIds: string[];
  emptySpanElements: { [id: string]: HTMLElement } = {};
  subscription: Subscription;
  mutationObserver: MutationObserver;

  constructor(private store: Store) {
    this.subscription = this.ariaDescribedByIdsSelector$.subscribe(ids => {
      this.ariaDescribedByIds = ids;
    });
    this.mutationObserver = new MutationObserver(mutationRecords => {
      if (mutationRecords.every(r => !r.removedNodes && !r.addedNodes)) {
        return;
      }

      this.ensureElementsForAriaDescribedByIdsInDom();
    });

    this.mutationObserver.observe(document.body, {
      attributes: false,
      childList: true,
      subtree: true,
    });
  }

  public destroy(): void {
    this.subscription.unsubscribe();
    this.mutationObserver.disconnect();
  }

  private ensureElementsForAriaDescribedByIdsInDom(): void {
    this.ariaDescribedByIds?.forEach(id => {
      if (!this.emptySpanElements[id]) {
        this.checkCreateNewEmptySpan(id);
      } else {
        this.checkDestroyExistingEmptySpan(id);
      }
    });

    this.removeEmptySpanElementsNotInAriaDescribedByIds();
  }

  private checkCreateNewEmptySpan(id: string): void {
    const elementById = document.getElementById(id);
    if (!elementById) {
      const emptyElement = document.createElement('span');
      emptyElement.id = id;
      this.emptySpanElements[id] = document.body.appendChild(emptyElement);
    }
  }

  private checkDestroyExistingEmptySpan(id: string): void {
    const selector = `#${id}`;
    const elementsById = document.querySelectorAll(selector);

    if (elementsById.length > 1) {
      this.emptySpanElements[id].remove();
      this.emptySpanElements[id] = null;
    }
  }

  private removeEmptySpanElementsNotInAriaDescribedByIds(): void {
    const newEmptySpanElements: { [id: string]: HTMLElement } = {};
    for (const id in this.emptySpanElements) {
      if (this.ariaDescribedByIds?.indexOf(id) > -1) {
        this.emptySpanElements[id]?.remove();
        newEmptySpanElements[id] = this.emptySpanElements[id];
      }
    }
    this.emptySpanElements = newEmptySpanElements;
  }
}

import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'dworkflow-formly-field-input-in-sentence',
    template: `<mat-form-field floatLabel="always">
    <input
      matInput
      [type]="props.type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [required]="props.required"
      [readonly]="props.readonly"
      [minlength]="props.minLength"
      [maxlength]="props.maxLength"
    />
    <mat-label>{{ props.label }}</mat-label>
    <span matPrefix>{{ props.textBefore }}</span>
    <span matSuffix>{{ props.textAfter }}</span>
    <mat-hint>{{ props.description }}</mat-hint>
  </mat-form-field>`,
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
    ],
})
export class InputInSentenceFieldTypeComponent extends FieldType<FieldTypeConfig> {
  get type(): string {
    return this.props.type || 'text';
  }
}

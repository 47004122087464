import { EnvironmentInjector, Injectable } from '@angular/core';
import { SecurityService } from '@dworkflow/shared/services/security.service';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { RouterStateUrl } from '@dworkflow/shared/utility/custom-serializer';
import { GuidHelper, guid } from '@dworkflow/shared/utility/guid';
import * as commonActions from '@dworkflow/state/common.actions';
import * as engineActions from '@dworkflow/state/engine.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, first, tap } from 'rxjs/operators';
import { InactivityService } from '../shared/services/inactivity.service';
import { SchrittService } from '../shared/services/schritt.service';
import { WorkflowService } from '../shared/services/workflow.service';
import { DataInterval } from '../shared/utility/data-interval';

const WORKFLOW_DETAIL_URL_REGEX =
  /\/workflows\/[a-fA-F0-9-]{36}(\/verlauf|\/faelligkeiten)?(#.*)?$/;
const WORKFLOW_PROTOKOLL_URL_REGEX = /\/workflows\/[a-fA-F0-9-]{36}(\/protokoll)?(#.*)?$/;

@Injectable({
  providedIn: 'root',
})
export class RoutingEffects {
  currentIntervals: DataInterval[] = [];

  constructor(
    private actions$: Actions,
    private inactivityService: InactivityService,
    private store: Store,
    private workflowService: WorkflowService,
    private schrittService: SchrittService,
    private securityService: SecurityService,
    private tenantService: TenantService,
    private injector: EnvironmentInjector
  ) {}

  navigationEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        tap(() => {
          this.clearCurrentInterval();
        })
      );
    },
    { dispatch: false }
  );

  navigated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(({ payload }: RouterNavigatedAction<RouterStateUrl>) => {
          const { routerState } = payload;

          const anzahlAnzuzeigenderAufgaben = 5;

          this.clearCurrentInterval();

          this.tenantService.currentTenant$.pipe(first()).subscribe(tenant => {
            const url = (
              tenant?.id == 0 ? routerState.url : routerState.url.replace(/^\/[^/]+/, '')
            ).replace(/#.*/, '');
            switch (url) {
              case '/home':
              case '/':
              case '':
                // Lade die Workflows und die aktiven Schritte, damit zu jedem Workflow der aktive Schritt angezeigt werden kann
                this.registerLoadMeineWorkflowsFuerStartseite();

                // Lade außerdem die Aufgaben des Users die aktiv oder geplant sind, für die Aufgabentabelle
                this.registerLoadMeineAufgaben(anzahlAnzuzeigenderAufgaben);
                this.registerLoadAlleWorkflows();
                // Polling für die Aufgabenanzahl soll auf der Startseite an sein
                break;
              case '/aufgaben':
              case '/meineAufgaben':
                this.registerLoadMeineAufgaben();
                this.registerLoadAlleWorkflows();
                break;
              // Meine Workflows Tabelle. Die 0 steht für den ersten Tab der TabComponent
              case '/workflows/eigene':
              case '/workflows':
                // Lade die Workflows und die aktiven Schritte, damit zu jedem Workflow der aktive Schritt angezeigt werden kann
                this.registerLoadMeineWorkflows();
                break;
              // Alle Workflows Tabelle. Die 1 steht für den zweiten Tab der TabComponent
              case '/workflows/alle':
                // Lade die Workflows und die aktiven Schritte, damit zu jedem Workflow der aktive Schritt angezeigt werden kann
                this.registerLoadAlleWorkflows();
                break;
              default:
                break;
            }

            this.tenantService.currentTenant$
              .pipe(first(currentTenant => currentTenant !== undefined))
              .subscribe(currentTenant => {
                if (currentTenant.id > 0) {
                  this.registerLoadAlleWorkflowsUndMeineAufgabenAnzahl();
                }
              });

            // Workflow Detail Seiten
            if (routerState.params.workflowId) {
              const workflowId = GuidHelper.stringToGuid(routerState.params.workflowId as string);
              if (WORKFLOW_DETAIL_URL_REGEX.exec(routerState.url)) {
                this.registerLoadWorkflowDataInterval(workflowId);
              } else if (WORKFLOW_PROTOKOLL_URL_REGEX.exec(routerState.url)) {
                this.registerLoadWorkflowProtokollInterval(workflowId);
              }
            }
          });
        })
      );
    },
    { dispatch: false }
  );

  private registerLoadWorkflowDataInterval(workflowId: guid): void {
    this.registerAuthorizedInterval(() => {
      this.store.dispatch(engineActions.loadWorkflow({ workflowId }));
    }, 5000);
  }

  private registerLoadMeineWorkflows(): void {
    this.registerAuthorizedInterval(() => {
      this.workflowService.loadMeineWorkflows();
    }, 20000);
  }

  private registerLoadMeineWorkflowsFuerStartseite(): void {
    this.registerAuthorizedInterval(() => {
      this.workflowService.loadMeineWorkflowsFuerStartseite();
    }, 20000);
  }

  private registerLoadAlleWorkflowsUndMeineAufgabenAnzahl(): void {
    this.registerAuthorizedInterval(() => {
      this.store.dispatch(commonActions.loadAnzahlen());
    }, 20000);
  }

  private registerLoadAlleWorkflows(): void {
    this.registerAuthorizedInterval(() => {
      this.workflowService.loadAlleWorkflows();
    }, 20000);
  }

  private registerLoadMeineAufgaben(anzahl?: number): void {
    this.registerAuthorizedInterval(() => {
      this.schrittService.loadMeineAufgaben(anzahl);
    }, 20000);
  }

  private registerLoadWorkflowProtokollInterval(workflowId: guid): void {
    this.registerAuthorizedInterval(() => {
      this.workflowService.loadWorkflowProtokoll(workflowId);
    }, 20000);
  }

  private registerAuthorizedInterval(action: () => void, baseTimeout: number): void {
    this.registerNewDataInterval(
      () =>
        this.securityService
          .isLoggedIn()
          .pipe(filter(isLoggedIn => isLoggedIn))
          .subscribe(_ => action()),
      baseTimeout
    );
  }

  private registerNewDataInterval(action: () => void, baseTimeout: number): void {
    this.currentIntervals.push(
      new DataInterval(this.inactivityService, action, baseTimeout, this.injector)
    );
  }

  private clearCurrentInterval(): void {
    if (this.currentIntervals) {
      this.currentIntervals.map(i => i.clear());
    }
    this.currentIntervals = [];
  }
}

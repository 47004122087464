import { Injectable, WritableSignal, signal } from '@angular/core';
import { SecurityService } from '@dworkflow/shared/services/security.service';

/**
 * The inactivity is calculated like this:
 * If the user is active is calculated over his mouse / keyboard actions
 * To increase performance, handlers are added after a certain timeout and removed if the user does an action
 *
 * 1. Add handlers after setSoftIdleAfter / 2
 * 1.1 Add another handler that sets the user softIdle after setSoftIdleAfter / 2
 * 1.2 Add another handler that sets the user idle after  setIdleAfter
 * 2. When the user is set softIdle the idleness incrementation starts
 * 2.1 With increasing idleness the service provides a growing margin for requests
 * 2.2 Idleness is increased every setSoftIdleAfter / 2 milliseconds
 * 3. The resulting margin is calculated via 1.25 * Math.pow(this.inactivityFactor, 2) * 1000;
 * *. When user does an action detach handlers and set him active again and reset everything
 *
 */
@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private _userSoftIdle = false;
  private setIdleAfter = 1 * 1000 * 165;
  private setSoftIdleAfter = 1 * 1000 * 30;
  private checkTimer: number;
  private checkSoftTimer: number;
  private inactivitySummandTimer: number;
  private inactivityFactor = 0;

  constructor(private securityService: SecurityService) {
    this.init();
  }

  public isUserActive: WritableSignal<boolean> = signal(true);

  set userSoftIdle(isSoftIdle: boolean) {
    this._userSoftIdle = isSoftIdle;
  }

  get userSoftIdle(): boolean {
    return this._userSoftIdle;
  }

  set userActive(isActive: boolean) {
    this.isUserActive.update(() => isActive);
    if (isActive) {
      this.securityService.checkLogin();
    }
  }

  get userActive(): boolean {
    return this.isUserActive();
  }

  get inactivitySummand(): number {
    return 1.25 * Math.pow(this.inactivityFactor, 2) * 1000;
  }

  private handleEvent = (): void => {
    this.detachEvents();
    this.setUserActive();
    window.clearTimeout(this.checkTimer);
    window.clearTimeout(this.checkSoftTimer);
    window.setTimeout(this.attachEvents, this.setSoftIdleAfter / 2);
  };

  private attachEvents = (): void => {
    document.addEventListener('click', this.handleEvent);
    document.addEventListener('mousemove', this.handleEvent);
    document.addEventListener('keypress', this.handleEvent);
    this.checkTimer = window.setTimeout(this.setUserIdle, this.setIdleAfter);
    this.checkSoftTimer = window.setTimeout(this.setUserSoftIdle, this.setSoftIdleAfter / 2);
  };

  private detachEvents = (): void => {
    document.removeEventListener('click', this.handleEvent);
    document.removeEventListener('mousemove', this.handleEvent);
    document.removeEventListener('keypress', this.handleEvent);
  };

  private init(): void {
    this.handleEvent();
  }

  private setUserActive(): void {
    this.resetTimeoutSummand();
    if (this.userSoftIdle) {
      this.userSoftIdle = false;
    }

    this.userActive = true;
  }

  private setUserSoftIdle = (): void => {
    if (!this.userSoftIdle) {
      this.incrementTimeoutSummand();
      this.userSoftIdle = true;
    }
  };

  private resetTimeoutSummand(): void {
    window.clearTimeout(this.inactivitySummandTimer);
    this.inactivityFactor = 0;
  }

  private incrementTimeoutSummand = (): void => {
    this.inactivityFactor++;
    this.inactivitySummandTimer = window.setTimeout(
      this.incrementTimeoutSummand,
      this.setSoftIdleAfter / 2
    );
  };

  private setUserIdle = (): void => {
    this.userActive = false;
  };
}

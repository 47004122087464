import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
    selector: 'dworkflow-formly-field-paragraph',
    template: `<p [style]="props.style" [id]="id">{{ props.text }}</p>`,
    standalone: true,
})
export class ParagraphFieldTypeComponent extends FieldType<FieldTypeConfig> {
  get type(): string {
    return this.props.type || 'text';
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@dworkflow/shared/services/toast.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap } from 'rxjs/operators';
import { PrincipalModel } from '../shared/model/security/principal.model';
import { VertreterServiceInternal } from '../shared/services/vertreter.service.internal';
import * as VertreterActions from './vertreter.actions';

@Injectable({
  providedIn: 'root',
})
export class VertreterEffects {
  constructor(
    private actions$: Actions,
    private vertreterService: VertreterServiceInternal,
    private translate: TranslateService,
    private toastService: ToastService,
    private store: Store
  ) {}

  loadVertreter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.loadVertreter, VertreterActions.loadVertreterUndVorgesetzte),
      mergeMap(() =>
        this.vertreterService.getVertreter().pipe(
          switchMap((vertreter: PrincipalModel[]) => {
            return of(
              VertreterActions.loadVertreterSucceeded({
                vertreter,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(VertreterActions.loadVertreterFailed({ error }));
          })
        )
      )
    );
  });

  addVertreter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.addVertreter),
      mergeMap(({ vertreter }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.WaitMessage',
            {
              displayName: vertreter.displayName,
            }
          ) as string
        );
        return this.vertreterService.vertreterHinzufuegen(vertreter).pipe(
          switchMap((addedVertreter: PrincipalModel) => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.SucceededMessage',
                {
                  displayName: vertreter.displayName,
                }
              ) as string
            );
            // Refactor 2024
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            setTimeout(() => this.store.dispatch(VertreterActions.loadVertreter()), 2000);
            return [
              VertreterActions.addVertreterSucceeded({
                vertreter: addedVertreter,
              }),
            ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Hinzufuegen.FailedMessage',
                {
                  displayName: vertreter.displayName,
                }
              ) as string,
              error.error as string,
              error.url
            );
            return of(VertreterActions.addVertreterFailed({ error }));
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  removeVertreter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.removeVertreter),
      mergeMap(({ vertreter }) => {
        const toast = this.toastService.showAndGetWaitToast(
          this.translate.instant(
            'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.WaitTitel'
          ) as string,
          this.translate.instant(
            'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.WaitMessage',
            {
              displayName: vertreter.displayName,
            }
          ) as string
        );
        return this.vertreterService.vertreterEntfernen(vertreter.id).pipe(
          switchMap(() => {
            this.toastService.showSuccess(
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.SucceededTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.SucceededMessage',
                {
                  displayName: vertreter.displayName,
                }
              ) as string
            );
            // Refactor 2024
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            setTimeout(() => this.store.dispatch(VertreterActions.loadVertreter()), 2000);
            return of(
              VertreterActions.removeVertreterSucceeded({
                vertreterId: vertreter.id,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.MeineVertretungen.Vertreter.Benachrichtigungen.Entfernen.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return [
              VertreterActions.removeVertreterFailed({ error }),
              VertreterActions.loadVertreter(),
            ];
          }),
          finalize(() => {
            this.toastService.removeToast(toast);
          })
        );
      })
    );
  });

  loadVorgesetzte$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.loadVorgesetzte, VertreterActions.loadVertreterUndVorgesetzte),
      mergeMap(() =>
        this.vertreterService.getVorgesetzte().pipe(
          switchMap((vorgesetzte: PrincipalModel[]) => {
            return of(
              VertreterActions.loadVorgesetzteSucceeded({
                vorgesetzte: vorgesetzte,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.VorgesetzteLaden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Allgemein.Benachrichtigungen.VorgesetzteLaden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(VertreterActions.loadVorgesetzteFailed({ error }));
          })
        )
      )
    );
  });

  loadVertretungen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.loadVertretungen),
      mergeMap(() =>
        this.vertreterService.getVertretungen().pipe(
          switchMap((vertretungen: PrincipalModel[]) => {
            return of(
              VertreterActions.loadVertretungenSucceeded({
                vertretungen: vertretungen,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Vertretungen.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vertretungen.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(VertreterActions.loadVertretungenFailed({ error }));
          })
        )
      )
    );
  });

  loadMitarbeiter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VertreterActions.loadMitarbeiter),
      mergeMap(() =>
        this.vertreterService.getMitarbeiter().pipe(
          switchMap((mitarbeiter: PrincipalModel[]) => {
            return of(
              VertreterActions.loadMitarbeiterSucceeded({
                mitarbeiter: mitarbeiter,
              })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            this.toastService.showError(
              this.translate.instant(
                'Texte.Vertretungen.Benachrichtigungen.Laden.FailedTitel'
              ) as string,
              this.translate.instant(
                'Texte.Vertretungen.Benachrichtigungen.Laden.FailedMessage'
              ) as string,
              error.error as string,
              error.url
            );
            return of(VertreterActions.loadMitarbeiterFailed({ error }));
          })
        )
      )
    );
  });
}

import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MonacoEditorComponent } from './monaco-editor/monaco-editor.component';
import { MonacoEditorConfig } from './shared/model/monaco-editor-config';
import { MonacoProviderService } from './shared/services/monaco-provider.service';

export function MonacoProviderFactory(
  parent: MonacoProviderService,
  monacoEditorConfig: MonacoEditorConfig
): MonacoProviderService {
  return parent || new MonacoProviderService(monacoEditorConfig);
}

export const MONACO_PROVIDER = {
  provide: MonacoProviderService,
  deps: [
    [new Optional(), new SkipSelf(), MonacoProviderService],
    MonacoEditorConfig,
    TranslateService,
  ],
  useFactory: MonacoProviderFactory,
};

@NgModule({
  imports: [CommonModule, MonacoEditorComponent],
  exports: [MonacoEditorComponent],
  providers: [
    MONACO_PROVIDER,
    {
      provide: MonacoEditorConfig,
      useValue: { defaultOptions: { automaticLayout: true } } as MonacoEditorConfig,
    },
  ],
})
export class MonacoEditorModule {}

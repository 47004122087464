import { CdkDrag, CdkDragHandle, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

import { FormlyCardWrapperComponent } from './custom-formly-components/formly-card-wrapper/formly-card-wrapper.component';
import { InputInSentenceFieldTypeComponent } from './custom-formly-components/formly-field-input-in-sentence/formly-input-in-sentence.field.component';
import { ParagraphFieldTypeComponent } from './custom-formly-components/formly-field-paragraph/formly-paragraph.field.component';
import { FormlyRepeatingSectionFieldTypeComponent } from './custom-formly-components/formly-repeating-section/formly-repeating-section.field.component';
import { FormlyFieldStepperComponent } from './custom-formly-components/formly-wrapper-stepper/formly-stepper-wrapper.component';

import { FormlyEditorInternalComponent } from './formly-editor-internal/formly-editor-internal.component';
import { FormlyEditorComponent } from './formly-editor/formly-editor.component';

import { FeldArrayEigenschaftComponent } from './formly-editor-internal/feld-bearbeiten/feld-array-eigenschaft/feld-array-eigenschaft.component';
import { FeldObjectArrayEigenschaftComponent } from './formly-editor-internal/feld-bearbeiten/feld-array-eigenschaft/feld-object-array-eigenschaft/feld-object-array-eigenschaft.component';
import { FeldStringArrayEigenschaftComponent } from './formly-editor-internal/feld-bearbeiten/feld-array-eigenschaft/feld-string-array-eigenschaft/feld-string-array-eigenschaft.component';
import { FeldBearbeitenFormComponent } from './formly-editor-internal/feld-bearbeiten/feld-bearbeiten-form/feld-bearbeiten-form.component';
import { FeldBearbeitenModalComponent } from './formly-editor-internal/feld-bearbeiten/feld-bearbeiten-modal/feld-bearbeiten-modal.component';
import { FeldEigenschaftBearbeitenComponent } from './formly-editor-internal/feld-bearbeiten/feld-eigenschaft/feld-eigenschaft.component';
import { FeldSektionEigenschaftComponent } from './formly-editor-internal/feld-bearbeiten/feld-sektion-eigenschaft/feld-sektion-eigenschaft.component';
import { FeldKopierenModalComponent } from './formly-editor-internal/feld-kopieren-modal/feld-kopieren-modal.component';

import { FormularFormlyWrapperComponent } from './formular-formly-wrapper/formular-formly-wrapper.component';

import { MonacoEditorModule } from '@dworkflow-monaco-editor/monaco-editor.module';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlComponent } from './custom-formly-components/formly-html/formly-html.component';
import { accessibilityExtension } from './custom-formly-extensions/accessibility-extension';
import { showValidationExtension } from './custom-formly-extensions/show-validation-extension';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      wrappers: [{ name: 'card', component: FormlyCardWrapperComponent }],
      types: [
        {
          name: 'paragraph',
          component: ParagraphFieldTypeComponent,
        },
        {
          name: 'html',
          component: HtmlComponent,
        },
        {
          name: 'inputInSentence',
          component: InputInSentenceFieldTypeComponent,
        },
        {
          name: 'stepper',
          component: FormlyFieldStepperComponent,
          wrappers: [],
        },
        { name: 'repeat', component: FormlyRepeatingSectionFieldTypeComponent },
      ],
      extensions: [
        {
          name: 'formly-accessibility-extension',
          extension: accessibilityExtension,
        },
        {
          name: 'formly-show-validation-extension',
          extension: showValidationExtension,
        },
      ],
    }),
    FormlyMatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    MatCardModule,
    MatStepperModule,
    MatMenuModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    MonacoEditorModule,
    TranslateModule.forChild({
      extend: true,
    }),
    FormularFormlyWrapperComponent,
    FormlyEditorComponent,
    FormlyCardWrapperComponent,
    FormlyRepeatingSectionFieldTypeComponent,
    ParagraphFieldTypeComponent,
    HtmlComponent,
    FormlyFieldStepperComponent,
    InputInSentenceFieldTypeComponent,
    FormlyEditorInternalComponent,
    FeldBearbeitenModalComponent,
    FeldSektionEigenschaftComponent,
    FeldArrayEigenschaftComponent,
    FeldStringArrayEigenschaftComponent,
    FeldObjectArrayEigenschaftComponent,
    FeldEigenschaftBearbeitenComponent,
    FeldBearbeitenFormComponent,
    FeldKopierenModalComponent,
  ],
  exports: [FormlyEditorComponent, FormularFormlyWrapperComponent],
  providers: [],
})
export class FormlyEditorModule {}

import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';
import { ThemeService } from '@dworkflow/shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// Lösung aus https://github.com/ngx-translate/core/issues/517#issuecomment-640500318
// Die Translations werden abgerufen, bevor die App gerendert wird. Dies ermöglicht einen
// zuverlässigen synchronen Zugriff auf die Translations per translate.instant('key')
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  themeService: ThemeService
): () => Observable<void> {
  // this language will be used as a fallback when a translation isn't found in the current language
  translate.setDefaultLang('config');
  themeService.initializeTheme();
  const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
  return (): Observable<void> =>
    from(locationInitialized).pipe(
      mergeMap(() => translate.use('config')),
      mergeMap(_ => EMPTY)
    );
}

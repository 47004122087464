import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomDatePickerAdapter extends NativeDateAdapter {
  parse(value: string | number): Date | null {
    if (typeof value === 'string') {
      const splitValue: string[] = value.split('.');
      const year = Number(splitValue[2]);
      const month = Number(splitValue[1]) - 1;
      const day = Number(splitValue[0]);

      // wenn year < 1000 ist, ist dies noch nicht 4 stellig. Dann soll das Datum nicht automatisch formatiert werden
      if (splitValue.length < 2 || isNaN(year) || year < 1000 || isNaN(month) || isNaN(day)) {
        return null;
      }

      const date = new Date(year, month, day);
      if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        return null;
      }

      return date;
    }

    return null;
  }

  format(date: Date, displayFormat: string): string {
    return moment(date).format(displayFormat);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export interface BannerMessageModel {
  id: number;
  tenantId: number;
  text: string;
  start: Date;
  ende: Date;
  identifier: string;
  level: BannerLevel;
}

export enum BannerLevel {
  Info = 0,
  Warnung = 1,
  Error = 2,
}

@if (bannerBenachrichtigungen$ | async; as bannerBenachrichtigungen) {
  <div class="banner-container" role="banner">
    @for (banner of bannerBenachrichtigungen; track trackBenachrichtigungBy($index, banner)) {
      <div
        [ngClass]="{
          'banner-error': banner.level === bannerLevel.Error,
          'banner-warn': banner.level === bannerLevel.Warnung,
          'banner-info': banner.level === bannerLevel.Info,
          banner: true
        }"
        >
        <span class="icon"
          >@if (banner.level === bannerLevel.Error) {
          <mat-icon
            fontSet="fa"
            fontIcon="fa-times-circle"
          ></mat-icon>
        }
        @if (banner.level === bannerLevel.Warnung) {
          <mat-icon
            fontSet="fa"
            fontIcon="fa-exclamation-circle"
          ></mat-icon>
        }
        @if (banner.level === bannerLevel.Info) {
          <mat-icon
            fontSet="fa"
            fontIcon="fa-info-circle"
          ></mat-icon>
        }
      </span>
      <span class="message" role="alert" [innerHtml]="banner.text"></span>
      @if (banner.level !== bannerLevel.Error) {
        <button
          type="button"
          mat-icon-button
          class="banner-removal"
          (click)="removeBanner(banner)"
          [title]="'Texte.Banner.SchliessenButton' | translate"
          >
          <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
        </button>
      }
    </div>
  }
</div>
}

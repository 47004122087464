import { createReducer, on } from '@ngrx/store';
import _ from 'lodash';
import { AccessibilityState, initialAccessibilityState } from './accessibility.state';
import * as commonActions from './common.actions';

export const accessibilityReducer = createReducer(
  initialAccessibilityState,
  on(commonActions.addAriaDescribedByIds, (state, { ids }) => {
    const idsInState = _.clone(state.ariaDescribedByIds);
    ids.forEach(id => idsInState.push(id));
    return { ...state, ariaDescribedByIds: idsInState };
  }),

  on(commonActions.removeAriaDescribedByIds, (state, { ids }) => {
    const idsInState = _.clone(state.ariaDescribedByIds);
    ids.forEach(id => {
      const index = idsInState.indexOf(id);
      if (index !== -1) {
        idsInState.splice(index, 1);
      }
    });
    return { ...state, ariaDescribedByIds: idsInState };
  }), // TenantChanged
  on(
    commonActions.tenantChanged,
    (_state): AccessibilityState => ({
      ...initialAccessibilityState,
    })
  )
);

import { VorlageModel, VorlagenType, VorlagenTypeUtil } from '@dworkflow/shared/model';
import { VorlagenLadeType } from '@dworkflow/shared/model/vorlagen/vorlagen-lade-type.enum';
import * as commonActions from '@dworkflow/state/common.actions';
import { createReducer, on } from '@ngrx/store';
import lodash, { cloneDeep } from 'lodash';
import * as GrundeinstellungenActions from './grundeinstellungen.actions';
import {
  State,
  initialState,
  vorlagenAdapter,
  vorlagenkonfigurationenAdapter,
} from './grundeinstellungen.state';

export const grundeinstellungenReducer = createReducer(
  initialState,

  // Vorlagenkonfigurationen
  on(
    GrundeinstellungenActions.createVorlagenkonfigurationSucceeded,
    (state, { newValue: value }) => {
      const toDelete = Object.values(state.vorlagenkonfigurationen.entities)
        .filter(vk => vk.basisKonfigurationsId === value.basisKonfigurationsId)
        .map(vk => vk.laufendeKonfigurationsId);
      let stateVks = vorlagenkonfigurationenAdapter.removeMany(
        toDelete,
        state.vorlagenkonfigurationen
      );
      stateVks = vorlagenkonfigurationenAdapter.upsertOne(value, stateVks);
      return {
        ...state,
        vorlagenkonfigurationen: stateVks,
      };
    }
  ),
  on(
    GrundeinstellungenActions.deleteVorlagenkonfigurationSucceeded,
    (state, { id, laufendeKonfigurationsId }) => {
      const vorlagenMitEntfernterKonfig = Object.values(state.vorlagen.entities)
        .filter(v => v.laufendeKonfigurationsId === laufendeKonfigurationsId)
        .map(v => ({ ...v, konfigurationMarkiertFuerLoeschung: true } as VorlageModel));
      return {
        ...state,
        vorlagenkonfigurationen: vorlagenkonfigurationenAdapter.removeOne(
          id,
          state.vorlagenkonfigurationen
        ),
        vorlagen: vorlagenAdapter.upsertMany(vorlagenMitEntfernterKonfig, state.vorlagen),
      };
    }
  ),
  on(GrundeinstellungenActions.loadVorlagenkonfigurationenSucceeded, (state, { values }) => ({
    ...state,
    vorlagenkonfigurationen: vorlagenkonfigurationenAdapter.upsertMany(values, {
      ...state.vorlagenkonfigurationen,
      isLoaded: true,
    }),
    anzahlSichtbarerVorlagenkonfigurationen: values.filter(k => k.istSichtbar).length,
  })),
  on(GrundeinstellungenActions.loadVorlagenkonfigurationSucceeded, (state, { konfiguration }) => ({
    ...state,
    vorlagenkonfigurationen: vorlagenkonfigurationenAdapter.upsertOne(
      konfiguration,
      state.vorlagenkonfigurationen
    ),
  })),

  // Vorlagen
  on(GrundeinstellungenActions.createVorlageSucceeded, (state, { newValue: value }) => ({
    ...state,
    vorlagen: vorlagenAdapter.upsertOne(correctVorlagenTyp(value), state.vorlagen),
  })),
  on(GrundeinstellungenActions.vorlageKopierenSucceeded, (state, { vorlage }) => {
    return {
      ...state,
      vorlagen: vorlagenAdapter.upsertOne(correctVorlagenTyp(vorlage), state.vorlagen),
    };
  }),
  on(GrundeinstellungenActions.updateVorlageSucceeded, (state, { newValue: value }) => ({
    ...state,
    vorlagen: vorlagenAdapter.upsertOne(correctVorlagenTyp(value), state.vorlagen),
    vorlagenZumWfAnlegenLoaded: false,
    resolvedVorlagen: vorlagenAdapter.getInitialState({
      isLoaded: false,
    }),
  })),
  on(GrundeinstellungenActions.deleteVorlageSucceeded, (state, { id }) => {
    return {
      ...state,
      vorlagen: vorlagenAdapter.removeOne(id, state.vorlagen),
    };
  }),

  on(GrundeinstellungenActions.loadVorlagenSucceeded, (state, { values, vorlagenLadeType }) => {
    values = correctVorlagenTypen(values);
    const vorlagenState = vorlagenAdapter.upsertMany(values, {
      ...state.vorlagen,
    });
    switch (vorlagenLadeType) {
      case VorlagenLadeType.Persoenliche:
        return {
          ...state,
          vorlagen: vorlagenState,
          persoenlicheVorlagenLoaded: true,
        };
      case VorlagenLadeType.AllePersoenlichen:
        return {
          ...state,
          vorlagen: vorlagenState,
          allePersoenlichenVorlagenLoaded: true,
        };
      case VorlagenLadeType.Freigegebene:
        return {
          ...state,
          vorlagen: vorlagenState,
          freigegebeneVorlagenLoaded: true,
        };
      case VorlagenLadeType.Allgemeine:
        return {
          ...state,
          vorlagen: vorlagenState,
          allgemeineVorlagenLoaded: true,
        };
      case VorlagenLadeType.Schnittstelle:
        return {
          ...state,
          vorlagen: vorlagenState,
          schnittstellenVorlagenLoaded: true,
        };
    }
  }),

  on(
    GrundeinstellungenActions.loadVorlagenZumWfAnlegenSucceeded,
    (state, { values, currentUserId }) => {
      values = correctVorlagenTypen(values);
      values.forEach(v => {
        if (
          v.vorlagenTyp.toLocaleUpperCase() === VorlagenType.Persoenlich.toLocaleUpperCase() &&
          v.erstelltVonId !== currentUserId
        ) {
          v.vorlagenTyp = VorlagenType.Freigegeben;
        }
        if (
          v.vorlagenTyp.toLocaleUpperCase() ===
            VorlagenType.PersoenlichSchnellstart.toLocaleUpperCase() &&
          v.erstelltVonId !== currentUserId
        ) {
          v.vorlagenTyp = VorlagenType.FreigegebenSchnellstart;
        }
      });
      return {
        ...state,
        vorlagen: vorlagenAdapter.upsertMany(values, {
          ...state.vorlagen,
        }),
        vorlagenZumWfAnlegenLoaded: true,
      };
    }
  ),

  on(GrundeinstellungenActions.loadResolvedVorlageSucceeded, (state, { value }) => {
    value = correctVorlagenTyp(value);
    return {
      ...state,
      resolvedVorlagen: vorlagenAdapter.upsertOne(value, {
        ...state.resolvedVorlagen,
        isLoaded: true,
      }),
    };
  }),

  on(GrundeinstellungenActions.loadResolvedVorlageFailed, (state, { vorlagenId, error }) => {
    return {
      ...state,
      resolvedVorlagen: vorlagenAdapter.upsertOne(
        {
          vorlagenId: vorlagenId,
          basisKonfigurationsId: undefined,
          benoetigtUpdate: undefined,
          konfigurationBenoetigtUpdate: undefined,
          beschreibung: undefined,
          freigegebenFuer: undefined,
          laufendeKonfigurationsId: undefined,
          vorlagenTyp: undefined,
          name: undefined,
          konfigurationSichtbar: undefined,
          konfigurationMarkiertFuerLoeschung: undefined,
          vorlagendaten: undefined,
          frischImportiertOderKopiert: undefined,
          tenantId: undefined,
          schnellstart: undefined,
          error: error.message,
        },
        {
          ...state.vorlagen,
        }
      ),
    };
  }),

  // Freigegebene Vorlagen
  on(GrundeinstellungenActions.vorlageFreigebenSucceeded, (state, { vorlagenId, principalIds }) => {
    const vorlage = lodash.cloneDeep(state.vorlagen.entities[vorlagenId]);
    vorlage.freigegebenFuer = principalIds;
    return {
      ...state,
      vorlagen: vorlagenAdapter.upsertOne(vorlage, state.vorlagen),
    };
  }),

  on(
    GrundeinstellungenActions.loadCombinedTexteWithoutCacheSucceeded,
    (state, { texte }): State => ({
      ...state,
      combinedTexte: { value: texte, isLoaded: true },
    })
  ),
  on(
    GrundeinstellungenActions.loadDefaultTexteSucceeded,
    (state, { texte }): State => ({
      ...state,
      defaultTexte: { value: texte, isLoaded: true },
    })
  ),
  on(
    GrundeinstellungenActions.postCustomTexteSucceeded,
    (state, { texte }): State => ({
      ...state,
      combinedTexte: { value: texte, isLoaded: true },
    })
  ),
  on(GrundeinstellungenActions.importVorlageSucceeded, (state, { vorlage }) => ({
    // Die Konfiguration wird an dieser Stelle nicht aktualisiert, da diese noch nicht vollständig ist (Schritt- und Dokumenten-anteile fehlen noch)
    // Die Konfigurationen werden per Action leicht verzögert aktualisiert.
    ...state,
    vorlagen: vorlagenAdapter.upsertOne(correctVorlagenTyp(vorlage), state.vorlagen),
  })),
  on(
    commonActions.tenantChanged,
    (_state): State => ({
      ...initialState,
    })
  )
);

function correctVorlagenTypen(oldVorlagen: VorlageModel[]): VorlageModel[] {
  const newVorlagen: VorlageModel[] = [];
  oldVorlagen.forEach(vorlage => {
    newVorlagen.push(correctVorlagenTyp(vorlage));
  });
  return newVorlagen;
}

function correctVorlagenTyp(oldVorlage: VorlageModel): VorlageModel {
  if (typeof oldVorlage.vorlagenTyp === 'string') {
    const newVorlage = cloneDeep(oldVorlage);
    newVorlage.vorlagenTyp = VorlagenTypeUtil.parse(
      oldVorlage.vorlagenTyp,
      oldVorlage.schnellstart
    );
    return newVorlage;
  } else {
    return oldVorlage;
  }
}

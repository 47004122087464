import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AccessibilityState {
  ariaDescribedByIds: string[];
}

export const initialAccessibilityState: AccessibilityState = {
  ariaDescribedByIds: [],
};

const selectAccessibilityState = createFeatureSelector<AccessibilityState>('a11y');

export const selectAriaDescribedByIds = createSelector(
  selectAccessibilityState,
  state => state?.ariaDescribedByIds
);

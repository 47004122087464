import { EnvironmentInjector, effect, runInInjectionContext } from '@angular/core';
import { InactivityService } from '@dworkflow/shared/services/inactivity.service';

export class DataInterval {
  private timer: number;

  constructor(
    private inactivity: InactivityService,
    private handler: () => void,
    private timeout: number,
    injector: EnvironmentInjector
  ) {
    this.handler = handler;
    runInInjectionContext(injector, () =>
      effect(
        () => {
          inactivity.isUserActive() ? this.userGotActive() : this.userGotIdle();
        },
        { allowSignalWrites: true }
      )
    );
    this.handle();
  }

  clear(): void {
    window.clearTimeout(this.timer);
  }

  private handle = (): void => {
    this.handler();
    this.setTimeout();
  };

  private userGotActive = (): void => {
    this.handle();
  };

  private userGotIdle = (): void => {
    window.clearTimeout(this.timer);
  };

  private setTimeout(): void {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(this.handle, this.timeout + this.inactivity.inactivitySummand);
  }
}

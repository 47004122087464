<!-- Vor dem Login können noch keine Translations abgerufen werden-->
@if (loggedIn) {
  <dworkflow-loading
    [loaded]="(texteLoaded$ | async) === true && !!(tenantService.currentTenant$ | async)"
    [loadingMessage]="
      (texteLoaded$ | async) === false ? 'Texte werden geladen' : 'Mandanten werden geladen'
    "
    [loaderContent]="loaderTenants"
    [showSpinner]="(accessibilityEnabled$ | async) === false"
    class="loading-container"
    [showLoadingMessage]="true"
    >
    <ng-template #loaderTenants>
      <div id="accessibility">
        @if (accessibilityEnabled$ | async) {
          <button class="accessibility-link" (click)="toggleZugriffsfreundlichenModus(false)">
            Zugriffsfreundlicheren Modus deaktivieren
          </button>
        }
        @if ((accessibilityEnabled$ | async) === false) {
          <button class="accessibility-link" (click)="toggleZugriffsfreundlichenModus(true)">
            Zugriffsfreundlicheren Modus aktivieren
          </button>
        }
      </div>
      <div id="skip-link">
        <a [href]="skipLinkPath" class="accessibility-link">Zum Hauptinhalt wechseln</a>
      </div>
      <dworkflow-banner></dworkflow-banner>
      @if (!currentRoute.startsWith('/management')) {
        <dworkflow-navigation
          class="hide-in-print"
        ></dworkflow-navigation>
      }
      <!--Link für den MainContent um Accessibility "2.4.1 Bypass Blocks" zu erfüllen -->
      <span role="main" class="main-content">
        <a id="main-content" tabindex="-1" aria-hidden="true"></a><router-outlet></router-outlet
      ></span>
      @if (!currentRoute.startsWith('/management')) {
        <dworkflow-footer
          class="footer hide-in-print"
        ></dworkflow-footer>
      }
      <dworkflow-dokumente-upload-progress-notification-area></dworkflow-dokumente-upload-progress-notification-area>
    </ng-template>
  </dworkflow-loading>
} @else {
  <div class="anonymous-content">
    <div class="component">
      @switch (route) {
        @case ('datenschutz') {
          <ng-container *ngTemplateOutlet="anonymousNav"></ng-container>
          <dworkflow-datenschutzerklaerung></dworkflow-datenschutzerklaerung
            >
          }
          @case ('impressum') {
            <ng-container *ngTemplateOutlet="anonymousNav"></ng-container>
            <dworkflow-impressum></dworkflow-impressum
              >
            }
            @case ('help') {
              <ng-container *ngTemplateOutlet="anonymousNav"></ng-container>
              <dworkflow-hilfe></dworkflow-hilfe
                >
              }
              @case ('barrierefreiheit') {
                <ng-container *ngTemplateOutlet="anonymousNav"></ng-container>
                <dworkflow-barrierefreiheitserklaerung></dworkflow-barrierefreiheitserklaerung
                  >
                }
                @default {
                  <ng-container *ngTemplateOutlet="anonymousNav"></ng-container>
                  <dworkflow-loading
                    [loadingMessage]="loginText"
                    [showSpinner]="true"
                    class="loading-container wait-for-login"
                    [showLoadingMessage]="true"
                  ></dworkflow-loading>
                }
              }
            </div>
            <dworkflow-footer
              class="hide-in-print"
              [showSupportInformationenButton]="false"
              [useRouter]="false"
            ></dworkflow-footer>
          </div>
        }

        <ng-template #anonymousNav>
          <div class="nav">
            <span aria-hidden="true" class="toolbar-brand-image" aria-label="dWorkflow-Logo"></span>
          </div>
        </ng-template>


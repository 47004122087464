/* eslint-disable @angular-eslint/template/use-track-by-function */
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

import { MatStepperModule } from '@angular/material/stepper';

@Component({
  selector: 'dworkflow-formly-wrapper-stepper',
  template: `
    <style>
      .stepper-buttons {
        justify-content: flex-end;
        margin-top: unset;
      }
    </style>
    <mat-stepper #stepper [id]="id">
      @for (step of field.fieldGroup; track $index; let index = $index; let last = $last) {
        <mat-step [state]="step.props.state">
          @for (state of props.states; track $index) {
            <ng-template [matStepperIcon]="state.state" let-index="index">
              <i [class]="state.icon + ' fa-sm'" aria-hidden="true"></i>
            </ng-template>
          }
          @if (!containsState(props.states, 'edit'); as index) {
            <ng-template matStepperIcon="edit" let-index="index">
              <i [class]="'fas fa-pen fa-sm'" aria-hidden="true"></i>
            </ng-template>
          }
          @if (!containsState(props.states, 'done'); as index) {
            <ng-template matStepperIcon="done" let-index="index">
              <i [class]="'fas fa-check fa-sm'" aria-hidden="true"></i>
            </ng-template>
          }
          <ng-template matStepLabel>{{ step.props.label }}</ng-template>
          <formly-field [field]="step"></formly-field>
          <div class="stepper-buttons">
            @if (index !== 0) {
              <button matStepperPrevious mat-flat-button type="button" color="primary">
                {{ props.zurueck || 'Zurück' }}
              </button>
            }
            @if (!last) {
              <button
                matStepperNext
                mat-flat-button
                type="button"
                color="primary"
                [disabled]="!isValid(step)"
              >
                {{ props.weiter || 'Weiter' }}
              </button>
            }
          </div>
        </mat-step>
      }
    </mat-stepper>
  `,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  standalone: true,
  imports: [MatStepperModule, FormlyModule, MatButtonModule],
})
export class FormlyFieldStepperComponent extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup ? field.fieldGroup.every(f => this.isValid(f)) : true;
  }

  containsState(states: { state: string; icon: string }[], state: string): boolean {
    return states?.some(s => s.state === state);
  }
}
